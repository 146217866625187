import React from "react";
import {
  TextField
} from  "@material-ui/core";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { alpha } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root input": {
      height: "25px",
      paddingTop: "3px",
      paddingBottom: "3px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    '&:hover .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
      borderColor: props => props.textColor ? alpha(props.textColor, 0.4) : theme.palette.color.secondary.main,
      borderStyle: 'dotted'
    },
    "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline": {
      borderColor: props => props.textColor ? alpha(props.textColor, 0.6) : theme.palette.color.primary.main,
      borderWidth: 1
    },
  }
}));

function isInteger(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
     (str >>> 0 === parseFloat(str));  // checks for non-negative integer.
}

// This method has l10n issue. It only works with full stop as the decimal point.
function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export const GhostTextField = (props) => {
  const {
    value, // value is a number
    intOnly = false, 
    maximumFractionDigits = 4,
    onChange, 
    textAlign = 'center', 
    width = "auto", 
    marginLeft, 
    marginRight, 
    paddingRight, 
    fontSize, 
    textColor,
     ...other} = props;

  const classes = useStyles({textColor: textColor});

  const numToViewString = (num) => {
    return num.toLocaleString("en-US",{maximumFractionDigits : maximumFractionDigits, useGrouping: "auto"});
  };
  
  const numToEditString = (num) => {
    return num.toLocaleString("en-US",{maximumFractionDigits : maximumFractionDigits, useGrouping: false});
  };

  const [internalValue, setInternalValue] = useState(numToViewString(value));

  useEffect(() => {
    setInternalValue(numToViewString(value));
  }, [value]);

  return (
    <TextField
      {...other} 
      autoComplete="off"
      margin="dense"
      size="small"
      type="text"
      variant="outlined"
      value={internalValue}
      style={{
        width: width,
        flexGrow: 0,
        marginLeft: marginLeft,
        marginRight: marginRight
      }}
      inputProps={{style: {
        textAlign: textAlign,
        paddingRight: paddingRight,
        fontSize: fontSize,
        color: textColor
      }}}
      onChange={(e) => {
        if (intOnly) {
          const regex = /^[0-9\b]+$/;
          if (e.target.value === "" || regex.test(e.target.value)) {
            setInternalValue(e.target.value);
          }
        } else {
          setInternalValue(e.target.value);
        }
      }}
      onFocus={() => {
        setInternalValue(numToEditString(value));
      }}
      onBlur={() => {
        // If the entered value is not a number, forget it and show the original value.
        if ((intOnly && isInteger(internalValue)) || (!intOnly && isNumeric(internalValue))) {
          if (value !== Number(internalValue)) {
            if (onChange) {
              onChange(Number(internalValue));
            }
          } else {
            setInternalValue(numToViewString(value));  
          }
        } else {
          setInternalValue(numToViewString(value));
        }
      }}
      classes={{
        root: classes.root
      }}
    />
  );
};

export default GhostTextField;