export const AVAILABLE_PAGE_NAME_KEY = "availablePageName";
export const AVAILABLE_PAGE_LINKS_LIST_KEY = "availablePageLinksList";

// Currently available user roles in system.
export const ROLES = {
    ADMINISTRATOR: "Global administrator",
    VENUE_ADMINISTRATOR: "Venue administrator",
    VENUE_OPERATOR: "Venue operator",
    VENUE_VIEWER: "Venue viewer"
};

// Mechanism that allows to give each role permission to: 
// 1. See specific menu point
// 2. Get access only for specific routes, else user sees page "Access to this page is denied!"
// Note the value in [AVAILABLE_PAGE_NAME_KEY] must exists in menus' name. (see menus.js)
export const AVAILABLE_PAGES_BY_ROLE = {
    [ROLES.ADMINISTRATOR]: [
        {
            [AVAILABLE_PAGE_NAME_KEY]: "*",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["*"]
        }
    ],
    [ROLES.VENUE_ADMINISTRATOR]: [
        {
            [AVAILABLE_PAGE_NAME_KEY]: "Home", 
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/Home", "/home", "/"]
        },
        {
            [AVAILABLE_PAGE_NAME_KEY]: "Dashboards",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/dashboards", "/dashboard"]
        },
        {
            [AVAILABLE_PAGE_NAME_KEY]: "Maps",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/maps", "/addMap", "/EditMap"]
        },
        {
            [AVAILABLE_PAGE_NAME_KEY]: "Alerts",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/alert-rule-sets"]
        },
        {
            [AVAILABLE_PAGE_NAME_KEY]: "Venue locations",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/venueLocations", "/VenueLocations", "/venueLocationsImport", "/addLocation", "/editLocation"]
        },
        {
            [AVAILABLE_PAGE_NAME_KEY]: "Reports",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/reports"]
        },
        {
            [AVAILABLE_PAGE_NAME_KEY]: "Settings",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/settings"]
        },
        {
            [AVAILABLE_PAGE_NAME_KEY]: "Occupancy manual adjustment",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/adjustments"]
        },
        {
            [AVAILABLE_PAGE_NAME_KEY]: "Users",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/users", "/user", "/user-profile", "/change-password"]
        },
        {
            [AVAILABLE_PAGE_NAME_KEY]: "User groups",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/user-groups"]

        },
        {
            [AVAILABLE_PAGE_NAME_KEY]: "Monitor",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/monitor"]
        },
        {
            [AVAILABLE_PAGE_NAME_KEY]: "Events",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/events"]
        },
    ],
    [ROLES.VENUE_OPERATOR]: [
        {
            [AVAILABLE_PAGE_NAME_KEY]: "Home",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/Home", "/home", "/"]
        },
        {
            [AVAILABLE_PAGE_NAME_KEY]: "Dashboards",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/dashboards", "/dashboard"]
        },
        {
            [AVAILABLE_PAGE_NAME_KEY]: "Maps",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/maps", "/addMap", "/EditMap"]
        },
        {
            [AVAILABLE_PAGE_NAME_KEY]: "Alerts",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/alert-rule-sets"]
        },
        {
            [AVAILABLE_PAGE_NAME_KEY]: "Reports",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/reports"]
        },
        {
            [AVAILABLE_PAGE_NAME_KEY]: "Settings",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/settings"]
        },
        {
            [AVAILABLE_PAGE_NAME_KEY]: "User",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/user-profile"]
        },
    ],
    [ROLES.VENUE_VIEWER]: [
        {
            [AVAILABLE_PAGE_NAME_KEY]: "Home",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/Home", "/home", "/"]
        },
        {
            [AVAILABLE_PAGE_NAME_KEY]: "Dashboards",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/dashboards", "/dashboard"]
        },
        {
            [AVAILABLE_PAGE_NAME_KEY]: "Reports",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/reports"]
        },
        {
            [AVAILABLE_PAGE_NAME_KEY]: "User",
            [AVAILABLE_PAGE_LINKS_LIST_KEY]: ["/user-profile"]
        },
    ]
};

// This mechanism allows you to determine which specific components of certain pages 
// are available to the user depending on the role.
export const SCOPES = {
    // Full control of system management.
    CAN_FULL_MANAGEMENT: "can-full-management",

    // Functionality on "Factors" page.
    CAN_EDIT_FACTORS: "can-edit-factors",
    
    //  Functionality on "Settings" page.
    CAN_EDIT_VENUE: "can-edit-venue",
    CAN_PUBLISH_DATA_FOR_REPORTS: "can-publish-data-for-reports",
    CAN_EDIT_USER_VENUE_LIST: "can-edit-user-venue-list",
    CAN_EDIT_MODES: "can-edit-modes",
    CAN_EDIT_DATA_SOURCES: "can-edit-data-sources",
    CAN_EDIT_BRANDING: "can-edit-branding",
    CAN_SETUP_ARM_API_SETTINGS: "can-setup-arm-api-settings",
    CAN_SETUP_REPORT_VIEW_SETTINGS: "can-setup-report-view-settings",
    CAN_SETUP_TWILIO_API_SETTINGS: "can-setup-twilio-api-settings",

    CAN_VIEW_EVENTS: "can-view-events",
    CAN_ADD_EVENTS: "can-add-events",
    CAN_EDIT_EVENTS: "can-edit-events",
    CAN_DELETE_EVENTS: "can-delete-events",

    // Functionality on "Home"/"Venues List" page.
    CAN_ADD_VENUE: "can-add-venue",
    CAN_EDIT_AI_STREAMS: "can-edit-ai-streams",

    CAN_UPDATE_DASHBOARD: "can-update-dashboard",
    CAN_VIEW_PMYVISIONMESSAGECHECKER: "can-view-pmyvisionmessagechecker", // for occupancy
    CAN_VIEW_PMYVISIONTRAFFICINOUTMESSAGECHECKER: "can-view-pmyvisiontrafficinoutmessagechecker", // for traffic in out
    CAN_VIEW_PMYVISIONQUEUEMODELMESSAGECHECKER: "can-view-pmyvisionqueuemodelmessagechecker", // for queue model
    CAN_VIEW_PMYVISIONDEMOGRAPHICMESSAGECHECKER: "can-view-pmyvisiondemographicmessagechecker", // for demographic
};
// Mapping of user roles and scopes above.
export const PERMISSIONS = {
    [ROLES.ADMINISTRATOR]: [
        SCOPES.CAN_FULL_MANAGEMENT
    ],
    [ROLES.VENUE_ADMINISTRATOR]: [
        SCOPES.CAN_EDIT_VENUE, SCOPES.CAN_PUBLISH_DATA_FOR_REPORTS, SCOPES.CAN_EDIT_USER_VENUE_LIST,
        SCOPES.CAN_EDIT_MODES, SCOPES.CAN_EDIT_DATA_SOURCES, SCOPES.CAN_EDIT_AI_STREAMS, SCOPES.CAN_EDIT_BRANDING,
        SCOPES.CAN_SETUP_REPORT_VIEW_SETTINGS, SCOPES.CAN_SETUP_TWILIO_API_SETTINGS,
        SCOPES.CAN_VIEW_PMYVISIONMESSAGECHECKER, SCOPES.CAN_VIEW_PMYVISIONTRAFFICINOUTMESSAGECHECKER, SCOPES.CAN_VIEW_PMYVISIONQUEUEMODELMESSAGECHECKER,
        SCOPES.CAN_VIEW_PMYVISIONDEMOGRAPHICMESSAGECHECKER, SCOPES.CAN_UPDATE_DASHBOARD,
        SCOPES.CAN_EDIT_FACTORS,
        SCOPES.CAN_VIEW_EVENTS, SCOPES.CAN_ADD_EVENTS, SCOPES.CAN_EDIT_EVENTS, SCOPES.CAN_DELETE_EVENTS
    ],
    [ROLES.VENUE_OPERATOR]: [
        SCOPES.CAN_PUBLISH_DATA_FOR_REPORTS, SCOPES.CAN_EDIT_MODES, SCOPES.CAN_UPDATE_DASHBOARD
    ],
    [ROLES.VENUE_VIEWER]: [
        SCOPES.CAN_PUBLISH_DATA_FOR_REPORTS, SCOPES.CAN_EDIT_MODES
    ],
};