import React from "react" 
import { Typography } from "@material-ui/core";
import appViewModeConstants from "../../../_constants/app.view.mode.constants";
import * as occUtils from './OccupancyUtils';

const Percentage = (props) => {
  const { data, viewMode, classes } = props;
 
  const isViewMode = viewMode === appViewModeConstants.VIEW_MODE;
  const percentage = isViewMode
    ? Math.round(((data?.occupancy?.occupancyNumber ?? 0) / (data?.location?.maximumCapacity ?? 0)) * 100)
    : 0;
  
  return (
    <>
            <Typography className={classes.numberContent}>
              {Number.isFinite(percentage) ? percentage.toLocaleString("en-US") : 0} %
            </Typography>
            <Typography className={classes.location}>
              { occUtils.getLocationName(props) }
            </Typography>
    </>
  );
}

export default Percentage;
