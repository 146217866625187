import React from "react";
import TreeItem from "@material-ui/lab/TreeItem";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, IconButton, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import RestoreFromTrashIcon from "@material-ui/icons/RestoreFromTrash";
import { Link } from "react-router-dom";

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
      backgroundColor: "transparent",
    },
  },
  content: {
    color: theme.palette.text.secondary,
    //borderTopRightRadius: theme.spacing(2),
    //borderBottomRightRadius: theme.spacing(2),
    //paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
    borderBottomColor: theme.palette.grey[300],
    borderBottomStyle: "solid",
    borderBottomWidth: 1
  },
  labelText: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  actionButtons: {
    minWidth: 150,
  },
  icon: {
    color: theme.palette.color.primary.dark
  },

  deleteIcon: {
    color: theme.palette.color.primary.red
  },  
}));

export const VenueLocationTreeItem = ({
  treeItemId,
  label,
  type,
  isSensor,
  capacities,
  deleted,
  isRootLocation,
  onSetDeleteTreeItemId,
  onSetRestoreTreeItemId,
  ...other
}) => {
  const classes = useTreeItemStyles();

  const allowDelete = !isRootLocation;
  const allowAdd = !isSensor;

  return (
    <TreeItem
      nodeId={treeItemId}
      label={
        <div className={classes.labelRoot}>
          <Typography
            color={deleted ? "secondary" : "primary"} 
            className={classes.labelText}
          >
            {label} {deleted ? "(Deleted)" : ""}
          </Typography>
          <Box minWidth={100}>
            <Typography
              color={deleted ? "secondary" : "primary"}
              className={classes.labelText}
            >
              {type}
            </Typography>
          </Box>
          <Box minWidth={100}>
            <Typography
              color={deleted ? "secondary" : "primary"}
              className={classes.labelText}
            >
              {type.toLowerCase() === "sensor" ? "" : capacities.maximum}
            </Typography>
          </Box>

          <div className={classes.actionButtons}>
            { !deleted && allowAdd ? (
              <Tooltip title="Add Child">
                <IconButton
                  aria-label="add"
                  color="primary"
                  disabled={deleted } // We don't really needs this because if location is deleted, we hide this button completely.
                  component={Link}
                  to={{
                    pathname: `/addLocation`,
                    state: {
                      parentId: treeItemId,
                    },
                  }}
                >
                  <AddIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                color="primary"
                disabled={deleted}
                component={Link}
                to={`/editLocation/${treeItemId}`}
              >
                <EditIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
            {allowDelete && !deleted ? (
              <Tooltip title="Delete">
                <span>
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={e => {
                      e.preventDefault();
                      onSetDeleteTreeItemId(treeItemId);
                    }}
                  >
                    <DeleteIcon className={classes.deleteIcon} />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <></>
            )}
            {allowDelete && deleted ? (
              <Tooltip title="Restore">
                <span>
                  <IconButton
                    aria-label="restore"
                    color="primary"
                    onClick={e => {
                      e.preventDefault();
                      onSetRestoreTreeItemId(treeItemId);
                    }}
                  >
                    <RestoreFromTrashIcon className={classes.icon}  />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <></>
            )}
          </div>
        </div>
      }
      classes={{
        //root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        //group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
};

export default VenueLocationTreeItem;
