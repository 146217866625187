export const initialState = {
  scheduleTasks: {
    tasks: [],
    indicators: [],
    vendors: [],
    schedules: [],
    indicatorsWithVendorList: [],
    indicatorVendorMappings: [],
    indicatorScheduleMappings: [],
  },
  userManagement: {
    users: [],
    roles: [],
    loading: {
      usersLoading: false,
      rolesLoading: false,
    },
  },
  locationMapping: {
    locationMappings: [],
    locations: [],
  },
  dashboards: {
    dashboards: [],
    indicators: [],
    vendors: [],
    cameras: [],
    dashboard: {},
    dashboardsLastUpdated: {},
    dashboardsLastRequested: {},
    widgetTrafficTypes: [],
    occupancyViewModes: [],
    trafficTypes: [],
    cohorts: [],
    numberWidgetValueTypes: [],
    demographicWidgetValueTypes: [],
    demographicWidgetAgeGroup: [],
    demographicWidgetGender: [],
    demographicWidgetTimePeriods: [],
    numberWidgetTimePeriods: [],
    mapWidgetIndicators: [],
    dashboardAlertRuleSets: [],
    queueModelAlertMetrics: [],
    loading: {
        dashboardDataLoading: false,
        dashboardLoading: false
    },
    preferences: {
      hideInactiveDashboards: true
    },
  },
  indicators: {
    occupancy: {
      latestOccupancies: [],
      loading: {
        latestOccupanciesLoading: false,
      },
    },
    trafficInOut: {
      trafficInOutMetrics: [],
      loading: {
        trafficInOutMetricsLoading: false,
      }
    },
    footfallTraffic: {
      footfallTrafficMetrics: [],
      loading: {
        footfallTrafficMetricsLoading: false,
      }
    }
  },
  maps: {
    maps: [],  // maps with layouts (images) not loaded
    layouts: [], // maps with loaded layouts
    selectedMap: null,
    loading: {
      mapsLoading: false,  // True - while loading the list of maps (without layout file)
      mapLoading: false, // True - while loading a single map (with layout file)
      layoutLoading: []
    }
  },
  mapWidget: {
    widget: null,
    loading: false
  },
  reporting: {
    loading: {
      reportPublishingModeLoading: false
    }
  },
  tableauReporting: {
    loading: {
      tableauPublishingModeLoading: false
    },
    tableauPublishingMode: {}
  },
  notifications: {},
};

export default initialState;
