import React, {useState, useEffect} from "react";
import {Box, Checkbox, FormControlLabel, TextField} from "@material-ui/core";
import {toast} from "react-toastify";
import {
  Typography
} from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from "@material-ui/core/styles";
import {Button} from "../../../_common/htmlTags";
import {updateARMApiSettings } from "../../../../_services/venue.service";

const useStyles = makeStyles((theme) => {
  return {
    infoIcon: {
      color: theme.palette.color.primary.main,
      height: "22px",
      width: "22px",
      marginRight: theme.spacing(2)
    },
  };
});

export const ARMApiSettings = ({venue}) => {
  const classes = useStyles();

  const[armResourceLineageId, setArmResourceId] = useState(null);
  const[armUseTestingData, setArmUseTestingData] = useState(false);
  const[isSaving, setIsSaving] = useState(false);
  
  useEffect(() => {
    setArmResourceId(venue.armResourceLineageId);
    setArmUseTestingData(venue.useARMTestingData);
  },[venue.armResourceLineageId, venue.useARMTestingData]);
  
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start"
      }}>
      <Typography variant="h5">
        ARM API settings 
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          marginTop: "40px",
          marginBottom: "32px"
        }}>
        <InfoOutlinedIcon
          className={classes.infoIcon}
        />
        <Typography display="block" style={{lineHeight: 1.5}}>
          If <b>Use testing data</b> is selected, PMY Platform will use the default testing event specified in configuration.<br/>
          The <b>ARM Resource Lineage Id</b> is accepting GUID matching the Resource Lineage Id in ARM.<br />
        </Typography>
      </Box>
      <FormControlLabel
        control={<Checkbox 
          id="" 
          checked={armUseTestingData} 
          onChange={(e)=> setArmUseTestingData(e.target.checked)}
        />}
        label="Use testing data"
      />
      <TextField
        id="armResourceId"
        label="ARM Resource Lineage Id"
        type="text"
        name="armResourceId"
        value={armResourceLineageId ?? ""}
        InputLabelProps={{ shrink: true }}
        style={{width: "400px"}}
        disabled={armUseTestingData}
        onChange={(e) => {
          setArmResourceId(e.target.value);
        }}
      /><br /><br />
      <Button disabled={isSaving} onClick={(e) => {
        setIsSaving(true);
        let trimmedArmResourceLineageId = armResourceLineageId ? armResourceLineageId.trim() : armResourceLineageId;
        setArmResourceId(trimmedArmResourceLineageId);
        updateARMApiSettings(venue.id, trimmedArmResourceLineageId, armUseTestingData).then(() => {
          toast.success("ARM API settings updated!");
          setIsSaving(false);
        }).catch((error) => {
          toast.error("Invalid ARM Resource Lineage Id.", {autoClose: false});
          console.error("Updating ARM API settings failed. Invalid ARM Resource Lineage Id.", error);
          setIsSaving(false);
        })
      }}>
        Save
      </Button>
    </Box>
  )
}