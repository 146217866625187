import { reportConstants } from "../_constants/report.constants";
import * as reportApi from "../_services/report.service";

const getReportPublishingModeStarted = () => {
  return {
    type: reportConstants.GET_REPORT_PUBLISHING_MODE_STARTED,
  };
};
export const getReportPublishingModeSuccess = (reportPublishingMode) => {
  return {
    type: reportConstants.GET_REPORT_PUBLISHING_MODE_SUCCESS,
    reportPublishingMode: reportPublishingMode,
  };
};

export const publishReportData = async (venueId) => {
  return (await reportApi.publishReportData(venueId)).data;
};

export const setReportAutoPublishing = (venueId, isAuto) => {
  return async (dispatch) => {
    await reportApi
      .setReportAutoPublishing(venueId, isAuto)
      .catch((error) => {
        throw error;
      });
  };
};

export const getReportPublishingMode = (venueId) => {
  return async (dispatch) => {
    dispatch(getReportPublishingModeStarted());
    await reportApi
      .getReportPublishingMode(venueId)
      .then((response) => {
        dispatch(getReportPublishingModeSuccess(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const exportFullReport = (venueId, date, apiRoot, nowDateTime, setIntervalEnum, locations, setGenerating) => {
  return async (dispatch) => {
    await reportApi
      .exportFullReport(venueId, date, apiRoot, nowDateTime, setIntervalEnum, locations, setGenerating)
      .then(() => { })
      .catch((error) => {
        throw error;
      });
  };
}

export const exportDailyReport = (venueId, selectedDate, apiRoot, nowDateTime, locations, setGenerating) => {
  return async (dispatch) => {
    await reportApi
      .exportDailyReport(venueId, selectedDate, apiRoot, nowDateTime, locations, setGenerating)
      .then(() => { })
      .catch((error) => {
        throw error;
      });
  };
}

export const exportRawOccupancyReport = (venueId, selectedDate, apiRoot, nowDateTime, locations, setGenerating) => {
  return async (dispatch) => {
    await reportApi
      .exportRawOccupancyReport(venueId, selectedDate, apiRoot, nowDateTime, locations, setGenerating)
      .then(() => { })
      .catch((error) => {
        throw error;
      });
  };
}

export const exportTrafficInOutReport = (
  startDate, endDate, isDuringBusinessHours, dataToDisplay, vendorId, venueId,
  apiRoot, nowDateTime,
  locations,
  setGenerating
) => {
  return async (dispatch) => {
    await reportApi
      .exportTrafficInOutReport(
        startDate, endDate, isDuringBusinessHours, dataToDisplay, vendorId, venueId,
        apiRoot, nowDateTime,
        locations,
        setGenerating
      )
      .then(() => { })
      .catch((error) => {
        throw error;
      });
  };
}