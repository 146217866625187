import { Button as MuiButton } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return theme.htmlTag.button;
});

export const ButtonType = {
  Primary: 0,
  Secondary: 1,
  PrimaryText: 2,
  Dark: 3,
};

const getButtonClass = (buttonType, classes) => {
  switch (buttonType) {
    case ButtonType.Primary:
      return classes.primaryButton;

    case ButtonType.Secondary:
      return classes.secondaryButton;

    case ButtonType.PrimaryText:
      return classes.primaryTextButton;

    case ButtonType.Dark:
      return classes.darkButton;

    default:
      return classes.primaryButton;
  }
}

export const Button = (props) => {
  const { className = null, buttonType = ButtonType.Primary, size = "normal", ...restOfProps } = props;
  const classes = useStyles();
  return (
    <MuiButton
      {...{
        ...restOfProps,
        className: (getButtonClass(buttonType, classes)) + " " + (size === "normal" ? classes.normalButton : classes.smallButton) + (" " + className ?? "")
      }}
    >
      {props.children}
    </MuiButton>
  );
};

