import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField, 
  Tooltip,
  Typography
} from "@material-ui/core";
import {DataGrid} from "@material-ui/data-grid";
import {NIL as NIL_UUID} from "uuid";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast } from "react-toastify";
import {AlertDialog} from "../../../../_helpers";
import LoadPleaseWait from "../../../notification/LoadingPleaseWait/LoadingMessage";
import {useApiGet} from "../../../../_helpers/useApiGet";
import {getTableauUsername, updateTableauUsername} from "../../../../_services/report.view.service";
import {getReportViews, addReportView, updateReportView, deleteReportView} from "../../../../_services/report.view.service";
import {getReportViews as getReportViewsAction} from "../../../../_actions/venue.actions";
import useLocalStorage from "../../../../_helpers/useLocalStorage";
import useSortModel from "../../../../_helpers/useSortModel";

const useStyles = makeStyles((theme) => ({
  tableauUsernameOk: {
    color: theme.palette.color.success.main,
    width: "30px",
    height: "100%",
    marginRight: "0px"
  },
  tableauUsernameCancel: {
    color: theme.palette.color.danger.main,
    width: "30px",
    height: "100%"
  },
  addIconButton: {
    alignSelf: "end",
    color: theme.palette.color.primary.main,
    cursor: "pointer",
    width: "40px",
  },  
  editIcon: {
    color: theme.palette.color.primary.dark
  },
  deleteIcon: {
    color: theme.palette.color.primary.red
  },
  saveIcon: {
    color: theme.palette.color.success.main,
    height: "100%",
    marginRight: "0px"
  },
  cancelIcon: {
    color: theme.palette.color.danger.main,
    height: "100%"
  },
  selectReportTypeControl: {
    width: "250px",
    "&.MuiFormControl-root .MuiSelect-root" : {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  dataGridRoot: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
  }
}));

const getTableauUsernameCall = async (venueId) => {
  if (venueId) {
    const result = await getTableauUsername(venueId);
    return result;
  } else {
    return null;
  }
};

const getReportViewsCall = async (venueId) => {
  if (venueId) {
    const result = await getReportViews(venueId);
    return result;
  } else {
    return [];
  }
};

export const ReportViewSettings = ({venue}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // The "data" here is the Tableau username as it was loaded from the database.
  const [{data: initialTableauUsername, isLoading : isLoading1}] = useApiGet(getTableauUsernameCall, venue.id, null);

  // The tableauUsername is the same as the initialTableauUsername until the user edits and saves
  // the username. The tableauUsername variable will be set to the newly saved value.
  const [tableauUsername, setTableauUsername] = useState("");

  // If cachedTableauUsername is null, then the username has not been edited since
  // the page loaded from the database. If the cachedTableauUsername is not null (it can be 
  // an empty string), then the username name has been edited.
  const [cachedTableauUsername, setCachedTableauUsername] = useState(null);

  const [isSavingTableauUsername, setIsSavingTableauUsername] = useState(false);

  useEffect(() => {
    setTableauUsername(initialTableauUsername?.data);
    setCachedTableauUsername(null);
  }, [initialTableauUsername]);

  const handleTableauUsernameSave = async () => {
    if (isSavingTableauUsername) {
      return;
    }
    if (cachedTableauUsername === null) {
      return;
    }

    setIsSavingTableauUsername(true);
    let trimmedCachedTableauUsername = cachedTableauUsername ? cachedTableauUsername.trim(): cachedTableauUsername;
    updateTableauUsername(venue.id, trimmedCachedTableauUsername)
    .then(() => {
      toast.success("Tableau username has been saved.");
      setTableauUsername(trimmedCachedTableauUsername);
      setCachedTableauUsername(null);
      setIsSavingTableauUsername(false);
    })
    .catch((error) => {
      toast.error("Failed to save Tableau username." + error.message, {autoClose: false});
      setIsSavingTableauUsername(false);
    });
  }

    // The "data" here is the array of report views as they are loaded from the database.
  const [{data: initialReportViews, isLoading : isLoading2}] = useApiGet(getReportViewsCall, venue.id, []);

  const [reportViews, setReportViews] = useState([]);

  useEffect(() => {
    setReportViews(initialReportViews?.data);
  }, [initialReportViews]);

  const [pageSize, setPageSize] = useLocalStorage("pmy-reportViewSettings-pageSize", 5);
  const [sortModel, setSortModel] = useSortModel("pmy-reportViewSettings-sortModel", [{field: 'name', sort: 'asc'}]);
  const [currentPage, setCurrentPage] = useState(0);
  const [deleteReportViewId, setDeleteReportViewId] = React.useState(null);
  const [editRowModel, setEditRowModel] = useState({});
  const [editRowId, setEditRowId] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const cleanupReportViewsWithoutNew = () => {
    var reportViewsWithoutNew = reportViews.filter(a => a.id !== NIL_UUID);
    setReportViews(reportViewsWithoutNew);
  };

  const reportTypes = [
    "Tableau",
    "ARM"
  ];

  const columns = [
    {
      field: "id",
      hide: true,
      valueFormatter: (params) => params.row?.id,
    },
    {
      field: "venueId",
      hide: true,
      valueFormatter: (params) => params.row?.venueId,
    },
    {
      field: "name",
      headerName: "NAME",
      width: 300,
      cellClassName: (params) => { 
        if(editRowId === params.row.id)
          return classes.rowSelected; 
        else return "";
        },
      renderCell: (params) => {
        if (editRowId === params.row.id) {
          return (
            <TextField required={true}
              placeholder={"name"} 
              type={"text"} 
              value={editRowModel.name} 
              variant={"outlined"} 
              onChange={(e) => {
                setEditRowModel({...editRowModel, name: e.target.value});
              }}
              style={{width:"100%", backgroundColor: "#ffffff"}} />
          )
        } else {
          return (<span>{params.row.name}</span>)
        }
      }
    },
    {
      field: "type",
      headerName: "TYPE",
      width: 200,
      cellClassName: (params) => { 
        if(editRowId === params.row.id)
          return classes.rowSelected; 
        else return "";
        },
      renderCell: (params) => {
        if (editRowId === params.row.id) {
          return (
            <FormControl
              variant="outlined"
              className={classes.selectReportTypeControl}
              style={{height: "25px"}}
            >
              <Select
                labelId="type-label"
                id="type-select"
                name="type"
                value={editRowModel.type}
                variant={"outlined"}
                onChange={(e) => {
                  setEditRowModel({...editRowModel, type: e.target.value});
                }}
                style={{
                  width:"100%", 
                  backgroundColor: "#ffffff",
                  height: "100%"
                }}
                placeholder={"report type"}
              >
              {reportTypes
                .map((t) => (
                  <MenuItem key={t} value={t}>
                    {t}
                  </MenuItem>
                ))}
            </Select> 
          </FormControl>);
        } else {
          return (<span>{params.row.type}</span>)
        }
      }
    },
    {
      field: "url",
      headerName: "URL",
      flex: 1,
      cellClassName: (params) => {
        if(editRowId === params.row.id)
          return classes.rowSelected;
        else return "";
      },
      renderCell: (params) => {
        if (editRowId === params.row.id) {
          return (
            <TextField required={true} 
              placeholder={"http://example.com"} 
              type={"text"} 
              value={editRowModel.url} 
              variant={"outlined"}
              onChange={(e) => {
                setEditRowModel({...editRowModel, url: e.target.value});
              }}
              style={{width:"100%", backgroundColor: "#ffffff"}} />
          )
        } else {
          return (<span>{params.row.url}</span>)
        }
      }
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      width: 150,
      cellClassName: (params) => {
        if(editRowId === params.row.id)
          return classes.rowSelected;
        else return "";
      },
      renderCell: (params) => {
        if (editRowId === params.row.id) {
          return (
            <>
              <Tooltip title="Save">
                <IconButton
                  className={classes.saveIcon}
                  onClick={(e) => {
                    editRowModel.name = editRowModel?.name?.trim();
                    editRowModel.url = editRowModel?.url?.trim();
                    
                    // validate required field
                    if ((editRowModel?.name ?? "") === "") {
                      toast.error("Report view name is required.", {autoClose: false});
                      return;
                    }
                    if ((editRowModel?.type ?? "") === "") {
                      toast.error("Report view type is required.", {autoClose: false});
                      return;
                    }
                    if ((editRowModel?.url ?? "") === "") {
                      toast.error("Report view URL is required.", {autoClose: false});
                      return;
                    }
                    // check if the name already exists in the list or not
                    let a = reportViews.find(a => a.id !== editRowModel.id && a.name === editRowModel.name);
                    if (a)
                    {
                      toast.error("There is a report view with the same name. The name must be unique.", {autoClose: false});
                      return;
                    }
                    if (editRowModel.id === NIL_UUID) {
                      // add new
                      addReportView(editRowModel)
                        .then(response => {
                          setEditRowId(null);
                          setReportViews( prev => {
                            return prev.map( tv => {
                              if (tv.id === NIL_UUID) {
                                return {
                                  id: response.data,
                                  venueId: editRowModel.venueId,
                                  name: editRowModel.name,
                                  url: editRowModel.url,
                                  type: editRowModel.type
                                };
                              } else {
                                return tv;
                              }
                            });
                          });
                          dispatch(getReportViewsAction(venue));
                          toast.success("Report view added successfully.");
                        })
                        .catch(error => {
                          toast.error("Failed to add report view.", {autoClose: false});
                          console.log("Failed to add report view.", error);
                        });
                    }
                    else {
                      // update value
                      updateReportView(editRowModel)
                        .then(response => {
                          setEditRowId(null);
                          setReportViews(prev => {
                            return prev.map( tv => {
                              if (tv.id === editRowModel.id) {
                                return {
                                  ...tv,
                                  name: editRowModel.name,
                                  url: editRowModel.url,
                                  type: editRowModel.type
                                };
                              } else {
                                return tv;
                              }
                            });
                          });
                          dispatch(getReportViewsAction(venue));
                          toast.success("Report view updated successfully.");
                        })
                        .catch(error => {
                          toast.error("Failed to update report view.", {autoClose: false});
                          console.log("Failed to update report view.", error);
                        });
                    }
                  }}
                >
                  <DoneIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancel">
                <IconButton
                  className={classes.cancelIcon}
                  onClick={(e) => {
                    cleanupReportViewsWithoutNew();
                    setEditRowId(null);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        } else {
          return (
            <>
              <Tooltip title="Edit">
                <IconButton
                  aria-label="view"
                  className={classes.editIcon}
                  onClick={(e) => {
                    cleanupReportViewsWithoutNew();
                    setEditRowId(params.row.id);
                    setEditRowModel(JSON.parse(JSON.stringify(params.row)));
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    className={classes.deleteIcon}
                    onClick={() => {
                      setDeleteDialogOpen(true);
                      setEditRowId(null);
                      setDeleteReportViewId(params.row.id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
              </Tooltip>
            </>
          );
        }
      },
    },
  ];

  const handleDeleteReportView = (reportViewId) => {
    try {
      deleteReportView(reportViewId)
        .then(()=> {
          toast.success("Report view deleted successfully.");
          setReportViews(prev => {
            return prev.filter(tv => tv.id !== reportViewId);
          });
          dispatch(getReportViewsAction(venue));
        })
        .catch(error => toast.error("Failed to delete report view. " + error, { autoClose: false }));
    } catch (error) {
      toast.error("Failed to delete report view. " + error.message, { autoClose: false });
    }
  };

  return (
    <div>
      {venue !== null ? (
        <LoadPleaseWait show={isLoading1 || isLoading2} >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start"
            }}>
            <Typography variant="h5">Tableau API settings</Typography>
            <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "30px"
                }}>
                <TextField
                  id="tableauUsername"
                  label = "Tableau username"
                  placeholder="Tableau username"
                  variant="outlined"
                  name="tableaUsername"
                  value={cachedTableauUsername === null ? tableauUsername : cachedTableauUsername}
                  onChange={(e) => {
                    const { value } = e.target;
                    setCachedTableauUsername(value);
                  }}
                  style={{width: "500px"}}
                  InputProps={cachedTableauUsername !== null ? {
                    endAdornment: (
                      <InputAdornment
                        position="end">
                        <IconButton 
                          edge="end" 
                          className={classes.tableauUsernameOk}
                          onClickCapture={(e) => handleTableauUsernameSave()} 
                          onMouseDown={e => e.stopPropagation()}
                        >
                          <DoneIcon />
                        </IconButton>
                        <IconButton 
                          edge="end" 
                          className={classes.tableauUsernameCancel}
                          onClickCapture={(e) => setCachedTableauUsername(null)} 
                          onMouseDown={e => e.stopPropagation()}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )}
                      :null
                    } 
                  />
                <Box
                  sx={{
                    width: "1200px",
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "40px",
                    justifyContent: "flex-end"
                  }}
                >
                  <Typography 
                    style={{
                      flexGrow: 1
                    }}
                    variant="h5">
                    Reports
                  </Typography>
                  <Tooltip title="Add">
                    <IconButton
                      aria-label="view"
                      className={classes.addIconButton}
                      onClick={(e) => {
                        let newReportView = reportViews.find(a=> a.id === NIL_UUID);
                        let newReportViews = [...reportViews];
                        if (!newReportView) {
                          newReportViews = [
                            ...reportViews, 
                            {
                              id: NIL_UUID, 
                              venueId: venue?.id, 
                              name: "", url: "", type: ""
                          }]
                          setReportViews(newReportViews);
                        }

                        if (!Number.isInteger(newReportViews.length / pageSize))
                          setCurrentPage(parseInt(newReportViews.length / pageSize));
                        setEditRowId(NIL_UUID);
                        setEditRowModel({id: NIL_UUID, venueId: venue?.id, name: "", url: "", type: ""})
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <DataGrid
                  page={currentPage}
                  onPageChange={(pageNo) => {
                    setCurrentPage(pageNo);
                  }}
                  disableSelectionOnClick={true}
                  hideFooterRowCount={true}
                  hideFooterSelectedRowCount={true}
                  autoHeight
                  editMode="row"
                  onPageSizeChange={(val) => {
                    cleanupReportViewsWithoutNew();
                    setPageSize(val);
                  }}
                  pageSize={pageSize}
                  rowsPerPageOptions={[5,10,20]}
                  sortModel={sortModel}
                  onSortModelChange={(model) => setSortModel(model)}
                  sortingOrder={['desc','asc']}
                  columns={columns}
                  rows={reportViews ? reportViews : []}
                  className={classes.dataGridRoot}
                />
                <AlertDialog 
                  open={deleteDialogOpen} 
                  onNoButtonClicked={() => setDeleteDialogOpen(false)} 
                  contentText={`Do you want to delete this report view?`} 
                  onYesButtonClicked={(e) => {
                    handleDeleteReportView(deleteReportViewId);
                    setDeleteDialogOpen(false);
                  }} 
                />
            </Box>
          </Box>
        </LoadPleaseWait>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
    </div>
  );
};

export default ReportViewSettings;