import { Box, Typography, Dialog, DialogTitle, DialogContent, TextField } from "@material-ui/core";
import { Button, ButtonType } from "../../../_common/htmlTags";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import * as venueEventApi from "../../../../_services/venue.event.service";

const useStyles = makeStyles((theme) => ({
    hiddenUploadButton: {
        display: "none"
    },
    dateFrom: {
        marginBottom: "50px",
    },
}));

export const VenueEventsExportModal = ({ showModal, setShowModal, venueId, }) => {
    const classes = useStyles();

    const [exporting, setExporting] = useState(false);
    const [dateFrom, setDateFrom] = useState(null);

    const handleExport = evt => {
        evt.preventDefault();
        if (venueId) {
            venueEventApi.venueEventsExport(venueId, dateFrom)
                .then(() => toast.success("Venue events exported"))
                .catch(error => toast.error("Export failed. " + (error.response?.data ?? error.message ?? error), { autoClose: false }));
        }
    }

    return (
        <Dialog
            open={showModal}
            onClose={() => setShowModal(false)}
            fullWidth={true}
            maxWidth="sm"
        >
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
            >
                <Box
                    display="flex"
                    flex="1"
                    justifyContent="center"
                    height="50px"
                >
                    <DialogTitle
                    >
                        <div>
                            <Typography
                                variant="h5"
                            >
                                Events export
                            </Typography>
                        </div>
                    </DialogTitle>
                </Box>
            </Box>

            <DialogContent
                className={classes.dialogContentContainer}
                style={{ overflowY: 'auto' }}
            >
                <div className={classes.configurationPopup}>
                    <div className={classes.dateFrom}>
                        <TextField
                            id="dateFrom"
                            label="Date from"
                            type="date"
                            variant="outlined"
                            name="dateFrom"
                            value={dateFrom}
                            onChange={e => setDateFrom(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>

                    <div className={classes.buttons}>
                        <Button
                            variant="contained"
                            style={{ marginRight: "15px" }}
                            disabled={exporting}
                            buttonType={ButtonType.Primary}
                            onClick={handleExport}
                        >
                            {exporting ? "Exporting..." : "Export"}
                        </Button>&nbsp;
                        <Button
                            variant="outlined"
                            type="button"
                            buttonType={ButtonType.Secondary}
                            onClick={() => {
                                setShowModal(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog >
    );
}

export default VenueEventsExportModal;