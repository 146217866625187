import React, { useState, useEffect, Fragment } from 'react';
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
//import PermissionsGate from "../../../_common/permissions/PermissionsGate";
import { Box, Modal, TextField, Tooltip, IconButton, Typography } from '@material-ui/core';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {NoVenueSelectedError} from "../../../../_constants";
import {Button, ButtonType} from "../../../_common/htmlTags";
import ImportEvents from "./ImportEvents";
import ExportEvents from './ExportEvents';
import {getAllVenueEvents as getEvents} from "../../../../_services/venue.event.service";
import { toast } from "react-toastify";
import { Calendar } from "./Calendar";
import {Loader} from "./Loader";
import {ViewEvent} from "./ViewEvent";
import {EditEvent} from "./EditEvent";
import { AlertDialog } from "../../../../_helpers/AlertDialog";
import {addVenueEvent, updateVenueEvent, deleteVenueEvent} from "../../../../_services/venue.event.service";

const useStyles = makeStyles((theme) => ({
  whiteBox: {
    background: "white",
    marginTop: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(3),
    padingBottom: theme.spacing(3)
  },
  iconButton: {
    color: theme.palette.color.primary.main,
    cursor: "pointer",
    width: "40px",
    marginBottom:"-30px"
  }
}));

const getYearmonthString = (date) => {
  let month = date.getMonth() + 1;
  if (month < 10) 
    month = "0" + month;
  return `${date.getFullYear()}-${month}`;
};

export const Events = () => {
  const classes = useStyles();

  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);

  const [navigationDate, setNavigationDate] = useState(new Date(Date.now()));
  const [showExportEvents, setShowExportEvents] = useState(false);
  const [showImportEvents, setShowImportEvents] = useState(false);
  const [eventToView, setEventToView] = useState(null);
  const [eventToEdit, setEventToEdit] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (selectedVenue?.id) {
      getEvents(selectedVenue?.id).then(d => {
        setEvents(d.data);
      }).catch(e => {
        console.error("getAllVenueEvents", e);
        toast.error("Failed to load events.");
      })
    }
  }, [selectedVenue?.id]);

  const handleYearmonthChange = (newValue) => {
    if (newValue) {
      setNavigationDate(new Date(newValue + "-01"));
    }
  };

  // Called after the events have been imported.
  // Re-loads the events from the database.
  const handleEventsImported = () => {
    setIsLoading(true);
    getEvents(selectedVenue.id).then(d => {              
      setEvents(d.data);
      setIsLoading(false);
    }).catch(e => {
      console.error("getAllVenueEvents", e);
      toast.error("Failed to load events.", {autoClose: false});
    })
  };

  // Called when the user clicks on the big + button on a date in the calendar.
  const handleEventAdd = (date)=> {
    const event = {
      startDate: new Date(date.getTime())
    };
    setEventToEdit(event);
  };

  // Called when the user clicks on the event box in the calendar grid.
  const handleEventClick = (event)=> {
    setEventToView(event);
  };

  // Called when the user clicks on the Yes button in the event deletion confirmation dialog.
  const handleEventDelete = (event)=> {
    setIsLoading(true);
    setEventToView(null);
    deleteVenueEvent(event.id).then(d => {
      const updatedEvents = [...events].filter(finalEvent => finalEvent.id !== event.id);

      setEvents(updatedEvents);
      // reload events just to make sure changes from db are loaded
      getEvents(selectedVenue.id)
        .then(d => {
          setIsLoading(false);
          setEvents(d.data);
        })
        .catch(error => {
          console.error(`getEvents: ${error.message}`, error);
        });
      toast.success("Event deleted successfully.");
    }).catch(error => {
      setIsLoading(false);
      console.error(`deleteVenueEvent: ${error.message}`, error);
      toast.error(error.message, {autoClose: false});
    });
  };

  // Called when the user clicks the Update button on the event editing dialog.
  const handleEventSave = (event) => {
    //setIsLoading(true);
    

    if( event.id) {
      // Updating the existing event.

      updateVenueEvent(event).then(d => {
        setEventToEdit(null);
        // reload events just to make sure changes from db are loaded
        getEvents(selectedVenue?.id)
          .then(d => {
            setIsLoading(false);
            setEvents(d.data);
          })
          .catch(error => {
            console.error(`getAllVenueEvents: ${error.message}`, error);
          });
        toast.success("Event edited successfully");
      }).catch(error => {
        setIsLoading(false);
        console.error(`updateVenueEvent: ${error.message}`, error);
        toast.error(error.message, {autoClose: false});
      });
    } else {
      // Adding a new event.
      event.venueId = selectedVenue?.id;
      addVenueEvent(event).then(d => {
        setEventToEdit(null);

        // reload events just to make sure changes from db are loaded
        getEvents(selectedVenue?.id)
          .then(d => {
            setIsLoading(false);
            setEvents(d.data);
          })
          .catch(error => {
            console.error(`getEvents: ${error.message}`, error);
          });
        toast.success("Event added successfully.");
      }).catch(error => {
        setIsLoading(false);
        console.error(`addVenueEvent: ${error.message}`, error);
        toast.error(error.message, {autoClose: false});
      });
    }
  };

  return (
    selectedVenue ? 
      <>
        <ExportEvents
          showModal={showExportEvents}
          setShowModal={setShowExportEvents}
          venueId={selectedVenue?.id}
        />
        <ImportEvents 
          showModal={showImportEvents}
          setShowModal={setShowImportEvents}
          venueId={selectedVenue?.id}
          refreshEvents={handleEventsImported}
        />  
        {eventToView? 
          <Modal open>
            <ViewEvent
              event={eventToView}
              onCancel={() => {
                setEventToView(null);
              }}
              onEventEdit={(event) => {
                setEventToView(null);
                setEventToEdit(event);
              }}
              onEventDelete={(event) => {
                setDeleteConfirmationOpen(true);
              }}
            />
          </Modal>
          : null
        }
        {eventToEdit ? 
          <Modal open>
            <EditEvent
              event={eventToEdit}
              onCancel={() => {
                setEventToEdit(null);
              }}
              onSave={handleEventSave}
            />
          </Modal>
          :null
        }
        { deleteConfirmationOpen ?
          <AlertDialog 
            showTitle
            title="Delete event"
            highPriority
            open={deleteConfirmationOpen} 
            contentText={`Do you want to delete event ${eventToView.eventName}?`}
            onNoButtonClicked={() => {
              setDeleteConfirmationOpen(false);
            }}
            onYesButtonClicked={(e) => {
              handleEventDelete(eventToView);
              setDeleteConfirmationOpen(false);
            }} />
            : null
          }
        <Box
          sx={{
            display: "flex",
            flexDirection: "column"
          }}>
          <Typography variant="h4">Events</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column"
            }}
            className={classes.whiteBox}
          >
            <Box // This box contains the navigation controls and import-export buttons.
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <TextField 
                id={"month"} 
                label={"Month"} 
                name={"month"}
                type={"month"}  
                value={getYearmonthString(navigationDate)}
                //value={`${yearmonth.year}-${yearmonth.month < 10 ? "0" + yearmonth.month: yearmonth.month}`} 
                variant="outlined"
                onChange={(e) => handleYearmonthChange(e.target.value)} 
                required 
                style={{width: "250px"}}
              />
              <Button 
                variant="outlined" 
                buttonType={ButtonType.Secondary}
                style={{
                  marginLeft: "40px"
                }}
                onClick={() => {
                  setNavigationDate(new Date(navigationDate.setMonth(navigationDate.getMonth() - 1)));
                }}>
                <ArrowBackIcon />
              </Button>
              <Button 
                variant="outlined" 
                buttonType={ButtonType.Secondary}
                style={{
                  marginLeft: "8px"
                }}
                onClick={() => {
                  setNavigationDate(new Date(navigationDate.setMonth(navigationDate.getMonth() + 1)));
                }}>
                <ArrowForwardIcon />
              </Button>
              <Button 
                variant="outlined" 
                buttonType={ButtonType.Dark}
                style={{
                  marginLeft: "40px"
                }}
                onClick={() => {
                  const today = new Date(Date.now());
                  setNavigationDate(today);
                }}
              >
                Today
              </Button>
              <div style={{flexGrow: 1}} />
              <Tooltip title="Export">
                <IconButton
                  aria-label="view"
                  className={classes.iconButton}
                  onClick={(e) => setShowExportEvents(true)}
                >
                  <CloudDownloadIcon  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Import">
                <IconButton
                  aria-label="view"
                  className={classes.iconButton}
                  onClick={(e) => {setShowImportEvents(true)}}
                >
                  <CloudUploadIcon  />
                </IconButton>
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: "flex",
                //bgcolor: "red",
                mt: "16px",
                mb: "16px"
              }}
            >
              {isLoading ? <Loader /> : null}
              <Calendar
                year={navigationDate.getFullYear()}
                month={navigationDate.getMonth() + 1}
                items={events.map(e => ({
                  ...e,
                  name: e.eventName,
                  start: new Date(e.startDate),
                  end: new Date(e.endDate)
                }))}
                onItemClick={handleEventClick}
                onItemAdd={handleEventAdd}
              />
            </Box>
          </Box>  
        </Box>
      </>
    :
    <NoVenueSelectedError></NoVenueSelectedError>
  );
};

export default Events;