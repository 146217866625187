export const widgetTypeConstants = {
    widgetSample: {
        id: 99, caption: 'Sample', description: 'A sample widget to display dummy data. This is used for developer to test the widget. Please do not include this in the production',
        iconSrc: "../../../icons/moreVert.svg",
        minW: 3, // minimum width
        maxW: Infinity, // maximum width
        minH: 3, // minimum height
        maxH: Infinity, // maximum height
        w: 3, // default width
        h: 3, // default height
    },
    widgetOccupancy: {
        id: 10, caption: 'Occupancy', description: 'A widget to display occupancy value for selected location.',
        iconSrc: "../../../icons/occupancy.svg",
        minW: 3, // minimum width
        maxW: 18, // maximum width
        minH: 3, // minimum height
        maxH: 9, // maximum height
        w: 9, // default width
        h: 3, // default height
    },
    widgetTrafficInOut: {
        id: 11, caption: 'Traffic In/Out', description: 'A widget to display traffic IN / OUT information for selected location.',
        iconSrc: "../../../icons/inOutWidget.svg",
        minW: 3, // minimum width
        maxW: 9, // maximum width
        minH: 3, // minimum height
        maxH: 3, // maximum height
        w: 9, // default width
        h: 3, // default height
    },
    widgetNumber: {
        id: 12, caption: 'Number', description: 'A widget to display pre-defined number metric value for selected location.',
        iconSrc: "../../../icons/number.svg",
        minW: 3, // minimum width
        maxW: Infinity, // maximum width
        minH: 3, // minimum height
        maxH: Infinity, // maximum height
        w: 3, // default width
        h: 3, // default height
    }, 
    widgetMap: {
        id: 13, caption: 'Map', description: 'A widget to display selected venue map and its polygons.',
        iconSrc: "../../../icons/map.svg",
        minW: 6, // minimum width
        maxW: Infinity, // maximum width
        minH: 6, // minimum height
        maxH: Infinity, // maximum height
        w: 6, // default width
        h: 6, // default height 
    },
    widgetFootfall: {
        id: 14, caption: 'Footfall Traffic', description: 'A widget to display footfall traffic.',
        iconSrc: "../../../icons/footfall.svg",
        minW: 3, // minimum width
        maxW: 9, // maximum width
        minH: 3, // minimum height
        maxH: 3, // maximum height
        w: 9, // default width
        h: 3, // default height 
    },

    // This Label widget configuration was made only for testing of label value setting.
    // TODO: Configure it properly when real component will be in work.
    widgetLabel: {
        id: 15, caption: 'Label', description: 'A widget to display specific label.',
        iconSrc: "../../../icons/label.svg",
        minW: 3, // minimum width
        maxW: Infinity, // maximum width
        minH: 1, // minimum height
        maxH: Infinity, // maximum height
        w: 9, // default width
        h: 1, // default height
    },
    widgetCamera: {
        id: 16,
        caption: 'AI Streams',
        description: 'AI Stream live video from camera with overlaid inferencing.',
        iconSrc: "../../../icons/camera.svg",
        minW: 6, // minimum width
        maxW: Infinity, // maximum width
        minH: 4, // minimum height
        maxH: Infinity, // maximum height
        w: 6, // default width
        h: 4, // default height 
    },
    widgetEmbedding: {
        id: 17,
        caption: 'Embedded widget',
        description: 'A widget which is embedded from side service.',
        iconSrc: "../../../icons/widgetEmbedding.svg",
        minW: Infinity, // minimum width
        maxW: Infinity, // maximum width
        minH: Infinity, // minimum height
        maxH: Infinity, // maximum height
        w: 6, // default width
        h: 4, // default height
    },
    widgetDemographic: {
        id: 18, caption: 'Demographic', description: 'A widget to display number metric value for selected location from Inferencing.',
        iconSrc: "../../../icons/Demographic.png",
        minW: 3, // minimum width
        maxW: Infinity, // maximum width
        minH: 3, // minimum height
        maxH: Infinity, // maximum height
        w: 3, // default width
        h: 3, // default height
    },
    widgetQueueModel: {
         id: 19,
         caption: 'Queue Metrics',
         description: 'How long is the queue? What is the wait time? How long does it take to service one person?',
         iconSrc: "../../../icons/queue.svg",
         minW: 6, // minimum width
         maxW: Infinity, // maximum width
         minH: 6, // minimum height
         maxH: Infinity, // maximum height
         w: 6, // default width
         h: 6, // default height
    },
};

export const widgetOccupancyValueTypeConstants = {
    Location: 'trafficLight',
    RawNumber: 'rawNumber',
    Percentage: 'percentage',
};

export default widgetTypeConstants;
