import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Typography,
  TextField,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import {
  updateVenue, 
  getTimeZones,
} from "../../../../_actions/venue.actions";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { toast } from "react-toastify";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import LoadPleaseWait from "../../../notification/LoadingPleaseWait/LoadingMessage"
import { loadDashboardsSimple } from "../../../../_actions/dashboard.actions";
import featureToggle from '../../../../models/featureToggle';
import { Button, ButtonType } from "../../../_common/htmlTags/Button";
import * as venueApi from "../../../../_services/venue.service";

var dateFormat = require("dateformat");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  card: {
    fontFamily: "Manrope",
    borderColor: "#15e577",
    color: "#000",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#15e577",
      borderColor: "#564345",
    },
  },
  formControl: {
    width: "100%",
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  venueId: {
    marginBottom: theme.spacing(2),
    color: theme.palette.color.secondary.main
  },
  venueIdCopy: {
    color: theme.palette.color.secondary.main,
    marginLeft: theme.spacing(1),
  },
  saveButton: {
    marginTop: theme.spacing(2)
  }
}));

function VenueGeneralSetting({selectedVenueId}) {
  const timeZones = useSelector((state) => state.venueReducer.timeZones);
  const dashboards = useSelector((state) => state.dashboards.dashboards);

  const error = useSelector((state) => state.scheduleTasks.error);
  const dispatch = useDispatch();
  const classes = useStyles();

  const [updating, setUpdating] = useState(false);
  const [currentVenue, setCurrentVenue] = useState({});

  const timeZonesLoading = useSelector((state) => state.venueReducer.loading.timeZonesLoading);
  const venueLoading = useSelector((state) => state.venueReducer.loading.venueLoading);
  const dashboardLoading = useSelector((state) => state.dashboards?.loading?.dashboardLoading);

  useEffect(() => {
    dispatch(loadDashboardsSimple(selectedVenueId, false))
      .catch(error => toast.error("Loading dashboards failed: " + error, { autoClose: false }));

    dispatch(getTimeZones()).catch((error) => {
      toast.error("Loading time zones failed. " + error, { autoClose: false });
    });

    venueApi.getVenueById(selectedVenueId).then ((response) => {
      setCurrentVenue(response.data);
    })   
    .catch(
      (error) => {
          toast.error("Loading venue failed. " + error, { autoClose: false });
      }
    );
  }, [dispatch, selectedVenueId]);

  function handleChange(e) {
    const { name, value } = e.target;
    setCurrentVenue((prevTask) => ({
      ...prevTask,
      [name]: value,
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (currentVenue.startDate > currentVenue.endDate) {
      toast.error("Start Date has to be less than End Date");
      return;
    }

    setUpdating(true);

    // update event
    dispatch(updateVenue(currentVenue, selectedVenueId))
    .then(() => {
      if (error !== null && typeof error !== "undefined")
        toast.error("Updating venue failed" + error, { autoClose: false });
      else {
        toast.success("Venue updated successfully.");
        venueApi.getVenueById(selectedVenueId).then ((response) => {
          setCurrentVenue(response.data);
        })   
        .catch(
          (error) => {
              toast.error("Loading venue failed. " + error, { autoClose: false });
          }
        );    
      }
      setUpdating(false);

    })
    .catch((err) => {
      toast.error("Updating venue failed" + err, { autoClose: false });
    });
  }

  return (
    <div>
      {currentVenue !== null ? (
        <LoadPleaseWait show={venueLoading} >
          <div style={{ float: 'left' }}>
            <Typography variant="h5">
              General settings
            </Typography>
            <Grid container style={{height: '65vh'}} >
              <form className={classes.container} onSubmit={handleSubmit}>
                <div className={classes.venueId}>
                  <Typography variant="subtitle2" style={{ verticalAlign: "middle", display: "inline" }} >
                    Venue ID: {currentVenue.id}
                  </Typography>
                  <IconButton style={{ verticalAlign: "middle" }} 
                    classes={{root: classes.venueIdCopy}}
                    aria-label="Copy venue ID to clipboard"
                    onClick={() => {
                      navigator.clipboard.writeText(currentVenue.id);
                    }}
                  >
                    <FileCopyOutlinedIcon fontSize="small" />
                  </IconButton>
                </div>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={7}>
                    <TextField
                      id="venueName"
                      label="Venue name"
                      variant="outlined"
                      fullWidth
                      required
                      name="name"
                      value={currentVenue.name ?? ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      id="venueDescription"
                      label="Venue description"
                      variant="outlined"
                      fullWidth
                      required
                      name="description"
                      value={currentVenue.description ?? ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <FormControl
                      variant="outlined"
                      className={classes.selectControl}
                      fullWidth
                      required
                    >
                      <InputLabel id="role-label">Time zone</InputLabel>
                      <Select
                        labelId="timeZone-label"
                        id="timeZone-select"
                        name="timeZoneId"
                        value={currentVenue.timeZoneId ?? ""}
                        onChange={handleChange}
                        label="TimeZone"
                        disabled={timeZonesLoading || timeZones.length === 0}
                      >
                        {timeZones.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.displayName + " - " + e.id}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={7}
                    style={featureToggle.DEFAULT_DASHBOARD_ENABLED ? { display: 'block' } : { display: 'none' }} >
                    <FormControl
                      variant="outlined"
                      className={classes.selectControl}
                      fullWidth
                    >
                      <InputLabel id="role-label">Default dashboard</InputLabel>
                      <Select
                        labelId="defaultdashboard-label"
                        id="defaultdashboard-select"
                        name="defaultDashboardId"
                        value={currentVenue.defaultDashboardId ?? ""}
                        onChange={handleChange}
                        label="defaultDashboard"
                        disabled={(dashboardLoading ?? true) || dashboards.length === 0}
                      >
                        {dashboards.map((e) => (
                          <MenuItem key={e.id} value={e.id}>
                            {e.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={7}
                    style={{ display: 'none' }}>
                    <TextField
                      id="venueDashboardUrl"
                      label="Dashboard URL"
                      variant="outlined"
                      fullWidth
                      name="venueDashboardUrl"
                      value={currentVenue.venueDashboardUrl ?? ""}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item container>
                    <Grid item xs={5} md={3}>
                      <TextField
                        id="startDate"
                        label="Start date"
                        type="date"
                        name="startDate"
                        value={dateFormat(currentVenue.startDate, "isoDate")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={2} md={1}></Grid>
                    <Grid item xs={5} md={3}>
                      <TextField
                        id="endDate"
                        label="End date"
                        type="date"
                        name="endDate"
                        value={dateFormat(currentVenue.endDate, "isoDate")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>

                  <Grid item container>
                    <Grid item xs={5} md={3}>
                      <TextField
                        id="businessHoursStart"
                        label="Business hours start"
                        type="time"
                        name="businessHoursStart"
                        value={currentVenue.businessHoursStart ?? ""}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={2} md={1}></Grid>
                    <Grid item xs={5} md={3}>
                      <TextField
                        id="businessHoursEnd"
                        label="Business hours end"
                        type="time"
                        name="businessHoursEnd"
                        value={currentVenue.businessHoursEnd ?? ""}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <br />

                  <Grid item xs={12}>
                    <Box
                      component="span"
                      sx={{ marginRight: 15 }}>
                      <Button
                        buttonType={ButtonType.Primary}
                        className={classes.saveButton}
                        disabled={updating}
                        type="submit"
                      >
                        {updating ? "Saving..." : "Save"}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </div>
        </LoadPleaseWait>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
    </div>
  );
}

export default VenueGeneralSetting;
