import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { Grid} from "@material-ui/core";
import {NoVenueSelectedError} from "../../../_constants";
import {getAllVenueEvents} from "../../../_services/venue.event.service";
import {useSelector} from "react-redux";
import {Button} from "../../_common/htmlTags";
import VenueEventCalendar from "./VenueEventCalendar";
import TextLink from "../../_common/htmlTags/TextLink";
import {SCOPES} from "../../../_constants/user.permissions.constants";
import PermissionsGate from "../../_common/permissions/PermissionsGate";
import { toast } from "react-toastify";

export const VenueEvent = () => {
  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);
  const nowDate = new Date(Date.now());
  const [venueEvents, setVenueEvents] = useState([]);
  useEffect(() => {
    if (selectedVenue?.id) {
      getAllVenueEvents(selectedVenue?.id).then(d => {
        
        setVenueEvents(d.data);
      }).catch(e => {
        console.error("getAllVenueEvents", e);
        toast.error("Cannot retrieve venue events!");
      })
    }
  }, [selectedVenue?.id]);


  return selectedVenue === null ? (
    <NoVenueSelectedError></NoVenueSelectedError>
  ) :
  (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} container justifyContent="space-between">
          <Typography variant="h4">Events</Typography>
          {/*
          <div>
            <Button variant="contained" component={TextLink} to={"/eventImport"}>Export</Button>
            <PermissionsGate scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_ADD_EVENTS]}>
              &nbsp;<Button variant="contained" component={TextLink} to={"/eventImport"}>Import</Button>
            </PermissionsGate>
          </div>
          */}
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        <br />
        <VenueEventCalendar venueId={selectedVenue?.id} month={nowDate.getMonth() + 1} year={nowDate.getFullYear()} preloadedEvents={venueEvents} ></VenueEventCalendar>
      </Grid>
    </div>
  );
}
export default VenueEvent;