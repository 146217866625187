import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import {
  Typography,
  IconButton,
  Tooltip,
  Grid,
  Card,
  CardContent, MenuItem, Menu,
  Box,
  FormControlLabel,
} from "@material-ui/core";
import { Button, ButtonType } from "../../_common/htmlTags/Button";
import { Link } from "react-router-dom";
import { TextLink } from "../../_common/htmlTags/TextLink";
import LanguageIcon from '@material-ui/icons/Language';
import { toast } from "react-toastify";
import { loadDashboardsSimple, removeDashboard, removeDashboardRange, setHideInactiveDashboards } from "../../../_actions/dashboard.actions";
import { loadModeList } from '../../../_actions/mode.actions';
import { NoVenueSelectedError } from "../../../_constants/";
import * as dashboardApi from "../../../_services/dashboard.service";
import * as dashboardUserApi from "../../../_services/dashboard.user.service";
import { AlertDialog } from "../../../_helpers/AlertDialog";
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import LoadPleaseWait from "../../notification/LoadingPleaseWait/LoadingMessage";
import StarRateIcon from '@material-ui/icons/StarRate';
import AddIcon from '@material-ui/icons/Add';
import { NIL as NIL_UUID } from "uuid";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {Delete} from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import PeopleIcon from '@material-ui/icons/People';
import {SCOPES} from "../../../_constants/user.permissions.constants";
import PermissionsGate from "../../_common/permissions/PermissionsGate";
import { IOSSwitch } from "../../admin/venueManagement/iosSwitch";
import ToggleOffIcon from '@material-ui/icons/ToggleOffOutlined';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import useLocalStorage from "../../../_helpers/useLocalStorage";
import useSortModel from "../../../_helpers/useSortModel";
import { PinIcon } from "../../../_constants/";

const useStyles = makeStyles((theme) =>
{
  var style = {};
  // for(var i = 0; i < pageSize ; i++) {
  //   style["noStarOuter" + i] = { "&:hover > span:nth-child(2)": { display: "block" }, "&:hover > span:nth-child(1)": { display: "none" } };
  //   style["noStar" + i] = { display: "none" };
  // }

  style["noStarOuter"] = { "&:hover > span:nth-child(2)": { display: "inline" }, "&:hover > span:nth-child(1)": { display: "none" } };
  style["noStar"] = { display: "none" };

  style.userDefaultDashboardSelected = {
    color: "#F4B72A", cursor: "pointer"
  };
  style.userDefaultDashboardNotSelected = {
    color: "darkgray", cursor: "pointer"
  };
  style.icon = {
    color: theme.palette.color.primary.dark
  }
  style.deleteIcon = {
    color: theme.palette.color.primary.red
  }
  style.activateIcon = {
    color: theme.palette.color.primary.green,
  };
  
  style.buttonAddDashboard = {
    width: '17em',
    height: '4em',
  };
  style.cellContent = { display:"flex" };
  style.cellContentNameNotPublic = {
    maxWidth: "290px", 
    overflow: "hidden",
    textOverflow: "ellipsis"
  };
  style.cellContentNamePublic = {
    maxWidth: "220px", 
    overflow: "hidden", 
    textOverflow: "ellipsis"
  };
  style.cellContentPublicTextDiv = {
    width: "52px", 
    alignItems: "center", 
    height: "19px", 
    verticalAlign: "middle"
  };
  style.cellContentPublicTextStyle = {
    background: "rgba(0, 193, 206, 0.2)",
    padding: "1px 10px", 
    borderRadius: "3px",
    color: "#00C1CE"
  };
  
  style.activeCard = {
    width: "52px", 
    alignItems: "center", 
    height: "19px", 
    verticalAlign: "middle"
  };
  style.activeCardText = {
    background: theme.palette.dashboard.inactiveDashboardListCardBackgroundColor,
    padding: "1px 10px", 
    borderRadius: "3px",
    color: theme.palette.dashboard.inactiveDashboardListCardTextColor,
  };

  style.menuIcon = {
    color: "#627E84"
  };
  style.addButton = {
    width: "100px", 
    height: "46px",
  };
  style.notAvailableUser = {
    color: theme.palette.color.secondary.main
  };
  style.deleteSelectedButton = {
    transform: "translate(0, -40px)",
    position: "absolute",
  };

  style.dataGridRoot = {
    '& .MuiDataGrid-cell': {
      color: theme.palette.common.gridRowInactiveTextColor,
    },
  };

  style.userFavoriteDashboardSelected = {
    width: 18,
    height: 18,
    color: "black", 
    cursor: "pointer",
  };
  style.userFavoriteDashboardNotSelected = {
    width: 18,
    height: 18,
    color: "darkgray", 
    cursor: "pointer"
  };

  return style;
});

export const Dashboards = () => {
  const hideInactiveDashboards = useSelector((state) => state.dashboards.preferences.hideInactiveDashboards);
  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);

  const [userFavoriteDashboards, setUserFavoriteDashboards] = useState(null);
  const [userNewFavoriteDashboards, setUserNewFavoriteDashboards] = useState([]); 

  const dashboards = useSelector((state) => {
    var dashboards = state.dashboards.dashboards;
    
    if (hideInactiveDashboards) {
      dashboards = dashboards.filter((dashboard) => dashboard.isActive);
    }

    if (userFavoriteDashboards) {
      dashboards = dashboards.slice().sort((a, b) => {
        if (userFavoriteDashboards.includes(a.id))
          return -1;
      });
    }

    return dashboards;
  });  

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [selectedDashboardId, setSelectedDashboardId] = useState(NIL_UUID);
  const [selectedDashboardName, setSelectedDashboardName] = useState(NIL_UUID);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [userDefaultDashboard, setUserDefaultDashboard] = useState(null);
  const [duplicating, setDuplicating] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useLocalStorage("pmy-dashboards-pageSize", 10);
  const [sortModel, setSortModel] = useSortModel("pmy-dashboards-sortModel", [{field: 'name', sort: 'asc'}]);
  const [selectionModel, setSelectionModel] = useState([]); // rows selected in the list of dashboards.
  const [showConfirmDeleteSelection, setShowConfirmDeleteSelection] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (selectedVenue !== null) {
      setLoading(true);
      dispatch(loadDashboardsSimple(selectedVenue.id, false))
        .then(() => {
          if (!isMounted) return;

          setLoading(false)
        })
        .catch(error => toast.error("Loading dashboards failed: " + error, { autoClose: false }));

      dispatch(loadModeList(selectedVenue?.id))
        .then(() => {
          if (!isMounted) return;
        })
        .catch(error => toast.error("Loading venue mode failed: " + error, { autoClose: false }));
    }

    return () => {
      isMounted = false;
    }
  }, [selectedVenue, dispatch]);

  useEffect(() => {
    let isMounted = true;

    if (userDefaultDashboard === null && selectedVenue !== null) {
      dashboardUserApi.getUserDefaultDashboard(selectedVenue.id).then((result) => {
        if (!isMounted) return;

        setUserDefaultDashboard(result.data);
      }).catch((error) => {
        console.log("Error getUserDefaultDashboard", error);
      });
    }

    if (userFavoriteDashboards === null && selectedVenue !== null) {
      dashboardUserApi.getUserFavoriteDashboards(selectedVenue.id).then((result) => {
        if (!isMounted) return;

        setUserFavoriteDashboards(result.data);
      }).catch((error) => {
        console.log("Error getUserFavoriteDashboards", error);
      });
    }

    return () => {
      isMounted = false;
    }
  }, [userDefaultDashboard, selectedVenue])
  const handleClose = () => {
    setOpen(false);
  };

  const handleRemoveDashboard = (dashboardId) => {
    try {
      dispatch(removeDashboard(dashboardId))
        .then(() => toast.success("Dashboard deleted successfully."))
        .catch(error => toast.error("Failed to delete dashboards: " + error, { autoClose: false }));
    } catch (error) {
      toast.error("Delete failed. " + error.message, { autoClose: false });
    }
  };

  const handleDuplicateDashboard = (dashboardId) => {
    setDuplicating(true);

    dashboardApi.duplicateDashboards(selectedVenue.id, [dashboardId], false)
      .then(() => {
        toast.success("Dashboard duplicated successfully!");

        // reload dashboards
        setLoading(true);
        dispatch(loadDashboardsSimple(selectedVenue.id, false))
          .catch(error => toast.error("Loading dashboards failed " + error, { autoClose: false }))
          .finally(() => setLoading(false));
      })
      .catch(error => toast.error("Duplicating dashboard failed " + error, { autoClose: false }))
      .finally(() => setDuplicating(false));
  }

  const handleActivateDashboards = (dashboardIds) => {
    dashboardApi.activateDashboards(dashboardIds)
      .then(() => {
        toast.success("Dashboard is activated successfully!");

        // reload dashboards
        setLoading(true);
        dispatch(loadDashboardsSimple(selectedVenue.id, false))
          .catch(error => toast.error("Dashboard activation failed " + error, { autoClose: false }))
          .finally(() => setLoading(false));
      })
      .catch(error => toast.error("Dashboard activation failed " + error, { autoClose: false }));
  }
  const handleInactivateDashboards = (dashboardIds) => {
    dashboardApi.inactivateDashboards(dashboardIds)
      .then(() => {
        toast.success("Dashboard is inactivated successfully!");

        // If inactivated dashboard is default - remove this dashboard default status
        if (dashboardIds.includes(userDefaultDashboard)) {
          setUserDefaultDashboard(NIL_UUID);
        }

        // If inactivated dashboards are favorite - remove these dashboards from favorite dashboards state
        setUserFavoriteDashboards(userFavoriteDashboards.filter((ufd) => !dashboardIds.includes(ufd)));
        setUserNewFavoriteDashboards(userNewFavoriteDashboards.filter((ufd) => !dashboardIds.includes(ufd)));

        // reload dashboards
        setLoading(true);
        dispatch(loadDashboardsSimple(selectedVenue.id, false))
          .catch(error => toast.error("Dashboard inactivation failed " + error, { autoClose: false }))
          .finally(() => setLoading(false));
      })
      .catch(error => toast.error("Dashboard inactivation failed " + error, { autoClose: false }));
  }
  const onHideInactiveDashboardsChange = (e) => {
    dispatch(setHideInactiveDashboards(!hideInactiveDashboards));
  };

  const handleDeleteSelection = () => {
    try {
      dispatch(removeDashboardRange(selectionModel))
        .then(() => toast.success("Dashboards deleted successfully."))
        .catch(error => 
          toast.error("Failed to delete dashboards: " + error, { autoClose: false })
        );
    } catch (error) {
      toast.error("Failed to delete dashboards. " + error.message, { autoClose: false });
    }
  }

  const sortByAlphabeticalOrderWithFavoriteDashboards = (a, b, param1, param2) => {
    var left = (a ?? "").toLowerCase(), right = (b ?? "").toLowerCase();

    if (userFavoriteDashboards?.includes(param1.id) && userFavoriteDashboards?.includes(param2.id)) {
      return left < right ? -1 : 1
    }
    
    if (!userFavoriteDashboards?.includes(param1.id) && !userFavoriteDashboards?.includes(param2.id)) {
      return left < right ? -1 : 1
    }
  }
  const sortByDateWithFavoriteDashboards = (a, b, param1, param2) => {
    var left = new Date(a), right = new Date(b);

    if (userFavoriteDashboards?.includes(param1.id) && userFavoriteDashboards?.includes(param2.id)) {
      return left < right ? -1 : 1
    }
    
    if (!userFavoriteDashboards?.includes(param1.id) && !userFavoriteDashboards?.includes(param2.id)) {
      return left < right ? -1 : 1
    }
  }
  const syncExistingAndNewFavoriteDashboards = (model) => {
    if (model[0] != sortModel[0]) {
      setUserFavoriteDashboards([...userFavoriteDashboards, ...userNewFavoriteDashboards]);
      setUserNewFavoriteDashboards([]);
    }
  }

  const columns = [
    {
      field: "id",
      hide: true,
      valueFormatter: (params) => params.row?.id,
    },
    {
      field: "userFavoriteDashboard",
      headerName: " ",
      width: 20,
      sortable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableSelectionOnClick: true,
      disableColumnSelector: true,
      disableDensitySelector: true,      
      renderCell: (params) => {
        if (!params.row.isActive) {
          return <></>
        }

        if (
          (userFavoriteDashboards !== null &&  userFavoriteDashboards.includes(params.row.id))
          ||
          (userNewFavoriteDashboards !== null &&  userNewFavoriteDashboards.includes(params.row.id))
        ) {
          return (
            <>
              <span>&nbsp;&nbsp;</span>
              <Tooltip title="Remove favourite">
                <PinIcon className={classes["userFavoriteDashboardSelected"]} onClick={() => {                  
                  dashboardUserApi.deleteUserFavoriteDashboard(selectedVenue.id, params.row.id).then((result) => {
                    setUserFavoriteDashboards(userFavoriteDashboards.filter((ufd) => ufd != params.row.id));
                    setUserNewFavoriteDashboards(userNewFavoriteDashboards.filter((ufd) => ufd != params.row.id))
                  }).catch((error) => console.log("Error deleteUserFavoriteDashboard", error));                  
                }} />
              </Tooltip>
              <span>&nbsp;&nbsp;</span>
            </>
          );
        }
        else {
          return (
            <>
              <div className={classes[`noStarOuter`]}>
                <span>&nbsp;&nbsp;</span>
                <span className={classes[`noStar`]}>
                  <span>&nbsp;&nbsp;</span>
                  <Tooltip title="Set favourite">
                    <PinIcon className={classes["userFavoriteDashboardNotSelected"]} onClick={() => {
                      dashboardUserApi.setUserFavoriteDashboard(selectedVenue.id, params.row.id).then((result) => {
                        setUserNewFavoriteDashboards([...userNewFavoriteDashboards, params.row.id]);
                      }).catch((error) => console.log("Error setUserFavoriteDashboard", error));
                    }} />
                  </Tooltip>
                </span>
                <span>&nbsp;&nbsp; &nbsp;&nbsp;</span>
              </div>
            </>
          );
        }
      }
    },
    {
      field: "userDefaultDashboard",
      headerName: " ",
      width: 33,
      sortable: false,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableSelectionOnClick: true,
      disableColumnSelector: true,
      disableDensitySelector: true,
      renderCell: (params) => {
        if (!params.row.isActive) {
          return <></>
        }

        if (params.row.id === userDefaultDashboard) {
          return (
            <>
              <span>&nbsp;&nbsp;</span>
              <Tooltip title="Remove default">
                <StarRateIcon className={classes["userDefaultDashboardSelected"]} onClick={() => {
                  dashboardUserApi.deleteUserDefaultDashboard(selectedVenue.id).then((result) => {
                    setUserDefaultDashboard(NIL_UUID);
                  }).catch((error) => console.log("Error deleteUserDefaultDashboard", error));
                }} />
              </Tooltip>
              <span>&nbsp;&nbsp;</span>
            </>
          );
        }
        else {
          return (
            <>
              <div className={classes[`noStarOuter`]}>
                <span>&nbsp;&nbsp;</span>
                <span className={classes[`noStar`]}>
                  <span>&nbsp;&nbsp;</span>
                  <Tooltip title="Set default">
                    <StarRateIcon className={classes["userDefaultDashboardNotSelected"]} onClick={() => {
                      dashboardUserApi.setUserDefaultDashboard(selectedVenue.id, params.row.id).then((result) => {
                        setUserDefaultDashboard(params.row.id);
                      }).catch((error) => console.log("Error setUserDefaultDashboard", error));
                    }} />
                  </Tooltip>
                </span>
                <span>&nbsp;&nbsp;</span>
              </div>
            </>
          );
        }
      }
    },
    {
      field: "name",
      headerName: "NAME",
      width: 300,
      sortComparator: sortByAlphabeticalOrderWithFavoriteDashboards,
      renderCell: (params) => {
        if (!params.row.isProtected) {
          return(
          <div className={classes.cellContent}>
            {params.row.isActive
              ? (<><div className={classes.cellContentNamePublic}><Link to={"/dashboard/" + params.row.id }>{params.row.name}</Link></div>&nbsp;</>)
              : (<><div>{params.row.name}</div>&nbsp;</>)
            }
            
            {params.row.isActive &&
              <div className={classes.cellContentPublicTextDiv}>
                <span className={classes.cellContentPublicTextStyle}>public</span>              
              </div>
            }

            {!params.row.isActive && 
              <div className={classes.activeCard}>
                <span className={classes.activeCardText}>inactive</span>
              </div>
            }
          </div>);
        }
        else {
          return (
            <div className={classes.cellContent}>
              {params.row.isActive
                ? <div className={classes.cellContentNameNotPublic}><Link to={"/dashboard/" + params.row.id }>{params.row.name}</Link></div>
                : <div>{params.row.name}</div>
              }
              
              {!params.row.isActive && 
                <>
                  &nbsp;
                  <div className={classes.activeCard}>
                    <span className={classes.activeCardText}>inactive</span>
                  </div>
                </>
              }
            </div>
          );
        }
      }
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      width: 150,
      flex: 1,
      sortComparator: sortByAlphabeticalOrderWithFavoriteDashboards,
    },
    {
      field: "action",
      sortable: false,
      headerName: " ",
      width: 60,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={(e) => {
              setSelectedDashboardId(params.row.id);
              setAnchorEl(e.currentTarget);
              setIsPublic(!params.row.isProtected);
              setIsActive(params.row.isActive);
              setSelectedDashboardName(params.row.name);
            }}
          >
            <MoreVertIcon className={classes.menuIcon} fontSize="small" />
          </IconButton>
        );
      }
    },
    {
      field: "venueModeName",
      headerName: "MODE",
      width: 150,
      sortComparator: sortByAlphabeticalOrderWithFavoriteDashboards,
    },
    {
      field: "lastUpdated",
      headerName: "LAST UPDATED",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            {(new Date(params.row.lastUpdated.replace("T", " ") + " UTC")).toLocaleString()}
          </>
        );
      },
      sortComparator: sortByDateWithFavoriteDashboards,
    },
    {
      field: "lastUpdatedByUser",
      headerName: "LAST UPDATED BY",
      width: 210,
      renderCell: (params) => {
        return (
          <>
            {params.row.lastUpdatedByUser ?  // lastUpdatedByUser is null if the user has been deleted from STS
              params.row.lastUpdatedByUser
              : <Typography className={classes.notAvailableUser}>not available</Typography>
            }
          </>
        );
      },
      sortComparator: sortByAlphabeticalOrderWithFavoriteDashboards,
    },
  ];
  return selectedVenue === null ? (
    <NoVenueSelectedError></NoVenueSelectedError>
  ) :
    (
      <div>
        <AlertDialog open={open} onNoButtonClicked={handleClose} contentText={`Do you want to delete this dashboard?`} onYesButtonClicked={(e) => {
          handleRemoveDashboard(deleteId);
          setOpen(false);
        }} />
        <AlertDialog 
          open={showConfirmDeleteSelection}
          contentText={`Do you want to delete ${selectionModel.length} ${selectionModel.length === 1 ? "dashboard" : "dashboards"}?`}
          onNoButtonClicked={() => setShowConfirmDeleteSelection(false)}
          onYesButtonClicked={() => {
            setShowConfirmDeleteSelection(false);
            handleDeleteSelection();
          }}
        />
        <Grid container direction="row" justifyContent="space-between">
          <Typography variant="h4">Dashboards</Typography>
        </Grid>

        <PermissionsGate
          scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_UPDATE_DASHBOARD]}
        >
        <Grid container direction="row" justifyContent="space-between">
          <div>
          </div>
          <Button
            className={classes.addButton}
            variant="contained"
            color="primary"
            component={TextLink}
            to="/dashboard"
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        </Grid>

        <Box display="flex" flexDirection="row-reverse" alignItems="stretch" width="100%" mt={2} mb={-2}>
          <Box width={250}>
          <FormControlLabel
            control = {<IOSSwitch
              checked={hideInactiveDashboards}
              onChange={onHideInactiveDashboardsChange}
              onClick={(e) => e.cancelBubble = true}
            />}
            label={hideInactiveDashboards ? "Hide inactive dashboards" : "Show inactive dashboards"}
          />
          </Box>
        </Box>

        </PermissionsGate>
        <br></br>
        <LoadPleaseWait show={loading} >
          <Card style={{ overflow: 'auto' }}>
            <CardContent>
              <div style={{ display: "flex", height: "100%", paddingTop: 15 }}>
                <div style={{ flexGrow: 1 }}>
                  <DataGrid
                    disableSelectionOnClick={true}
                    hideFooterRowCount={true}
                    hideFooterSelectedRowCount={true}
                    autoHeight
                    pageSize={pageSize}
                    onPageSizeChange={(val) => setPageSize(val)}
                    rowsPerPageOptions={[5,10,20]}
                    sortModel={sortModel}
                    onSortModelChange={(model) => {
                      syncExistingAndNewFavoriteDashboards(model);
                      setSortModel(model);
                    }}
                    sortingOrder={['desc','asc']}
                    columns={columns}
                    rows={dashboards === undefined ? [] : dashboards}
                    getRowClassName={(params) => !params.row.isActive ? classes.dataGridRoot : ""}
                    checkboxSelection
                    onSelectionModelChange={(newSelectionModel) => {
                      setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                  />
                  <Button
                    variant="contained"
                    component="span"
                    buttonType={ButtonType.Dark}
                    size="small"
                    className={classes.deleteSelectedButton}
                    disabled={selectionModel.length === 0}
                    onClick={() => {
                      setShowConfirmDeleteSelection(true);
                    }}
                  >
                    {`Delete (${selectionModel.length})`}
                  </Button>
                  <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={(e) => setAnchorEl(null)} >
                      
                    {isActive &&
                      <MenuItem
                        component={TextLink}
                        to={`/dashboard/${selectedDashboardId}`}
                        style={{ textDecoration: 'none', color: "black" }}
                      >
                        <LanguageIcon className={classes.icon} fontSize="small" />&nbsp;View
                      </MenuItem>
                    }

                    {isPublic && isActive ? (
                      <MenuItem
                        component={TextLink}
                        onClick={(e) => {
                          window.open(`/public-dashboard/${encodeURIComponent(selectedVenue?.name)}/${encodeURIComponent(selectedDashboardName)}`);
                        }}
                        style={{ textDecoration: 'none', color: "black" }}
                      >
                        <PeopleIcon className={classes.icon} fontSize="small" />&nbsp;View public
                      </MenuItem>
                    ) : ("")}
                    <PermissionsGate
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_UPDATE_DASHBOARD]}
                    >
                      {isActive &&
                        <MenuItem
                          component={TextLink}
                          to={`/dashboard/${selectedDashboardId}?editMode=true`}
                          style={{ textDecoration: 'none', color: "black" }}
                        >
                          <EditIcon className={classes.icon} fontSize="small" />&nbsp;Edit
                        </MenuItem>
                      }

                      {isActive &&
                        <MenuItem
                          disabled={duplicating}
                          onClick={(e) => {
                            setAnchorEl(null);
                            handleDuplicateDashboard(selectedDashboardId);}}>
                          <FileCopyTwoToneIcon className={classes.icon} fontSize="small" />&nbsp;Duplicate
                        </MenuItem>
                      }
                                                                  
                      <MenuItem
                        onClick={(e) => {
                          isActive ? handleInactivateDashboards([selectedDashboardId]) : handleActivateDashboards([selectedDashboardId]);
                          setAnchorEl(null);
                        }}
                      >                        
                        {isActive 
                          ? (<><ToggleOffIcon className={classes.icon} fontSize="small" />&nbsp;Inactivate {isActive}</>)
                          : (<><ToggleOnIcon className={classes.activateIcon} fontSize="small" />&nbsp;Activate {isActive}</>)
                        }
                      </MenuItem>

                      <MenuItem
                        onClick={(e) => {
                          setAnchorEl(null);
                          setOpen(true);
                          setDeleteId(selectedDashboardId);
                        }}>
                        <Delete className={classes.deleteIcon} fontSize="small" />&nbsp;Delete
                      </MenuItem>
                    </PermissionsGate>
                </Menu>
                </div>
              </div>
            </CardContent>
          </Card>
        </LoadPleaseWait>
      </div>
    );
};
