import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { TabContext, TabPanel } from "@material-ui/lab";
import {
  Box,
  Tabs,
  Typography,
  Card,
  CardContent,  
} from "@material-ui/core";
import PermissionsGate from "../_common/permissions/PermissionsGate";
import WrappedTab from '../_common/permissions/WrappedTab';
import { SCOPES } from "../../_constants/user.permissions.constants";
import { selectFactorTab, clearHistoryFilter } from "../../_actions/factor.actions";
import CurrentFactors from './CurrentFactors';
import FactorHistory from './FactorHistory';
import FactorImport from './FactorImport';

const useStyles = makeStyles(theme => ({
  // KF: The label of the active tab should be highlighted with the green line under the label.
  // I cannot make it work with dynamic tabs. Below is my attempt to fix this, but 
  // result does not look as beautiful, e.g. there ae silly margins on the left and
  // right of the highlighting and there's no animation. That's all I coould do
  // for now. We should give it another go before too long.
  tabsRoot: {
    width: "100%",
    borderBottom: '1px solid #BCCBCE',
    '& .MuiTabs-indicator': {
      backgroundColor: "transparent", // theme.palette.color.primary.main,
    },
  },
  cardRoot: {
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
    marginBottom: theme.spacing(3),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: 0,
  },
  tabLabel: {
    color:  theme.palette.color.secondary.main,
    fontWeight: "400",
    padding: "0px 20px 12px 20px",
    marginBottom: "-11px", 
    // KF - I don't really know what I'm doing with this border bottom, but it makes it look nice.
    borderBottomColor: "transparent",
    borderBottom: '2px solid',
  },
  activeTabLabel: {
    fontWeight: "500",
    color: theme.palette.color.primary.main,
    padding: "0px 20px 10px 20px",
    marginBottom: "-11px",
    borderColor: theme.palette.color.primary.main,
    borderBottom: '4px solid',
  },
  currentFactorsTabPanel: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: 0,
  }
}));

const Factors = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);
  const selectedTabIndex = useSelector((state) => state.factorReducer.selectedTab);

  const [selectedTab, setSelectedTab] = useState(selectedTabIndex ?? "current-factors");

  const handleTabSelectionChange = (e, selected) => {
    setSelectedTab(selected);
    dispatch(selectFactorTab(selected));
  };

  useEffect(() => {
    return () => {
      dispatch(clearHistoryFilter());
    };
  }, []);

  return (
    <>
      {selectedVenue !== null && selectedVenue !== undefined ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            minHeight: 0,
            minWidth: "300px"
          }}>
        <Typography variant="h4" className={classes.title}>
          Factors
        </Typography>
        <br></br>

        <Card 
          className={classes.cardRoot}
          >
          <CardContent
            className={classes.cardContent}>
            <TabContext value={selectedTab}>
               <Tabs value={selectedTab} classes={{ root: classes.tabsRoot }} onChange={handleTabSelectionChange}>
                  <WrappedTab
                    scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_FACTORS]}
                    label={<div className={selectedTab === "current-factors" ? classes.activeTabLabel : classes.tabLabel}>CURRENT FACTORS</div>}
                    value="current-factors" />
                  <WrappedTab
                    scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_FACTORS]}
                    label={<div className={selectedTab === "factor-history" ? classes.activeTabLabel : classes.tabLabel}>HISTORY</div>}
                    value="factor-history" />
                  <WrappedTab
                    scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_FACTORS]}
                    label={<div className={selectedTab === "factor-inferencing" ? classes.activeTabLabel : classes.tabLabel}>IMPORT</div>}
                    value="factor-inferencing" />
                </Tabs>
              <TabPanel
                value="current-factors"
                className={classes.currentFactorsTabPanel}>
                <PermissionsGate
                  scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_FACTORS]} >
                  <CurrentFactors venue={selectedVenue} />
                </PermissionsGate>
              </TabPanel>
              <TabPanel
                value="factor-history">
                <PermissionsGate
                  scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_FACTORS]} >
                  <FactorHistory venue={selectedVenue}/>
                </PermissionsGate>
              </TabPanel>
              <TabPanel
                value="factor-inferencing">
                <PermissionsGate scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_FACTORS]}>
                  <FactorImport venue={selectedVenue} />
                </PermissionsGate>
              </TabPanel>
            </TabContext>
          </CardContent>
        </Card>
      </Box>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
    </>
  );
};

export default Factors;