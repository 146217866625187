import {widgetTypeConstants } from "../../../_constants";
import { WidgetWrapper } from "../WidgetWrapper";
import { TrafficInOut } from "./TrafficInOut";

export const WidgetTrafficInOut = (props) => (
        <WidgetWrapper
            {...{...props, widgetType: widgetTypeConstants.widgetTrafficInOut.id}}
        >
            <TrafficInOut />
        </WidgetWrapper>
    );

export default WidgetTrafficInOut;
