import React, { useEffect, useContext, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { widgetSettingsConstants, widgetSettingsDataTypesConstants } from "../../../_constants/widget.settings.constants";
import widgetTypeConstants from "../../../_constants/widget.type.constants";
import { getWidgetSettingsRefsList } from "../../../_reducers/widget.data.selectors";
import CheckboxSetting from "./settingComponents/CheckboxSetting";
import InputSetting from "./settingComponents/InputSetting";
import SelectSetting from "./settingComponents/SelectSetting";
import MultipleSelectSetting from "./settingComponents/MultipleSelectSetting";
import MappingEntitiesSetting from "./settingComponents/MappingEntitiesSetting";
import WidgetUnknown from "../WidgetUnknown";
import SettingsDependencyContext from "./SettingsDependencyContext";
import { DATASOURCEID_RF_SENSORS } from "../../../_constants/dataSource.constants";
import { ALERT_TYPE_PERCENT } from "../../../_constants/dashboard.alertRuleSet.constants";
import { NIL as NIL_UUID } from "uuid";

const DISPLAY_NONE = "none";
const DISPLAY_BLOCK = "block";

/**
 * This method allows to manage what component is used for specific setting data type.
 */
function getSettingComponent(settingDataType) {
    switch (settingDataType) {
        case widgetSettingsDataTypesConstants.boolean:
            return CheckboxSetting;

        case widgetSettingsDataTypesConstants.integer:
            return InputSetting;

        case widgetSettingsDataTypesConstants.refId:
            return SelectSetting;

        case widgetSettingsDataTypesConstants.string:
            return InputSetting;

        case widgetSettingsDataTypesConstants.refIdList:
            return MultipleSelectSetting;

        case widgetSettingsDataTypesConstants.refTable:
            return MappingEntitiesSetting;

        default:
            return WidgetUnknown;
    }
}

function isArmNumberWidgetValueType(valueTypeId) {
    const armIds = ['numberOfPatrons', 'numberOfReturnPatrons', 'numberOfNewPatrons', 'dwellTime'];
    return valueTypeId && armIds.includes(valueTypeId);
}

/**
 * Component is responsible for importing and selecting 
 * necessary Setting component (from ./settingComponents) depending on className.
 */
export const SettingComponentImport = (
    widgetTypeId,
    settingClassName,
    settingDataType,
    settingData,
    handleSettingValueChange
) => {
    const settingElementRef = useRef();

    const settingsDependencyContextData = useContext(SettingsDependencyContext);

    const indicatorVendorMappings = useSelector(state => state.scheduleTasks.indicatorVendorMappings);
    /**
     * Below is assignment of necessary Setting Component using getSettingComponent() function by setting data type.
     */
    let SettingComponent = getSettingComponent(settingDataType);

    /**
     * Below are available data for options for "SelectSetting" component.
     */

    const { vendors, maps, locations, trafficInOutMetrics, footfallTrafficMetrics, occupancyViewModes, trafficTypes, cohorts,
        numberWidgetValueTypes, numberWidgetTimePeriods, demographicWidgetValueTypes, demographicWidgetTimePeriods, 
        demographicWidgetAgeGroup, demographicWidgetGender, mapWidgetIndicators, cameras, dashboardAlertRuleSets, queueModelAlertMetrics, 
    } = useSelector(state => getWidgetSettingsRefsList(state));

    var selectionOptions = [];
    switch (settingClassName) {
        case widgetSettingsConstants.refVendor.className:
            selectionOptions = vendors;
            break;

        case widgetSettingsConstants.refMap.className:
            selectionOptions = maps;
            // Set selected map to context to use it in dependent settings (here is for refTableMapAlerts).
            settingsDependencyContextData.setSelectedMap(maps.find(e => e.id === settingData.value));
            break;

        case widgetSettingsConstants.refLocation.className:
            selectionOptions = locations;
            break;

        case widgetSettingsConstants.widgetSettingTimePeriod.className:
            if (widgetTypeId === widgetTypeConstants.widgetTrafficInOut.id)
                selectionOptions = trafficInOutMetrics;
            if (widgetTypeId === widgetTypeConstants.widgetFootfall.id)
                selectionOptions = footfallTrafficMetrics;
            break;

        case widgetSettingsConstants.refOccupancyViewMode.className:
            selectionOptions = occupancyViewModes;
            break;

        case widgetSettingsConstants.refTrafficType.className:
            selectionOptions = trafficTypes;
            break;

        case widgetSettingsConstants.refListCohort.className:
            selectionOptions = cohorts.map(e => ({ id: e, name: e }));
            break;

        case widgetSettingsConstants.refNumberWidgetValueType.className:
            selectionOptions = numberWidgetValueTypes;
            let numberValueType = numberWidgetValueTypes.find(e => e.id === settingData.value);
            if (numberValueType)
                settingsDependencyContextData.setSelectedNumberValueType(numberValueType);
            break;
        
        case widgetSettingsConstants.refDemographicWidgetValueType.className:
            selectionOptions = demographicWidgetValueTypes;
            let demographicValue = demographicWidgetValueTypes.find(e => e.id === settingData.value);
            if (demographicValue !== undefined && demographicValue !== null)
                settingsDependencyContextData.setSelectedDemographicValueType(demographicValue);

            break;
       
        case widgetSettingsConstants.refDemographicWidgetTimePeriod.className:
            selectionOptions = demographicWidgetTimePeriods;
            break;
        case widgetSettingsConstants.refDemographicGenderList.className:
            selectionOptions = demographicWidgetGender;
            break;
        case widgetSettingsConstants.refDemographicAgeGroup.className:
            selectionOptions = demographicWidgetAgeGroup;
            break;
        
        case widgetSettingsConstants.refNumberWidgetTimePeriod.className:
            selectionOptions = numberWidgetTimePeriods;
            break;

        case widgetSettingsConstants.refMapIndicator.className:
            selectionOptions = mapWidgetIndicators;
            // Set selected Map Indicator to context to use it in dependent settings

            let selectedIndicator = mapWidgetIndicators
                .find(e => e.id === settingData.value);
            if (selectedIndicator)
                selectedIndicator.isArmDataSource = indicatorVendorMappings
                    .some(ivm => ivm.indicatorId === selectedIndicator.id && ivm.vendorId === DATASOURCEID_RF_SENSORS);

            settingsDependencyContextData.setSelectedMapIndicator(selectedIndicator);
            break;

        case widgetSettingsConstants.refListLocation.className:
            selectionOptions = locations;
            break;

        case widgetSettingsConstants.refWidgetSettingCameraUrl.className:
            selectionOptions = cameras;
            break;

        case widgetSettingsConstants.refAlertRuleSet.className:
            selectionOptions = dashboardAlertRuleSets;

            if (widgetTypeId !== widgetTypeConstants.widgetOccupancy.id) {
                selectionOptions = selectionOptions.filter(o => o?.alertType !== ALERT_TYPE_PERCENT);
            }

            break;

        case widgetSettingsConstants.refTableMapAlerts.className:
            let selectedMapOverlays = [];
            if (settingsDependencyContextData.selectedMap) {
                selectedMapOverlays = settingsDependencyContextData.selectedMap.overlays.map(overlay => ({
                    id: overlay.id,
                    name: locations.find(location => location.id === overlay.locationId)?.name
                }))
            }

            selectionOptions = {
                "mainEntity": selectedMapOverlays,
                "selectiveEntity": dashboardAlertRuleSets
            };

            break;

        case widgetSettingsConstants.refQueueModelAlertMetric.className:
            selectionOptions = queueModelAlertMetrics;
            break;

        default:
            break;
    }

    /**
     * Below setting's "mandatory" property is defined.
     */
    let required = false;
    switch (settingDataType) {
        case widgetSettingsDataTypesConstants.refId:
            required = true;

            if (settingClassName === widgetSettingsConstants.refAlertRuleSet.className)
                required = false;

            break;

        case widgetSettingsDataTypesConstants.refIdList:
            required = true;
            break;

        case widgetSettingsDataTypesConstants.string:
            required = true;
            break;

        case widgetSettingsDataTypesConstants.integer:
            required = true;
            break;
        default:
            required = false;
            break;
    }

    /*
    Below are defined logic and conditions to hide/show specific setting:
    */
    const [currentParentElement, setCurrentParentElement] = useState();

    useEffect(() => {
        if (settingElementRef.current)
            setCurrentParentElement(settingElementRef.current?.parentElement);
    }, [settingElementRef, settingsDependencyContextData])

    const displayParentElement = (display) => {
        if (currentParentElement)
            currentParentElement.style.display = display;
    }

    // ----------------------------------------------------------------------------------------------------------------------------------
    /*
    Below is defined any additional logic for settings depending on setting class.
    */
    let additionalSettingsLogic = {
        checkedItems: [],
        mapWidgetResetPercentAlerts: (selectedMapIndicatorName, widgetSettingWithValue) => {},
        filterAlertsByIndicator: (selectedMapIndicatorName, alertType) => {},
    };
    switch (settingClassName) {
        case widgetSettingsConstants.refListCohort.className:
            additionalSettingsLogic.checkedItems = cohorts.map(e => e);
            break;

        case widgetSettingsConstants.refTableMapAlerts.className:
            additionalSettingsLogic.mapWidgetResetPercentAlerts = (selectedMapIndicatorName, widgetSettingWithValue) => {
                if (selectedMapIndicatorName !== widgetTypeConstants.widgetOccupancy.caption) {
                    let newMappingFormValues = widgetSettingWithValue?.value.slice();
        
                    let selectedPercentOptions = selectionOptions.selectiveEntity.filter(e => newMappingFormValues.map(it => it[1].value).includes(e.id) && e?.alertType == ALERT_TYPE_PERCENT);
        
                    selectedPercentOptions.forEach(so => {
                        if (newMappingFormValues.find(nm =>  nm[1].value == so.id)) {
                            newMappingFormValues.find(nm =>  nm[1].value == so.id)[1].value = NIL_UUID;
                        }
                    });
        
                    handleSettingValueChange(widgetSettingWithValue, newMappingFormValues);
                }
            }

            additionalSettingsLogic.filterAlertsByIndicator = (selectedMapIndicatorName, alertType) => selectedMapIndicatorName !== widgetTypeConstants.widgetOccupancy.caption ? alertType !== ALERT_TYPE_PERCENT : true;

            break;


        default:
            break;
    }

    // 1. If in Map widget "Occupancy" is selected as Indicator => hide Time Period setting
    if (
        widgetTypeId === widgetTypeConstants.widgetMap.id
        &&
        settingsDependencyContextData.selectedMapIndicator?.name === "Occupancy"
        &&
        settingClassName === widgetSettingsConstants.refNumberWidgetTimePeriod.className
    ) {
        displayParentElement(DISPLAY_NONE);

        return <div ref={settingElementRef}></div>
    }

    // 2. If in Map widget selected Indicator is not mapped with ARM data source => hide Cohorts setting
    else if (
        widgetTypeId === widgetTypeConstants.widgetMap.id
        &&
        !settingsDependencyContextData.selectedMapIndicator?.isArmDataSource
        &&
        settingClassName === widgetSettingsConstants.refListCohort.className
    ) {
        displayParentElement(DISPLAY_NONE);

        return <div ref={settingElementRef}></div>
    }
    else if (widgetTypeId === widgetTypeConstants.widgetDemographic.id) { 

        let valueTypeId = settingsDependencyContextData.selectedDemographicValueType?.id;
        if (valueTypeId !== null && valueTypeId !== undefined) {
            if (valueTypeId === widgetSettingsConstants.refDemographicGenderList.className &&
                settingClassName === widgetSettingsConstants.refDemographicAgeGroup.className) {
                displayParentElement(DISPLAY_NONE);
                return <div ref={settingElementRef}></div>
            }
            else if (valueTypeId === widgetSettingsConstants.refDemographicAgeGroup.className &&
                settingClassName === widgetSettingsConstants.refDemographicGenderList.className) {
                displayParentElement(DISPLAY_NONE);
                return <div ref={settingElementRef}></div>
            }
            else { 

                displayParentElement(DISPLAY_BLOCK);
    
                return (
                    <SettingComponent
                        ref={settingElementRef}
                        widgetSettingWithValue={settingData}
                        handleSettingValueChange={handleSettingValueChange}
                        selectionOptions={selectionOptions}
                        required={required}
                        additionalSettingsLogic={additionalSettingsLogic}
                    />
                );
            }
        }
        else if (settingClassName === widgetSettingsConstants.refDemographicGenderList.className ||
            settingClassName === widgetSettingsConstants.refDemographicAgeGroup.className) {
            displayParentElement(DISPLAY_NONE);
            return <div ref={settingElementRef}></div>
        }
        else { 

            displayParentElement(DISPLAY_BLOCK);

            return (
                <SettingComponent
                    ref={settingElementRef}
                    widgetSettingWithValue={settingData}
                    handleSettingValueChange={handleSettingValueChange}
                    selectionOptions={selectionOptions}
                    required={required}
                    additionalSettingsLogic={additionalSettingsLogic}
                />
            );
        }
    }

    // Hide cohorts for to number widget and non-ARM value types
    else if (widgetTypeId === widgetTypeConstants.widgetNumber.id &&
        settingClassName === widgetSettingsConstants.refListCohort.className &&
        !isArmNumberWidgetValueType(settingsDependencyContextData.selectedNumberValueType?.id)) {

            displayParentElement(DISPLAY_NONE);
            return <div ref={settingElementRef}></div>
    }
    else {
        displayParentElement(DISPLAY_BLOCK);

        return (
            <SettingComponent
                ref={settingElementRef}
                widgetSettingWithValue={settingData}
                handleSettingValueChange={handleSettingValueChange}
                selectionOptions={selectionOptions}
                required={required}
                additionalSettingsLogic={additionalSettingsLogic}
            />
        );
    }
}