import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import {
  loadIndicatorScheduleMappings,
  loadIndicatorVendorMappings,
  loadIndicatorsWithVendorList,
} from "../../_actions/schedule.task.actions";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Button, ButtonType } from "../_common/htmlTags/Button";
import { Typography, Card, CardContent } from "@material-ui/core";
import * as taskApi from "../../_services/scheduledTasks.service";
import { NIL as NIL_UUID } from "uuid";
import { toast } from "react-toastify";
import {
  loadLocations,
  loadLocationMapping
} from "../../_actions/location.mapping.actions";
import { DataGridLoadingOverlay } from '../_common/DataGridLoadingOverlay';
import LoadPleaseWait from "../notification/LoadingPleaseWait/LoadingMessage";
import devconsole from "../_common/devconsole";

var dateFormat = require("dateformat");

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

// This function makes array of objects with children flat recursively.
function flattenList(array) {
  var flattened = [];
  (function flat(array) {
    array.forEach(function (el) {
      if (Array.isArray(el.children) && el.children) flat(el.children);
      flattened.push(el);
    });
  })(array);
  return flattened;
}

function getSteps() {
  return [
    "Choose indicator",
    "Choose data source",
    "Choose locations",
    "Setup schedule",
    "Create scheduled task",
  ];
}
const AddNewTask = () => {
  const loading = useSelector((state) => state.scheduleTasks.loading);
  const locationMappingsLoading = useSelector((state) => state.locationMapping.loading);

  const indicatorVendorMappings = useSelector((state) => state.scheduleTasks.indicatorVendorMappings.sort((a, b) => a.vendorName.localeCompare(b.vendorName)));
  const indicatorsWithVendorList = useSelector((state) => state.scheduleTasks.indicatorsWithVendorList.sort((a, b) => a.name.localeCompare(b.name)));
  const indicatorScheduleMappings = useSelector((state) => state.scheduleTasks.indicatorScheduleMappings);
  const vendorLocationMappings = useSelector((state) => state.locationMapping.locationMappings);
  const locations = useSelector((state) => state.locationMapping.locations);
  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const steps = getSteps();

  const [selectedModelIndicator, setSelectedModelIndicator] = useState([]);
  const [selectedModelVendor, setSelectedModelVendor] = useState([]);
  const [selectedIndicator, setSelectedIndicator] = useState("");
  const [selectedIndicatorVendorMapping, setSelectedIndicatorVendorMapping] = useState({});
  const [selectedVendorLocationMappings, setSelectedVendorLocationMappings] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState("");
  const [processStart, setProcessStart] = useState("now");
  const [scheduleNextDate, setScheduleNextDate] = useState(Date.now());

  const [isLoadingIndicatorWithVendorList, setIsLoadingIndicatorWithVendorList] = useState(true);
  const [isLoadingIndicatorVendorMappings, setIsLoadingIndicatorVendorMappings] = useState(true);
  const [isLoadingIndicatorScheduleMappings, setIsLoadingIndicatorScheduleMappings] = useState(true);
  const [isLoadingLocationMappings, setIsLoadingLocationMappings] = useState(true);
  const [isLoadingLocations, setIsLoadingLocations] = useState(true);

  const [activeStep, setActiveStep] = useState(0);
  const [state, setState] = useState({
    indicatorsWithVendorList: [],
    indicatorVendorMappings: [],
    indicatorScheduleMappings: [],
    vendorLocationMappings: [],
    locations: [],
    selectedSchedule: null,
  });

  const scheduleNextChanged = (venue) => {
    setScheduleNextDate(new Date(venue.target.value));
  }

  const processStartChanged = (venue) => {
    setProcessStart(venue.target.value);
  }

  const ScheduleFrequencyChanged = (venue) => {
    setSelectedSchedule(venue.target.value);
  }

  useEffect(() => {
    setState({
      indicatorsWithVendorList: indicatorsWithVendorList.filter(a => a.isUsedInScheduleTask ).map((e) => ({ ...e, selected: false })),
      indicatorVendorMappings: indicatorVendorMappings.map((e) => ({ ...e, selected: false })),
      indicatorScheduleMappings: indicatorScheduleMappings.map((e) => ({ ...e, selected: false })),
      vendorLocationMappings: vendorLocationMappings.map((e) => ({ ...e, selected: false })),
      locations: locations,
    });
  }, [indicatorsWithVendorList, indicatorVendorMappings, indicatorScheduleMappings, vendorLocationMappings, locations]);
  
  useEffect(() => {
    setIsLoadingIndicatorWithVendorList(true);
    setIsLoadingIndicatorVendorMappings(true);
    setIsLoadingIndicatorScheduleMappings(true);
    setIsLoadingLocationMappings(true);
    setIsLoadingLocations(true);

    dispatch(loadIndicatorsWithVendorList()).then((d) => { setIsLoadingIndicatorWithVendorList(false); }).catch((error) => {
      toast.error("Loading indicators with vendors failed. " + error, { autoClose: false });
    });

    dispatch(loadIndicatorVendorMappings()).then((d) => { setIsLoadingIndicatorVendorMappings(false); }).catch((error) => {
      toast.error("Loading indicator-vendor mappings failed. " + error, { autoClose: false });
    });

    dispatch(loadIndicatorScheduleMappings()).then((d) => { setIsLoadingIndicatorScheduleMappings(false); }).catch((error) => {
      toast.error("Loading indicator-schedule mapping failed. " + error, { autoClose: false });
    });

    dispatch(loadLocationMapping(selectedVenue.id)).then((d) => { setIsLoadingLocationMappings(false); }).catch((error) => {
      toast.error("Loading LocationMappings failed. " + error, { autoClose: false });
    });

    dispatch(loadLocations(selectedVenue.id)).then(d => { setIsLoadingLocations(false); }).catch((error) => {
      toast.error("Loading locations failed. " + error, { autoClose: false });
    })
  }, [dispatch, selectedVenue.id]);
  
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <div className={classes.instructions}>
              <Typography variant="h6">
                Choose what data you would like to collect
              </Typography>
            </div>
            <div>
              <DataGrid
                autoHeight
                checkboxSelection={false}
                disableMultipleSelection={true}
                pageSize={20}
                columns={[
                  {
                    field: "id",
                    hide: true,
                  },
                  {
                    field: "name",
                    headerName: "Indicator",
                    width: 300,
                  },
                ]}
                rows={state.indicatorsWithVendorList}
                loading={loading && loading.loadIndicatorsWithVendorList}
                components={{
                  LoadingOverlay: DataGridLoadingOverlay,
                }}
                selectionModel={selectedModelIndicator}
                onSelectionModelChange={(newSelection) => {
                  setSelectedModelIndicator(newSelection);
                  setSelectedModelVendor([]); /// reset value for vendor
                  if (newSelection.length === 0)
                    setSelectedIndicator("");
                  else if (newSelection.length >= 1)
                    setSelectedIndicator(newSelection[0]);
                }}
              />
            </div>
          </>
        );
      case 1:
        return (
          <>
            <div className={classes.instructions}>
              <Typography variant="h6">
                Choose data source to collect the data from
              </Typography>
            </div>
            <div>
              <DataGrid
                autoHeight
                checkboxSelection={false}
                disableMultipleSelection={true}
                pageSize={20}
                columns={[
                  {
                    field: "id", // mapping id
                    hide: true,
                  },
                  {
                    field: "vendorName",
                    headerName: "Data Source",
                    width: 300,
                  },
                ]}
                rows={state.indicatorVendorMappings.filter((x) => x.indicatorId === selectedIndicator && x.canBeScheduled)}
                loading={loading && loading.loadIndicatorVendorMappings}
                components={{
                  LoadingOverlay: DataGridLoadingOverlay,
                }}
                selectionModel={selectedModelVendor}
                onSelectionModelChange={(newSelection) => {
                  setSelectedModelVendor(newSelection);
                  setSelectedSchedule(""); // reset schedule drop down selection
                  setProcessStart("now"); // reset process start radio selection
                  if (newSelection.length === 0)
                    setSelectedIndicatorVendorMapping({});
                  else if (newSelection.length >= 1)
                    setSelectedIndicatorVendorMapping(state.indicatorVendorMappings.filter(
                      (e) => newSelection.includes(e.id)
                    )[0]);
                }}
              />
            </div>
          </>
        );
      case 2:
        //All locations - parents and their children.
        var locationsFlatList = flattenList(state.locations);

        //Getting vendor location mappings with location names and their parent's names.
        var locationMappingsByVendor = state.vendorLocationMappings.filter(
          locationMappingByVendor => locationMappingByVendor.vendorId === selectedIndicatorVendorMapping.vendorId
        );
        var vendorLocationMappingsWithNames = locationMappingsByVendor.map(locationMapping => {
          const location = locationsFlatList.find(location => location.id === locationMapping.locationId);
          const locationName = location?.name;
          const locationParentId = location?.parentId;
          const locationType = location?.locationType?.name;
          const locationTypeGroup = location?.locationType?.locationTypeGroup?.name;
          return { ...locationMapping, locationName, locationParentId, locationType, locationTypeGroup }
        }).filter(a => a.locationTypeGroup !== 'Sensor'); /// remove sensor from the list for all indicators because we cannot get data for sensor (it has to be from location)
        vendorLocationMappingsWithNames.forEach(vendorLocationMapping => {
          if (vendorLocationMapping.locationParentId)
            vendorLocationMapping.locationParentName = locationsFlatList.find(
              location => location.id === vendorLocationMapping.locationParentId
            ).name;
        });
        devconsole.log("vendorLocationMappingsWithNames", vendorLocationMappingsWithNames);
        vendorLocationMappingsWithNames = vendorLocationMappingsWithNames.sort((a,b) => (a.locationParentName??"").localeCompare(b.locationParentName??""));
        state.vendorLocationMappingsWithNames = vendorLocationMappingsWithNames;
        
        return (
          <>
            <div className={classes.instructions}>
              <Typography variant="h6">
                Choose locations to collect the data for
              </Typography>
            </div>
            <div>
              <DataGrid
                autoHeight
                checkboxSelection
                pageSize={20}
                columns={[
                  {
                    field: "id",
                    hide: true,
                  },
                  {
                    field: "locationName",
                    headerName: "Location Name",
                    width: 300,
                  },
                  {
                    field: "locationParentName",
                    headerName: "Parent Name",
                    width: 300,
                  },
                  {
                    field: "locationType",
                    headerName: "Location Type",
                    width: 300,
                  },
                ]}
                rows={vendorLocationMappingsWithNames}
                loading={locationMappingsLoading && locationMappingsLoading.locationMappingsLoading}
                components={{
                  LoadingOverlay: DataGridLoadingOverlay,
                }}
                selectionModel={selectedVendorLocationMappings}
                onSelectionModelChange={(newSelection) => {
                  if (newSelection.length === 0)
                    setSelectedVendorLocationMappings([]);
                  else if (newSelection.length >= 1)
                    setSelectedVendorLocationMappings(newSelection);
                }}
              />
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className={classes.instructions}>
              <Typography variant="h6">
                Choose when to collect the data
              </Typography>
            </div>
            <LoadPleaseWait show={loading} >
              <div>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="vendor-label">Schedule</InputLabel>
                  <Select
                    labelId="vendor-label"
                    id="id"
                    name="indicatorScheduleMappingId"
                    label="Schedule"
                    value={selectedSchedule}
                    onChange={ScheduleFrequencyChanged}
                  >
                    {state.indicatorScheduleMappings.filter((x) => x.indicatorId === selectedIndicator)
                      .map((e) => (
                        <MenuItem key={e.id} value={e.id}>
                          {e.scheduleName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </LoadPleaseWait>
            <br />
            <div>
              <FormControl component="fieldset" >
                <FormLabel><Typography>Start data collection:</Typography></FormLabel>
                <RadioGroup row aria-label="quiz" name="quiz" value={processStart} onChange={processStartChanged} >
                  <FormControlLabel value="now" control={<Radio />} label="Now" />
                  <FormControlLabel value="future" control={<Radio />} label={
                    <TextField
                      id="scheduleNext"
                      type="datetime-local"
                      onChange={scheduleNextChanged}
                      defaultValue={dateFormat(scheduleNextDate, "isoDateTime").substring(0, 16)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  } />
                </RadioGroup>
              </FormControl>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className={classes.instructions}>
              <Typography variant="h6">Summary</Typography>
            </div>
            <div>
              <Typography component="div" variant="body1">
                Collect this data:
                {state.indicatorsWithVendorList
                  .filter((e) => e.id === selectedIndicator)
                  .map((e) => (
                    <Typography variant="body1" key={e.id}>- {e.name}</Typography>
                  ))}
                from this data source:
                {state.indicatorVendorMappings
                  .filter((e) => e.id === selectedIndicatorVendorMapping.id)
                  .map((e) => (
                    <Typography variant="body1" key={e.vendorId}>- {e.vendorName} </Typography>
                  ))}
                for these locations:
                {state.vendorLocationMappingsWithNames
                  .filter((e) => selectedVendorLocationMappings.includes(e.id))
                  .map((e) => (
                    <Typography variant="body1" key={e.id}>- {e.locationName}</Typography>
                  ))}
                {state.indicatorScheduleMappings
                  .filter((e) => e.id === selectedSchedule)
                  .map((e) => (
                    <Typography variant="body1" key={e.scheduleId}>{e.scheduleName}</Typography>
                  ))}
                Start data collection: {(processStart === "now") ? "Now" : (new Date(scheduleNextDate)).toString()}
              </Typography>
            </div>
          </>
        );
      default:
        return "Unknown step";
    }
  };

  const handleNext = () => {
    if (activeStep === 0) {
      if (selectedModelIndicator.length === 0) {
        toast.error("At least one indicator must be selected.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
    }
    if (activeStep === 1) {
      if (selectedModelVendor.length === 0) {
        toast.error("At least one data source must be selected.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
    }
    if (activeStep === 2) {
      if (selectedVendorLocationMappings.length === 0) {
        toast.error("At least one location must be selected.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
    }
    if (activeStep === 3) {
      if (selectedSchedule === "") {
        toast.error("Data collection schedule must be selected.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
    }

    if (activeStep === steps.length - 1) {
      // create task
      let createdDateUTC = new Date();
      var processStartDateUTC = createdDateUTC;
      if (processStart === "future") { /// when set to "future", validate the value should be greater than the current created date
        processStartDateUTC = new Date(scheduleNextDate);
        if (createdDateUTC > processStartDateUTC) {
          toast.error("The data collection start time cannot be in the past.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }
      }
      state.indicatorsWithVendorList.forEach((ind) => {
        if (ind.id === selectedIndicator) {
          state.indicatorVendorMappings.forEach((v) => {
            if (v.id === selectedIndicatorVendorMapping.id) {
              state.indicatorScheduleMappings.forEach((s) => {
                if (s.id === selectedSchedule) {

                  var task = {};
                  task.id = NIL_UUID;
                  task.venueId = selectedVenue.id;
                  task.name = `${ind.name} - ${v.vendorName}`;
                  task.description = `Collect ${ind.name} data from ${v.vendorName} ${s.scheduleName}`;
                  task.operationalIndicatorId = ind.id;
                  task.vendorId = v.vendorId;
                  task.frequencyId = s.scheduleId;
                  task.enabled = true;
                  task.createdDateUTC = createdDateUTC.toUTCString();
                  task.startProcessDateTimeUTC = processStartDateUTC.toUTCString();
                  task.vendorLocationMappings = [];
                  selectedVendorLocationMappings.forEach(selectedVendorLocationMapping => {
                    task.vendorLocationMappings.push({ "id": selectedVendorLocationMapping });
                  });

                  try {
                    taskApi
                      .addTask(task)
                      .then((response) => {
                        toast.success("Adding task successful.");
                        history.push("/scheduled-tasks");
                      })
                      .catch((error) => {
                        toast.error("Adding task failed. " + error, { autoClose: false });
                        history.push("/scheduled-tasks");
                      });
                  } catch (err) {
                    console.log("Adding task failed.", err);
                    toast.error("Adding task failed. " + err, { autoClose: false });
                  }
                }
              })
            }
          });
        }
      });
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Card style={{ overflow: 'auto' }}><CardContent>
        {selectedVenue !== null ? (
          (!isLoadingLocations &&
            !isLoadingLocationMappings &&
            !isLoadingIndicatorWithVendorList &&
            !isLoadingIndicatorVendorMappings &&
            !isLoadingIndicatorScheduleMappings) ? (
            <div className={classes.root}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <div>
                <br />
                {activeStep === steps.length ? (
                  <div>
                    <Typography className={classes.instructions}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Button
                      className={classes.button}
                      component={Link}
                      to="/scheduled-tasks"
                    >
                      Go To Tasks
                    </Button>
                  </div>
                ) : (
                  <div>
                    {getStepContent(activeStep)}
                    <div>
                      <br />
                      <Button
                        variant="outlined"
                        buttonType={ButtonType.Secondary}
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? "Create" : "Next"}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>Loading page. Please wait...</div>
          )
        ) : (
          <div>
            <Typography variant="h4">Please select venue first</Typography>
          </div>
        )}
      </CardContent></Card>
    </>
  );
};

export default AddNewTask;
