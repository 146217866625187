import React from "react";
import { Link } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { getMainDrawerMenuTop, mainDrawerMenuBottom } from "../models/menus";
import {AppBar, CssBaseline, Divider, Drawer, Hidden, Typography, IconButton, Tooltip} from "@material-ui/core";
import { List, ListItem, ListItemIcon, ListItemText, Toolbar } from "@material-ui/core";
import { Chip, Menu, MenuItem } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { AccountCircle, Person, ExitToApp } from "@material-ui/icons";
import MyRoutes from "./route";
import { useEffect } from 'react';
import { AuthService } from '../_services/auth.service';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import NotificationPanel from './notification/NotificationPanel'
import * as userPermissionsConstants from "../_constants/user.permissions.constants";
import { PermissionDenied } from "./PermissionDenied";
import {   
    selectVenue,   
    getReportViews as getReportViewsAction
} from "../_actions/venue.actions";
import {   
    useSessionStorage,   
} from "./_common/useSessionStorage"; 
import {ErrorBoundary } from './_common/ErrorBoundary';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const authService = new AuthService();


const drawerWidth = 255;

const login = () => {
    authService.login();
};

const useStyles = makeStyles((theme) => ({
    greyColor:{ color: theme.palette.color.secondary.main},
    root: {
        display: "flex",
        "& .MuiListItemIcon-root": {
            minWidth: "28px"    
        },
        "& .MuiSvgIcon-root": {
            fontSize: "1.4rem"
        }, 
        ...theme.scrollbar
    },   
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        background: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        background: theme.palette.menu.background,
        color: theme.palette.menu.color,    
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        minHeight: '100vh',
        backgroundColor: theme.palette.color.primary.background,
        //paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    pageContent: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        minHeight: 0,
        paddingTop: theme.spacing(3),
    },
    icon: {
        color: theme.palette.menu.color,
    },
    menu: {
        fontSize: 14,
        fontWeight: 500
    },
    divider: {
        background: "#304046",
    },
    title: {
        flexGrow: 1,
        color: "#000",
    },
    logo: {
        width: 94,
        margin: 18,
        marginBottom: "10px",
    },
    nested: {
        paddingLeft: theme.spacing(4),
        "&:hover": {
            marginLeft: "5px",
        },
    },
    accountCircle: {
        fill: theme.palette.color.primary.dark
    },
    userProfileMenuIcon: {
        marginRight: "5px", 
        fontSize: "large"
    },
    menuItem: {
        "&:hover": {
            marginLeft: "5px",
        },
    },
    helpContainer: {
        //display: (window.screen.width < 1024 ? "none" : "block"),
        position: "relative",
    },
    helpButton: {
        position: "absolute",
        right: 5,
        bottom: 5,
        padding: "5px 5px",
        borderRadius: "60px",
        border: "none",
        backgroundColor: "#008CBA", /* Change this to your preferred color */
        color: "white", /* Change this to your preferred color */
        cursor: "pointer",
        transition: "background-color 0.3s ease",
        "&:hover": {
            backgroundColor: "#006994" /* Change this to your preferred color */
        }
    },
    helpIcon: {
        fontSize: "40px"
    },
    helpIframe: {
        display: "none",
        position: "absolute",
        bottom: "60px", /* Adjust this to account for the larger button */
        right: 10,
        zIndex: 1,
        border: "1px solid silver",
        boxShadow: "5px"
    }
}));

function Home(props) {
    const dispatch = useDispatch();
    const classes = useStyles();    
    const theme = useTheme();
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);   
    const [user, setUser] = React.useState(null);
    const [userAvailablePages, setUserAvailablePages] = React.useState([]);
    const open = Boolean(anchorEl);
    
    const [venueSessionStorage, setVenueSessionStorage] = useSessionStorage(`${"selectedVenue." + user?.profile.sub}`, null);
    const selectedVenue = useSelector(
        (state) => state.venueReducer.selectedVenue
    ) ?? (venueSessionStorage);

    const reportViews = useSelector(
        (state) => state.venueReducer.reportViews
    );

    const handleMenu = (e) => {
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getUser = () => {
        authService.getUser().then((user) => {
            setUser(user);
            if (user &&
                Object.values(userPermissionsConstants.ROLES).includes(user.profile.role) &&
                Object.keys(userPermissionsConstants.AVAILABLE_PAGES_BY_ROLE).includes(user.profile.role)
            ) {
                setUserAvailablePages(
                    userPermissionsConstants.AVAILABLE_PAGES_BY_ROLE[user.profile.role].map(
                        (page) => page[userPermissionsConstants.AVAILABLE_PAGE_NAME_KEY]
                    )
                );
            }
        });
    };

    const handleLogout = () => {
        authService.logout();
        setAnchorEl(null);
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        getUser();
    }, []);
    
    useEffect(() => { 
        //saving selected venue to session 
        if (user?.profile?.sub !== undefined && selectedVenue !== undefined) {
            setVenueSessionStorage(selectedVenue);
            dispatch(selectVenue(selectedVenue));
        }
    },[selectedVenue, user?.profile.sub]) 

    useEffect(() => {
        if (selectedVenue) {
            dispatch(getReportViewsAction(selectedVenue));
        }
    }, [selectedVenue]);

    let preferred_username = "";
    if (user) preferred_username = user.profile.given_name + ' ' + user.profile.family_name; //user.profile.preferred_username;

    const [openSubMenu, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!openSubMenu);
    };

    const drawer = (
        <div>
            <div className={classes.toolbar} style={{textAlign:"center"}}>
                <img src="logo_white.png" alt="" className={classes.logo}></img>
            </div>
            <Divider className={classes.divider} />
            <List>
                {getMainDrawerMenuTop(reportViews).map((item, index) => {
                    if (userAvailablePages && (userAvailablePages.includes("*") || userAvailablePages.includes(item.name)))
                    {
                        if (item.subMenu?.length > 0) { //sub menu
                            return (
                                <div key={index}>
                                    <ListItem className={classes.menuItem} button key={item.name} onClick={handleClick}>
                                        <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
                                        <ListItemText>
                                            <Typography className={classes.menu}>{item.name}</Typography>
                                        </ListItemText>
                                        {openSubMenu ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>

                                    <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {item.subMenu?.map((s) =>
                                                <ListItem button key={s.name} className={classes.nested} component={Link} to={s.link}>
                                                    <ListItemIcon></ListItemIcon>
                                                    <ListItemText>
                                                        <Typography className={classes.menu}>{s.name}</Typography>
                                                    </ListItemText>
                                                </ListItem>

                                            )}
                                        </List></Collapse>
                                </div>
                            )
                        }
                        else {
                            return (

                                <ListItem className={classes.menuItem} button key={item.name} component={Link} to={item.link} onClick={() => mobileOpen ? handleDrawerToggle() : ''}>
                                    <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
                                    <ListItemText>
                                        <Typography className={classes.menu}>{item.name}</Typography>
                                    </ListItemText>
                                </ListItem>
                            )
                        }
                    } else {
                        return null;
                    }
                })}

            </List>
            {userAvailablePages &&
                (userAvailablePages.includes("*") ||
                    mainDrawerMenuBottom.filter(
                        mainDrawerMenuBottomItem => userAvailablePages.includes(mainDrawerMenuBottomItem.name)
                    ).length > 0
                ) ? (<Divider className={classes.divider} />) : (<></>)
            }

            <List>
                {mainDrawerMenuBottom.map((item, index) => {
                    if (userAvailablePages && (userAvailablePages.includes("*") || userAvailablePages.includes(item.name))) {
                        return (
                            <ListItem className={classes.menuItem} button key={item.name} component={Link} to={item.link} onClick={() => mobileOpen ? handleDrawerToggle() : ''}>
                                <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
                                <ListItemText>
                                    <Typography className={classes.menu}>{item.name}</Typography>
                                </ListItemText>
                            </ListItem>
                        )
                    } else {
                        return null;
                    }
                })}
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;
    let hide = "";
    if (!user) {
        hide = "hide";
    }
    else {
        /// check expiry
        if (user.expired) { /// when expired, go to logout page
            hide = "hide";
            login();
        }
    }

    if (user &&
        !(Object.values(userPermissionsConstants.ROLES).includes(user.profile.role) &&
          Object.keys(userPermissionsConstants.AVAILABLE_PAGES_BY_ROLE).includes(user.profile.role))
    )
        return <PermissionDenied />;

    return (
        <div className={hide}>
            <ErrorBoundary>
                <div className={classes.root}>
                    <CssBaseline />
                    <AppBar position="fixed" className={classes.appBar} elevation={2}>
                        <Toolbar>
                            <IconButton
                                color="primary"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography
                                variant="h5"
                                noWrap
                                className={classes.title}
                            >
                                {selectedVenue ?
                                    <div display="flex">
                                        <div className={classes.greyColor} style={{float:"left", paddingRight:"5px"}}>Current venue: </div>                                
                                        <div>{selectedVenue.name}</div>
                                    </div>
                                    : "Please, select venue to manage"}
                            </Typography>

                            <NotificationPanel />

                            <div>
                                <Chip
                                    label={preferred_username}
                                    avatar={<AccountCircle className={classes.accountCircle} />}
                                    onClick={handleMenu}         
                                    style={{backgroundColor: theme.palette.color.primary.background}}
                                />
                                <Menu
                                    id="profile-menu"
                                    anchorEl={anchorEl}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem component={Link} to="/user-profile">
                                        <Person className={classes.userProfileMenuIcon} />
                                        Profile
                                    </MenuItem>
                                    <MenuItem onClick={handleLogout}>
                                        <ExitToApp className={classes.userProfileMenuIcon} />
                                        Sign out
                                    </MenuItem>
                                </Menu>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <nav className={classes.drawer} aria-label="mailbox folders">
                        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                        <Hidden smUp implementation="css">
                            <Drawer
                                container={container}
                                variant="temporary"
                                anchor={theme.direction === "rtl" ? "right" : "left"}
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                ModalProps={{
                                    keepMounted: true, // Better open performance on mobile.
                                    animation: "false"
                                }}
                            >
                                {drawer}
                            </Drawer>
                        </Hidden>
                        <Hidden xsDown implementation="css">
                            <Drawer
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                variant="permanent"
                                open
                            >
                                {drawer}
                            </Drawer>
                        </Hidden>
                    </nav>
                    <main className={classes.content} name="main">
                        <div className={classes.toolbar} />
                        <div className={classes.pageContent}>
                            <MyRoutes />
                        </div>                        
                    </main>
                    <div className={classes.helpContainer}>
                        <div className={classes.helpButton}  onClick={(e) => {
                            let myFrame = document.getElementById("myIframe").style.display;
                            if (myFrame === "block")
                                document.getElementById("myIframe").style.display = "none";
                            else
                                document.getElementById("myIframe").style.display = "block";
                        }} >
                            <Tooltip title="OPTIC User Guide">
                                <HelpOutlineIcon style={{fontSize: 40}} /> 
                            </Tooltip>
                        </div>
                        <iframe id="myIframe" 
                                src={process.env.REACT_APP_AI_BOT_URL}
                                className={classes.helpIframe}  name="codyai" scrolling="no" frameBorder="1"
                                marginHeight="0" marginWidth="0" height="550px" width="350px"
                                allow={"fullscreen"} ></iframe>
                    </div>
                </div>
                <ToastContainer
                    enableMultiContainer
                    autoClose={3000}
                    hideProgressBar
                    position={toast.POSITION.BOTTOM_LEFT}
                    newestOnTop
                />
            </ErrorBoundary>
        </div>
    );
};

function mapStateToProps(state) {

    return {
    };
}

const connectedHomePage = connect(mapStateToProps)(Home);
export { connectedHomePage as Home };