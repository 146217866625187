import React, { useState } from 'react';
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { DataGrid } from "@material-ui/data-grid";
import { AlertDialog } from "../../../_helpers/AlertDialog";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
    Typography,
    IconButton,
    Tooltip,
    Grid,
} from "@material-ui/core";
import {
    deleteMode,
} from '../../../_actions/mode.actions';
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';
import useLocalStorage from "../../../_helpers/useLocalStorage";
import useSortModel from "../../../_helpers/useSortModel";

const useStyles = makeStyles((theme) => {
    return {
        icon: {
            color: theme.palette.color.primary.dark,
            "&:hover": {
                color: theme.palette.color.primary.main,
            },   
        },
        deleteIcon: {
            color: theme.palette.color.primary.red
        },
        addButton: {
            color: theme.palette.color.primary.main,
            cursor: "pointer",
            width: "40px",
            marginBottom:"-20px",
        },
    };
});

const ModeItem = props => {
    const classes = useStyles();
    const { data = [] } = props;
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [modeId, setModeId] = useState(null);
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useLocalStorage("pmy-modeItems-pageSize", 5);
    const [sortModel, setSortModel] = useSortModel("pmy-modeItems-sortModel", [{field: 'name', sort: 'asc'}]);

    const handleDelete = (modeId) => {
        try {
            const found = data.find((e) => e.id === modeId);
            if (!found) toast.error("Venue mode not found!");

            dispatch(deleteMode(modeId))
                .then(() => {
                    toast.success("Venue mode has been deleted");
                })
                .catch((err) => {
                    toast.error("Deleting venue mode failed. " + err.message, { autoClose: false });
                });
        } catch (error) {
            toast.error("Deleting venue mode failed. " + error.message, {
                autoClose: false,
            });
        }
    };

    const columns = [
        {
            field: "id",
            hide: true,
        },
        {
            field: "name",
            headerName: "NAME",
            flex: 1,
        },
        {
            field: "description",
            headerName: "DESCRIPTION",
            flex: 1,
        },
        {
            field: "actions",
            headerName: "ACTION",
            width: 170,
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Edit">
                            <IconButton
                                aria-label="edit"
                                className={classes.icon}
                                color="primary"
                                component={Link}
                                to={`/settings/mode/${params.row.id}`}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span>
                                <IconButton
                                    aria-label="delete"
                                    color="primary"
                                    className={classes.deleteIcon}
                                    disabled={params.row.enabled}
                                    onClick={() => {
                                        setDeleteOpen(true);
                                        setModeId(params.row.id);
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <AlertDialog open={deleteOpen} contentText={`Do you want to delete this venue mode?`}
                onNoButtonClicked={() => {
                    setDeleteOpen(false);
                }}
                onYesButtonClicked={(e) => {
                    handleDelete(modeId);
                    setDeleteOpen(false);
                }} />

            <Grid container direction="row" justifyContent="space-between" style={{width: "60%"}}>
                <Typography variant="h5">Venue modes</Typography>
                <Tooltip title="Add">
                  <IconButton
                    aria-label="view"
                    className={classes.addButton}
                    component={Link}
                    to="/settings/mode"
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
            </Grid>

            <div style={{ display: "flex", height: "100%", width: "60%", paddingTop: 15 }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid autoHeight
                        pageSize={pageSize}
                        rowsPerPageOptions={[5, 10, 20]}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        sortModel={sortModel}
                        onSortModelChange={(model) => setSortModel(model)}
                        sortingOrder={['desc','asc']}
                        columns={columns}
                        rows={data}
                        />
                </div>
            </div>
        </>
    )

};

export default ModeItem;