import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadSchedules,
  loadVendors,
  loadIndicators,
  updateTask,
  loadTaskById,
  loadIndicatorScheduleMappings,
  loadIndicatorVendorMappings,
  loadLocations,
  loadLocationMapping,
} from "../../_actions";
import EditTaskForm from "./EditTaskForm";
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { NoVenueSelectedError } from "../../_constants";

// This function makes array of objects with children flat recursively.
function flattenList(array) {
  var  flattened = [];
  (function flat(array) {
    array.forEach(function (el) {
      if (Array.isArray(el.children) && el.children) flat(el.children);
      flattened.push(el);
    });
  })(array);
  return flattened;
}

const EditTaskPage = () => {
  const locationMappingsLoading = useSelector((state) => state.locationMapping.loading);
  const taskByIdLoading = useSelector((state) => state.scheduleTasks.loading);
  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);
  const tasks = useSelector((state) => state.scheduleTasks.tasks);
  const indicators = useSelector((state) => state.scheduleTasks.indicators);
  const vendorLocationMappings = useSelector((state) => state.locationMapping.locationMappings);
  const locations = useSelector((state) => state.locationMapping.locations);
  const indicatorVendorMappings = useSelector((state) => state.scheduleTasks.indicatorVendorMappings);
  const indicatorScheduleMappings = useSelector((state) => state.scheduleTasks.indicatorScheduleMappings);
  
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  
  const [isLoadingIndicators, setIsLoadingIndicators] = useState(false);
  const [isLoadingVendors, setIsLoadingVendors] = useState(false);
  const [isLoadingSchedules, setIsLoadingSchedules] = useState(false);
  const [isLoadingTaskById, setIsLoadingTaskById] = useState(false);
  const [isLoadingLocations, setIsLoadingLocations] = useState(false);
  const [isLoadingLocationMappings, setIsLoadingLocationMappings] = useState(false);
  const [isLoadingIndicatorVendorMappings, setIsLoadingIndicatorVendorMappings] = useState(false);
  const [isLoadingIndicatorScheduleMappings, setIsLoadingIndicatorScheduleMappings] = useState(false);
  
  const [filteredDataSources, setFilteredDataSources] = useState([]);
  const [filteredVendorLocationMapping, setFilteredVendorLocationMapping] = useState([]);
  const [filteredSchedules, setFilteredSchedules] = useState([]);
  
  const [selectedVendorLocationMappings, setSelectedVendorLocationMappings] = useState([]);
  const [selectedIndicatorId, setSelectedIndicatorId] = useState(null);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);

  const [name, setName] = useState(null);
  const [enabled, setEnabled] = useState(false);
  const [description, setDescription] = useState(null);
  const [createdDateUTC, setCreatedDateUTC] = useState(null);
  const [startProcessDateTimeUTC, setStartProcessDateTimeUTC] = useState(null);

  
  const [task, setTask] = useState({});

  useEffect(() => {

    const newTask = {
      id: null,
      name: "",
      description: "",
      operationalIndicator: [],
      operationalIndicatorId: null,
      vendor: [],
      vendorId: null,
      frequency: [],
      frequencyId: null,
      enabled: true,
      vendorLocationMappings: [],
    };
    
    const taskToEdit =
      id && tasks.length > 0 ? tasks.find((t) => t.id === id) || null : newTask;
    setTask(taskToEdit);
    setIsLoadingTaskById(true);
    dispatch(loadTaskById(selectedVenue?.id ?? 0, id))
      .then((d) => {
        setIsLoadingTaskById(false);
      })
      .catch((error) => {
        toast.error("Loading task failed. " + error, {autoClose: false});
      });

    if (taskToEdit) {
      setName(taskToEdit.name);
      setDescription(taskToEdit.description);
      setEnabled(taskToEdit.enabled);
      setSelectedVendorId(taskToEdit.vendorId);
      setSelectedIndicatorId(taskToEdit.operationalIndicatorId);
      setSelectedSchedule(taskToEdit.frequencyId);
      setCreatedDateUTC(taskToEdit.createdDateUTC);
      setStartProcessDateTimeUTC(taskToEdit.startProcessDateTimeUTC);
      setSelectedVendorLocationMappings(taskToEdit.vendorLocationMappings);
    }
  }, [dispatch, id, selectedVenue?.id, tasks]);
  
  useEffect(() => {
    if (selectedVenue && selectedVenue?.id) {
      setIsLoadingLocations(true);
      dispatch(loadLocations(selectedVenue?.id ?? 0))
        .then((d) => {
          setIsLoadingLocations(false);
        })
        .catch((error) => {
          toast.error("Loading locations failed. " + error, {autoClose: false});
        });

      setIsLoadingLocationMappings(true);
      dispatch(loadLocationMapping(selectedVenue?.id ?? 0))
        .then((d) => {
          setIsLoadingLocationMappings(false);
        })
        .catch((error) => {
          toast.error("Loading LocationMappings failed. " + error, {autoClose: false});
        });

      setIsLoadingIndicators(true);
      dispatch(loadIndicators())
        .then((d) => {
          setIsLoadingIndicators(false);
        })
        .catch((error) => {
          toast.error("Loading indicators failed. " + error, {autoClose: false});
        });

      setIsLoadingIndicatorVendorMappings(true);
      dispatch(loadIndicatorVendorMappings())
        .then((d) => {
          setIsLoadingIndicatorVendorMappings(false);
        })
        .catch((error) => {
          toast.error("Loading indicator-data source mappings failed. " + error, {autoClose: false});
        });

      setIsLoadingIndicatorScheduleMappings(true);
      dispatch(loadIndicatorScheduleMappings())
        .then((d) => {
          setIsLoadingIndicatorScheduleMappings(false);
        })
        .catch((error) => {
          toast.error("Loading indicator-schedule mapping failed. " + error, {autoClose: false});
        });

      setIsLoadingVendors(true);
      dispatch(loadVendors())
        .then((d) => {
          setIsLoadingVendors(false);
        })
        .catch((error) => {
          toast.error("Loading data sources failed. " + error, {autoClose: false});
        });

      setIsLoadingSchedules(true);
      dispatch(loadSchedules())
        .then((d) => {
          setIsLoadingSchedules(false);
        })
        .catch((error) => {
          toast.error("Loading schedules failed. " + error, {autoClose: false});
        });
    }
  }, [dispatch, selectedVenue, task]);

  /// update data source and schedules when there is changes to indicators
  useEffect(() => {
    const filteredIndicatorScheduleMapping = indicatorScheduleMappings.filter(
      (a) => a.indicatorId === selectedIndicatorId
    );
    const filteredSchedules = filteredIndicatorScheduleMapping.map((a) => ({
      id: a.scheduleId,
      name: a.scheduleName,
    }));
    setFilteredSchedules(filteredSchedules);

    const filteredIndicatorVendorMappings = indicatorVendorMappings.filter(
      (a) => a.indicatorId === selectedIndicatorId && a.canBeScheduled
    );
    const filteredDataSources = filteredIndicatorVendorMappings.map((a) => ({
      id: a.vendorId,
      name: a.vendorName,
    }));
    setFilteredDataSources(filteredDataSources);
  }, [selectedIndicatorId, indicatorScheduleMappings, indicatorVendorMappings]);

  useEffect(() => {
    const getVendorLocationMappingsWithNames = (vendorId) => {
      //All locations - parents and their children.
      const locationsFlatList = flattenList(locations);

      //Getting vendor location mappings with location names and their parent's names.
      const locationMappingsByVendor = vendorLocationMappings.filter(
        locationMappingByVendor => locationMappingByVendor.vendorId === vendorId
      );

      let vendorLocationMappingsWithNames = locationMappingsByVendor.map(locationMapping => {
        const locationName = locationsFlatList.find(
          location => location.id === locationMapping.locationId
        )?.name;
             
        const locationParentId = locationsFlatList.find(
          location => location.id === locationMapping.locationId
        )?.parentId;

        return { ...locationMapping, locationName, locationParentId }
        
      });

      vendorLocationMappingsWithNames = vendorLocationMappingsWithNames.filter(loc => loc.locationName !== undefined);
      vendorLocationMappingsWithNames.forEach(vendorLocationMapping => {
        if (vendorLocationMapping.locationParentId)
          vendorLocationMapping.locationParentName = locationsFlatList.find(
            location => location.id === vendorLocationMapping.locationParentId
          ).name;
      });

      return vendorLocationMappingsWithNames;
    };
    
    setFilteredVendorLocationMapping(getVendorLocationMappingsWithNames(selectedVendorId));
  }, [selectedVendorId, locations, vendorLocationMappings]);

  function handleSave(venue) {
    venue.preventDefault();
    
    if(selectedVendorLocationMappings.length === 0){
      toast.error("Cannot save the schedule. Please select at least one location.", { autoClose: false });
      return; 
    }
    
    if (task.id) {   
      dispatch(updateTask(task))
        .then(() => {
          toast.success("Updating task successful.");
          history.push("/scheduled-tasks");
        })
        .catch((error) => {
          toast.error("Updating task failed. " + error.message, { autoClose: false });
        });
    } else
      toast.error(
        "Cannot create task here. Please use wizard to create new task.",
        { autoClose: false }
      );
  }

  function handleNameChange(venue) {
    task.name = venue.target.value;
    setTask(task);
    setName(venue.target.value);
  }

  function handleDescriptionChange(venue) {
    task.description = venue.target.value;
    setTask(task);
    setDescription(venue.target.value);
  }

  function handleEnabledChange(venue) {
    task.enabled = venue.target.checked;
    setTask(task);
    setEnabled(venue.target.checked);
  }

  function handleVendorChange(venue) {
    const selectedVendorId = venue.target.value;
    task.vendorId = selectedVendorId;
    setTask(task);
    setSelectedVendorId(selectedVendorId);
    setSelectedVendorLocationMappings([]);
  }

  function handleIndicatorChange(venue) {
    task.operationalIndicatorId = venue.target.value;
    setTask(task);
    setSelectedIndicatorId(venue.target.value);
  }

  function handleScheduleChange(venue) {
    task.frequencyId = venue.target.value;
    setTask(task);
    setSelectedSchedule(venue.target.value);
  }

  function handleLocationChange(e) {
    let selectedValues = vendorLocationMappings.filter(a => e?.includes(a.id));
    task.vendorLocationMappings = selectedValues;
    setTask(task);
    setSelectedVendorLocationMappings(selectedValues);
  }
  
  let isLoading = ( taskByIdLoading && !isLoadingIndicators && !isLoadingVendors && !isLoadingSchedules &&
                    !isLoadingTaskById && !isLoadingLocations && !isLoadingLocationMappings &&  
                    !isLoadingIndicatorVendorMappings && !isLoadingIndicatorScheduleMappings );
  return selectedVenue === null? (
    <NoVenueSelectedError />
  ) : (
    <EditTaskForm
      indicators={indicators.filter(a => a.isUsedInScheduleTask )}
      filteredDataSources={filteredDataSources}
      filteredSchedules={filteredSchedules}
      filteredVendorLocationMapping={filteredVendorLocationMapping}
      description={description}
      name={name}
      taskEnabled={enabled}
      createdDateUTC={createdDateUTC}
      selectedIndicatorId={selectedIndicatorId}
      selectedVendorId={selectedVendorId}
      startProcessDateTimeUTC={startProcessDateTimeUTC}
      vendorLocationMappings={vendorLocationMappings}
      selectedSchedule={selectedSchedule}
      selectedVendorLocationMappings={selectedVendorLocationMappings}
      onNameChange={handleNameChange}
      onDescriptionChange={handleDescriptionChange}
      onEnabledChange={handleEnabledChange}
      onSave={handleSave}
      onVendorChange={handleVendorChange}
      onIndicatorChange={handleIndicatorChange}
      onLocationChange={handleLocationChange}
      onScheduleChange={handleScheduleChange}
      locationMappingsLoading={locationMappingsLoading}
      isLoading={isLoading}
    />
  );
};

export default EditTaskPage;
