import React, { useCallback, useState, useRef, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Modal } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Button, ButtonType } from "../_common/htmlTags";
import PolygonCreator from "./PolygonCreator";
import PolygonEditor from "./PolygonEditor";
import PolygonViewer from "./PolygonViewer";
import Legend from "./Legend";
import SelectLocation from "./SelectLocation";
import {getVenueLocations} from "../../_services/venue.locations.service";

// This variable is used for debugging mounting/unmounting.
//let componentCounter = 0;

const useStyles = makeStyles( theme => { 
  return {
    root: {
      //maxWidth: "800px"
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      minHeight: 0
    },
    hiddenLayout : {
      visibility: "hidden",
      maxWidth: 0,
      maxHeight: 0
    },
    layoutDiv : {
      //width: "100%",
    },
    currentLocationName: {
      marginLeft: theme.spacing(8),
    }
  };
});

function debounce(fn, ms) {
  let timer
  return () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
};

export const MapEditor = ({venueId, blobUrl, overlays, onOverlayAdded, onOverlayChanged, onOverlayDeleted}) => {
  const classes = useStyles();

  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false }
  }, []);

  // This is the natural size of the map image. 
  // In our SVG, this is the width and height of the viewBox (the viewBox left and top are zeros).
  const [layoutSize, setLayoutSize] = useState({
    height: 0,
    width: 0
  });

  // This is the div that contains the "map editor". We only use the width of this div.
  const [viewPort, setViewPort] = useState();

  // To convert from pixels in the viewport to the pixels in <svg> viewBox.
  // Example: if we want to see a circle of radius 5px on the screen,
  // then we need to draw a circle with radius 5*scaleFactor in the <svg>.
  const [scaleFactor, setScaleFactor] = useState(0);

  const [newOverlayCounter, setNewOverlayCounter] = useState(0);
  
  // If currentOverlayId is null, then no overlay is being created or edited.
  // If currentOverlayId is an empty string, then a new overlay is being created.
  // If currentOverlayId is a non-empty string, then an existing overlay is being edited.
  const [currentOverlayId, setCurrentOverlayId] = useState(null);

  const [locations, setLocations] = useState(null);
  const [selectLocationOpen, setSelectLocationOpen] = useState({
    open: false,
    onOk: () => {}
  });

  // Uncomment this effect for debuggin mounting/unmounting.
  // useEffect(() => {
  //   componentCounter++;
  //   const counter = componentCounter;
  //   console.log("MapEditor being mounted", componentCounter);
  //   return () => {
  //     console.log("MapEditor being unmounted", componentCounter);
  //   }
  // },[])

  // As layoutRef is used in another useEffect hook, we need to do this weird clean-up.
  // Otherwise, React will show a warning about memory leaks signalled by attempting to
  // updated an unmounted component.
  useEffect(() => {
    const loadData = async (venueId) => {
      getVenueLocations(venueId).then((response) => {
        if (isMounted.current) {
          setLocations(response.data);
        }
      });
    };
    loadData(venueId);
    return () => {
      //layoutRef.current = false;
    };
  }, [venueId]); 

  // Re-calculate scaleFactor when the viewport or the viewBox of the <svg> element changes.
  const recalculateScaleFactor = useCallback(() => {
    if (layoutSize.width > 0 && layoutSize.height > 0 && viewPort) {
      const vp = viewPort.getBoundingClientRect();
      if (vp.width > 0 && vp.height > 0) {
        const wAspect = layoutSize.width / vp.width;
        const hAspect = layoutSize.height / vp.height;
        if (wAspect < hAspect ) {
          setScaleFactor( hAspect );
        } else {
          setScaleFactor( wAspect );
        }
      }
    }
  }, [layoutSize, viewPort]);

  useEffect(() => {
    recalculateScaleFactor();
  }, [recalculateScaleFactor]);

  // The scaleFactor needs to be recalculated when the width of the layout <div>
  // changes. To know when this happens, we can listen to the resize
  // venue of the window. This is so clumsy, but it kind of works.
  // KF: The other option is to use the ResizeObserver, but I don't know how to
  // do that.
  useEffect(() => {
    const debouncedOnWindowResize = debounce(function handleResize() {
      recalculateScaleFactor();
    }, 500);

    window.addEventListener('resize', debouncedOnWindowResize)
    return () => {
      window.removeEventListener('resize', debouncedOnWindowResize)
    };
  }, [recalculateScaleFactor]);

  const onHiddenLayoutLoad = (e) => {
    const {naturalHeight, naturalWidth} = e.target;
    setLayoutSize({
      height: naturalHeight,
      width: naturalWidth
    });
  };

  // Called from PolygonCreator.
  // If the user clicked Esc during the creation of the polygin, 
  // then the markup paramter will be null.
  const onPolygonCreated = ({markup}) => {
    setSelectLocationOpen({
      open: true,
      onOk: (locationId) => {
        const overlayId = `new-${newOverlayCounter + 1}`;
        setNewOverlayCounter((prev) => prev + 1);
        setCurrentOverlayId(overlayId);
        onOverlayAdded({
          overlayId: overlayId,
          locationId: locationId,
          markup: markup
        });
        setSelectLocationOpen({
          open: false,
          onOk: () => {}
        });
      }
    });
  };

  const onPolygonCreationCancelled = () => {
    setCurrentOverlayId(null);
  };

  const onPolygonEdited = ({overlayId, markup}) => {
    const overlay = overlays.find((o) => o.id === overlayId);
    if (overlay) {
      onOverlayChanged({
        ...overlay,
        markup: markup
      });
    }
  };

  // Called from PolygonViewer, when it's time to show
  // the polygon as PolygonEditor.
  const onPolygonSelected = (overlayId) => {
    setCurrentOverlayId(overlayId);
  };

  const renderedOverlays = overlays.map((overlay) => {
    if (overlay.id !== currentOverlayId ) {
      return (
        <PolygonViewer
          key={overlay.id}
          layoutSize={layoutSize}
          scaleFactor={scaleFactor}
          overlayId={overlay.id}
          markup={overlay.markup}
          onPolygonSelected={onPolygonSelected}
        />
      );
    } else {
      return null;
    }
  });
  if (currentOverlayId === "") {
    renderedOverlays.push(
      <PolygonCreator
        key={currentOverlayId}
        layoutSize={layoutSize}
        scaleFactor={scaleFactor}
        onPolygonCreated={onPolygonCreated}
        onPolygonCreationCancelled={onPolygonCreationCancelled}
      />
    );
  } else if ( currentOverlayId !== null ) {
    // We are edting an overlay.
    const overlay = overlays.find( o => o.id === currentOverlayId );
    renderedOverlays.push(
      <PolygonEditor
        key={overlay.id}
        layoutSize={layoutSize}
        scaleFactor={scaleFactor}
        overlayId={overlay.id}
        markup={overlay.markup}
        onPolygonEdited={onPolygonEdited}
      />
    );
  }

  return (
    <div
      className={classes.root}
      >
      <Box
        visibility={blobUrl ? "visible" : "hidden"}>
        <Button
          variant="contained" 
          buttonType={ButtonType.Primary}
          startIcon={<AddIcon />}
          disabled={currentOverlayId===""}
          onClick={() => {
            setCurrentOverlayId("");
          }}>
          Draw new location
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          minHeight: 0,
          flexGrow: 1
        }}
      >
        <Box
          ref={setViewPort}
          sx={{flexGrow: 1}}
          className={classes.layoutdiv}>
          <img 
            src={blobUrl} 
            alt="Underlying map"
            className={classes.hiddenLayout} 
            onLoad={onHiddenLayoutLoad} />
          <svg 
            width="100%" 
            height="100%" 
            viewBox={`0 0 ${layoutSize.width} ${layoutSize.height}`}
            >
            <image 
              href={blobUrl} 
              x="0" 
              y="0"
              width={layoutSize.width}
              height={layoutSize.height}
              />
            <rect  // This rectangle catches mouse venue when the user clicks outside of any polygon.
              x="0"
              y="0"
              width={layoutSize.width}
              height={layoutSize.height}
              fill="transparent"
              onMouseDown={(e) => setCurrentOverlayId(null)}
            />
            {renderedOverlays}
          </svg>
        </Box>
        <Box>
          <Legend 
            overlays={overlays}
            currentOverlayId={currentOverlayId}
            rootLocation={locations && (locations.length > 0) ? locations[0] : null}
            onLocationChanged={(overlay, locationId) => {
              onOverlayChanged({
                id: overlay.id,
                locationId: locationId,
                markup: overlay.markup
              });            
            }}
            onOverlayDeleted={(overlayId) => {
              if (currentOverlayId === overlayId) {
                setCurrentOverlayId(null);
              }
              onOverlayDeleted({overlayId: overlayId});
            }}
            onOverlaySelected={onPolygonSelected}
          />
        </Box>
      </Box>
      <Modal
        open={selectLocationOpen.open}
      >
        <div>
          <SelectLocation
            rootLocation={locations && (locations.length > 0) ? locations[0] : null}
            markedLocationIds={overlays.map(o => o.locationId)}
            onOk={selectLocationOpen.onOk}
            onCancel={() => {
              setSelectLocationOpen({
                open: false,
                onOk: () => {}
              });
              onPolygonCreationCancelled();
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default MapEditor;
