import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  loadRoles,
  loadUsers,
  updateUser,     
} from "../../../_actions/user.management.actions";
import { getVenues } from "../../../_actions/venue.actions";
import { updateUserVenues } from "../../../_actions/venue.user.actions";
import {
  Box, TextField,Grid,Chip,Checkbox,ListItemText,  
  FormControl,
  InputLabel,
  Select,
  MenuItem,  
  FormHelperText,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col } from "react-bootstrap";
import LoadPleaseWait from "../../notification/LoadingPleaseWait/LoadingMessage"
import { Button, ButtonType } from "../../_common/htmlTags/Button";
import { ROLES } from "../../../_constants/user.permissions.constants";

const useStyles = makeStyles((theme) => ({
  backgroundColorDisable: { backgroundColor: theme.palette.color.primary.disabled,  borderRadius:'5px'  },
  root: {
    "& .MuiTextField-root": {
      'margin-bottom': theme.spacing(1),   
      'margin-top': theme.spacing(1),   
      
    },
  },
  margin: {
    margin: theme.spacing(1),
  },

  selectControl: {
    'margin-bottom': theme.spacing(2),   
    'margin-top': theme.spacing(2),       
  },
  
  buttons: {
    'margin-bottom': theme.spacing(1),   
    'margin-top': theme.spacing(1),   
  },

  multipleSelectionOptionChip: {
    borderRadius: "3px",
    backgroundColor: theme.palette.color.primary.background,
    marginRight: "5px",
  },
}));

const EditUser = ({ userId }) => {


  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const userList = useSelector((state) => state.userManagement.users);
  const roleList = useSelector((state) => state.userManagement.roles);
  const venueList = useSelector((state) => state.venueReducer.venues);
  
  const [venues, setVenues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [user, setUser] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    roleId: "",
    role: null,
    password: "",
    enabled: true,
    venue: []
  });
  const [venuesPermissionsError, setVenuesPermissionsError] = useState(false);

  const handleMultiSelectChange = (event) => {
    const { value } = event.target;
    let venue = [];
    let lastVenue = [];
    
    let venueSplit = value[value.length - 1].split("`");
    if (venueSplit.length > 1) {
      lastVenue = {
        id: venueSplit[0],
        name: venueSplit[1]
      }
    }

    venue = venues?.map(v => { return { ...v } });
    if (venue.find(v => v.id === lastVenue.id) !== undefined)
      venue = venue.filter(e => e.id !== lastVenue.id);
    else
      venue.push(lastVenue);

    setVenues(venue);
    setUser((prevUser) => ({
      ...prevUser,
      venue: venue,
    }));

    setVenuesPermissionsError(false);
  };



  useEffect(() => {
    setIsLoading(userList === undefined);
    if (userList?.length === 0) {
      dispatch(loadUsers()).catch((error) => {
        toast.error("Loading users failed" + error, { autoClose: false });
      });
    }
    
    if (venueList?.length === 0) {
      dispatch(getVenues(0)).catch((error) => {
        toast.error("Loading venue failed" + error, { autoClose: false });
      });
    }

    if (roleList.length === 0) {
      dispatch(loadRoles()).catch((error) => {
        toast.error("Loading roles failed" + error, { autoClose: false });
      });
    }

    if (userId !== undefined) {
      setUser(userList.find((u) => u.id === userId));
    }
  }, [userId, venueList, roleList, userList, dispatch]);


  useEffect(() => {
    if (user !== undefined) {
      setVenues(user?.venue);
    }

  }, [user]);

  
  function handleChange(e) {
    
    const { name, value } = e.target;
    if (name === "roleId") {
      const selectedRole = roleList.find(e => e.id === value);
      setUser((prevUser) => ({
        ...prevUser,
        [name]: value,
        role: selectedRole,
      }));
      
    }
    else {
      setUser((prevUser) => ({
        ...prevUser,
        [name]: value,
      }));
    }
  }

  const handleSave = (e) => {    
    e.preventDefault();
    setSaving(true);
       
    if (user.venue.length === 0 && user.role.name !== ROLES.ADMINISTRATOR) {
      toast.error("There is no venue selected for the user.")
      setSaving(false);
      return;
    }


    let venueUserDto = {
      id: user.id,
      username: user.email,
      venues: user.venue,
      roles: [user.role]
    }

    setVenuesPermissionsError(venueUserDto.venues.length === 0);
    
    //saving users 
    dispatch(updateUser(user))
      .then(() => {
        dispatch(updateUserVenues(venueUserDto))
          .finally(() => {          
            toast.success("User updated successfully.");
            history.push("/users");
          })
          .catch((error) => {
            setSaving(false);
            toast.error(error, { autoClose: false });
          });
      })
      .catch((error) => {
        setSaving(false);
        toast.error("Updating user failed " + error, { autoClose: false });
      });
  }

  return (
    <>
      
      <LoadPleaseWait show={isLoading} >
        <form className={classes.root} autoComplete="off" onSubmit={handleSave}>
          <>
            <Box >
              <Grid>
                <Row item md={12}>
                  <Col md={8}>
                    <div className={classes.formInput} >
                      <Row>
                        <Col>
                          <TextField
                            id="email"
                            label="Login / Email"
                            name="email"
                            value={user?.email}
                            disabled
                            className={classes.backgroundColorDisable}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        </Col>
                      </Row>
                
                      <Row>
                        <Col md>
                          <TextField
                            id="firstName"
                            label="First name"
                            name="firstName"
                            value={user?.firstName}
                            onChange={handleChange}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md>
                          <TextField
                            id="outlined-lastName"
                            label="Last name"
                            name="lastName"
                            value={user?.lastName}
                            onChange={handleChange}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md>
                          <TextField
                            id="outlined-phoneNumber"
                            label="Mobile number"
                            name="phoneNumber"
                            value={user?.phoneNumber}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormControl
                            variant="outlined"
                            className={classes.selectControl}
                            fullWidth
                            required
                          >
                            <InputLabel id="role-label">Role</InputLabel>
                            <Select
                              labelId="role-label"
                              id="role-select"
                              name="roleId"
                              value={user?.roleId}
                              onChange={handleChange}
                              label="Role"
                            >
                              {roleList
                                .sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase())
                                .map((e) => (
                                  <MenuItem key={e.id} value={e.id}>
                                    {e.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Col>
                      </Row>
                      
                      <Row style={ user?.role?.name === ROLES.ADMINISTRATOR ? { display: 'none'} : { display: 'block'}}>
                        <Col>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            required={user?.role?.name !== ROLES.ADMINISTRATOR}
                          >
                            <InputLabel id='assignedVenuesInputLabel' >Venues, assigned to user</InputLabel>
                            <Select
                              name="assignedVenue"
                              MenuProps={{
                                style: {
                                  maxHeight: "30%",
                                  width: 200,
                                },
                                anchorOrigin: {
                                  vertical: "bottom",
                                },
                                getContentAnchorEl: () => null,
                              }}
                              multiple
                              labelId='assignedVenue'
                              label='Venues, assigned to user'
                              value={venues}
                              onChange={(event) => handleMultiSelectChange(event)}
                              disabled={user?.role?.name === ROLES.ADMINISTRATOR}
                              className={user?.role?.name === ROLES.ADMINISTRATOR ? classes.backgroundColorDisable : ""}
                              renderValue={(selected) => (

                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {
                                    (selected)
                                    .sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase())
                                    .map((venue) =>
                                      <Chip key={venue.id} label={venue.name} className={classes.multipleSelectionOptionChip} />
                                    )}
                                </Box>
                              )}
                            >
                              {venueList
                                .filter(v => v.venueStatus.name.toLowerCase() === "active")
                                .sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase())
                                .map(venue => {
                                  return (
                                    <MenuItem key={venue.id} value={venue.id + "`" + venue.name}>
                                      <Checkbox
                              
                                        checked={venues.find(v => v.id === venue.id) !== undefined}
                                      />
                                      <ListItemText primary={venue.name} />
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            {
                               venuesPermissionsError && user?.role?.name !== ROLES.ADMINISTRATOR  ?
                                <FormHelperText style={{color: "#EE4040", fontWeight: "400", fontSize: "12px", }}>
                                  No changes has been made in venue permissions. Please add at least one new venue
                                </FormHelperText>
                                :
                                <></>
                            }
                          </FormControl>
                        </Col>
                      </Row>
          
                  
                      <Row>
                        <Col>
                            <Button
                            type="submit"
                            variant="contained"
                            disabled={saving}
                            onClick={(e) => { setVenuesPermissionsError(user.venue.length === 0) }}
                            >
                               {saving ? "SAVING..." : "SAVE"} 
                          </Button>
                          
                            <Button
                              variant="outlined"
                              disabled={saving}            
                              className={classes.margin}
                              buttonType={ButtonType.Secondary}
                              onClick={() => {
                                history.push("/users");
                              }}
                            >
                              CANCEL
                            </Button>
                         
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Grid>
            </Box>
          </>
        </form>
      </LoadPleaseWait>

    </>
  );
};

export default EditUser;
