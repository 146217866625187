import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { changePasswordAdmin, loadUsers } from "../../../_actions/user.management.actions";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography, TextField } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { checkPassword } from "../../../_helpers/regex";
import { Button, ButtonType } from "../../_common/htmlTags/Button";
import { useHistory } from "react-router";
import PasswordValidation from "../../user/userInfo/PasswordValidation";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      //width: "25ch",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  selectControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },
  buttons: {
    marginLeft: theme.spacing(1),
  },
}));

const ChangePassword = ({ userId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const userList = useSelector((state) => state.userManagement.users);
  const [userPassword, setUserPassword] = useState({
    userName: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    userId: userId
  })
  
 
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(true);
  
  useEffect(() => {
    if (userList?.length === 0) {
      dispatch(loadUsers()).catch((error) => {
        toast.error("Loading users failed" + error, { autoClose: false });
      });
    }
  }, [userList, dispatch]);


  useEffect(() => {
    if (userId !== undefined) {
      let user = userList.find((u) => u.id === userId);

      if (user !== undefined) {
        setUserPassword({
          userName: user.email,
          userId: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
        });
      }
    }
  }, [userId, userList]);


  function handleSave(e) {
    e.preventDefault();
    if (showPasswordError)
      return;
    
    if (!checkPassword(userPassword.password)) {
      toast.error("Password does not meet strength requirements. It must be at least 6 characters long with at least 1 upper case, 1 lower case, 1 digit, 1 special character and no leading or trailing spaces. ")
      return;
    }

    dispatch(changePasswordAdmin(userPassword))
      .then(() => {
        toast.success("User password updated successfully.", { autoClose: false });
      })
      .catch((error) => {
        toast.error("Updating user password failed. " + error, { autoClose: false });
      });
  }

  const handlePasswordChange = (e) => {
    // keep track of changed password locally as well  
    setShowPasswordError(e.target.value !== confirmPassword);
    setUserPassword({ ...userPassword, password: e.target.value });
  };

  const handleConfirmPasswordChange = (e) => {
    setUserPassword({ ...userPassword, confirmPassword: e.target.value });
    setConfirmPassword(e.target.value);
    setShowPasswordError(userPassword.password !== e.target.value);
  };

  return (
  
    <form className={classes.root} autoComplete="off" onSubmit={handleSave}>
         
      <Box >
        <Grid>
          <Typography variant="h4" className={classes.margin}>
            Change password for {userPassword.firstName} {userPassword.lastName}
          </Typography>
            
          <Row item md={12}>
            <Col md={5}>
              <div className={classes.formInput} >
                <Row>
                  <Col>
                    <TextField
                      id="new-password"
                      label="Password"
                      name="Password"
                      value={userPassword.password}
                      onChange={handlePasswordChange}
                      variant="outlined"
                      type="password"
                      required
                      fullWidth
                      error={userPassword.password && !isNewPasswordValid}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextField
                      id="confirm-password"
                      label="Confirm Password"
                      value={userPassword.confirmPassword}
                      error={showPasswordError}
                      helperText={
                        showPasswordError ? "Password does not match" : ""
                      }
                      onChange={handleConfirmPasswordChange}
                      variant="outlined"
                      type="password"
                      required
                      fullWidth
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className={classes.buttons}>
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        buttonType={ButtonType.primary}
                      >
                        SAVE
                      </Button>
                      <Button
                        className={classes.margin}
                        buttonType={ButtonType.Secondary}
                        onClick={() => {
                          history.push("/users");
                        }}
                      >
                        CANCEL
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={7} style={{ marginTop: '0.5em' }}>
              <PasswordValidation
                setIsNewPasswordValid={setIsNewPasswordValid}
                newPassword={userPassword.password}
              />
            </Col>
          </Row>
           
        </Grid>
      </Box>
    </form>
     
  );
};

export default ChangePassword;
