import React, {useState} from "react";
import {useSelector} from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { Button, ButtonType } from "../_common/htmlTags";
import { getFactorTypes, downloadImportSample, tryImport, confirmImport } from "../../_services/factors.service";
import {toast} from "react-toastify";
import {useApiGet} from "../../_helpers/useApiGet";
import {DataGrid} from "@material-ui/data-grid";
import {DataGridLoadingOverlay} from "../_common/DataGridLoadingOverlay";
import ExplicitGhostTextField from "../_common/ExplicitGhostTextField";
import { alpha } from '@material-ui/core';
import devconsole from "../_common/devconsole";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  locations: {
    backgroundColor: theme.palette.venueSettings.dataSources.locationsBackground,
  },
  title: {
    textTransform: "uppercase",
    fontWeight: "500",
    fontSize: "15px",
    padding: "15px 20px 5px 20px",
  },
  downloadLabel: {
    color: "#627E84",
    fontSize: "14px",   
  },
  downloadTemplateLink: {
    color: theme.palette.color.primary.main,
    fontSize: "14px",
    "&:hover" : {
      color: theme.palette.color.primary.main,
    }
  },
  hiddenUploadButton: {
    display: "none"
  },
  selectFactorTypeInvitation: {
    color: theme.palette.color.secondary.main
  },
  selectControl: {
    height: "41px"
  },
  errors:{
    maxHeight: "150px",
    minHeight: 0,
    overflowX: "hidden",
    overflowY: "auto",
    backgroundColor: alpha(theme.palette.color.primary.red, 0.05),
    color: theme.palette.color.primary.red,
    padding: "10px 20px"
  },
}));

const getFactorTypesCall = () => {
  return getFactorTypes();
}

export const FactorImport = ({venue}) => {
  const classes = useStyles();

  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);

  const [selectedFile, setSelectedFile] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const [records, setRecords] = useState([]);
  const [currentFactorTypeId, setCurrentFactorTypeId] = useState("-");
  const [applyNow, setApplyNow] = useState(true);
  const [applyFrom, setApplyFrom] = useState(null);
  const [selectedRecordIds, setSelectedRecordIds] = useState([]);

  const [{data: factorTypes}] = useApiGet(getFactorTypesCall, [], []);

  const handleTemplateClicked = (e) => {
    e.preventDefault();
    if (selectedVenue?.id) {
        downloadImportSample(selectedVenue.id)
            .then(() => toast.success("Downloaded template file."))
            .catch(error => toast.error("Failed to download template file. " + (error.response ? error.response.data : error.message), { autoClose: false }));            
    }
  };

  const handleFileSelected = (e) => {
    const file = e.target.files[0];
    devconsole.log("Selected File: ", file);
    if (typeof file === 'undefined') {
      // do nothing
    } else {
      setSelectedFile(file);
      setRecords([]);
      setErrors([]);
      setIsUploading(true);
      tryImport(venue.id, currentFactorTypeId, file)
        .then((response) => {
          setIsUploading(false);
          const recordsWithIds = response.data.map((r, i) => ({
            ...r,
            id: i
          }));

          setRecords(recordsWithIds);
        })
        .catch((error) => {
          setIsUploading(false);
          toast.error("Failed to upload data. " + error, { autoClose: false });
          if (error.response && Array.isArray(error.response.data)) {
            setErrors(error.response.data);
          } else if (error.response && error.response.data) {
            setErrors(new Array(error.response.data));
          } else if (error.message) {
            setErrors(new Array(error.message));
          }
          else {
            setErrors(["Unknown error"]);
          }
        });
    }
   
    e.target.value = null;
  };

  const handleFactorTypeChange = (e) => {
    // If the location type changed to a sensor, reset Factor to 1.
    const newFactorTypeId = e.target.value;
    setCurrentFactorTypeId(newFactorTypeId);
    setRecords([]);
    setErrors([]);
    setSelectedFile(null);
  };

  const handleApply = () => {
    const selectedRecords = records.filter(r => selectedRecordIds.includes(r.id));
    setIsSaving(true);
    confirmImport(venue.id, currentFactorTypeId, applyNow, applyFrom, venue.timeZoneId, selectedRecords)
      .then(() => {
        setIsSaving(false);
        toast.success("Factors applied successfully.");
      })
      .catch((error) => {
        setIsSaving(false);
        toast.error("Failed to apply factors. " + error, { autoClose: false });
      });
  };

  const disableColumnOrnaments = {
    disableColumnMenu: true,
    disableColumnFilter: true,
    disableSelectionOnClick: true,
    disableColumnSelector: true,
    disableDensitySelector: true,
    sortable: false
  }

  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "locationName",
      headerName: "LOCATION",
      width: 300,
      ...disableColumnOrnaments
    },
    {
      field: "currentData",
      headerName: "CURRENT DATA",
      width: 180,
      ...disableColumnOrnaments,
      type:"number",
      align:'center',
      headerAlign: 'center'
    },
    {
      field: "realData",
      headerName: "REAL DATA",
      width: 180,
      ...disableColumnOrnaments,
      type:"number",
      align:'center',
      headerAlign: 'center'
    },
    {
      field: "factorValue",
      headerName: "FACTOR",
      width: 180,
      ...disableColumnOrnaments,
      type:"number",
      align:'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <ExplicitGhostTextField
            value={params.row.factorValue.toLocaleString(undefined, { maximumFractionDigits: 4 })}
            onChange={(newFactorValue) => 
              setRecords((prev) => {
                return prev.map( record => {
                  if (record.id === params.row.id) {
                    return {
                      ...record,
                      factorValue: newFactorValue
                    };
                  } else {
                    return record;
                  }
                });
              })
            }
            marginTop="0"
            marginBottom="2px"
            height="31px"
          />
        );
      }
    }
  ];

  const disableUpload = currentFactorTypeId === '-' || (!applyNow && !applyFrom) || isUploading;
  const uploadButtonStyle = disableUpload? {pointerEvents: "none"} : {};

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        minHeight: 0
      }}>
      <Typography variant="h5">
        Import factors
      </Typography>
      <Box
        sx={{
          mt: 2,
          mb: 2
        }}
        >
        <span className={classes.downloadLabel}>Download template before import.&nbsp;</span>
          <Link 
            href="#" 
            onClick={handleTemplateClicked}
            className={classes.downloadTemplateLink}
          >
            Download here
          </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start"
        }}
      >
        <FormControl
          variant="outlined"
          style={{
            width: "400px",
            marginTop: "8px"
          }}
        >
          <InputLabel id="factorType-label">Choose metric</InputLabel>
          <Select
            labelId="factorType-label"
            id="factorType-select"
            name="factorTypeId"
            value={currentFactorTypeId}
            onChange={handleFactorTypeChange}
            label="Choose metric"
            className={classes.selectControl}
          >
            {[
              <MenuItem key={"-"} value={"-"}>
                <Typography className={classes.selectFactorTypeInvitation}>Choose metric</Typography>
              </MenuItem>,
              ...factorTypes.map((ft) => (
                <MenuItem key={ft.id} value={ft.id}>
                  <Typography>{ft.name}</Typography>
                </MenuItem>
              ))
            ]}
          </Select>
        </FormControl>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            mt: "16px"
          }}
        >
          <FormControlLabel
            label="Apply now"
            style={{
              marginRight: "80px",
              marginTop: "7px"
            }}
            control={
              <Checkbox
                  checked={applyNow}
                  onChange={(e) => {
                    setApplyNow(e.target.checked);  
                  }}
              />
            }
          />
          <TextField
            id="applyFrom"
            label="Apply from"
            type="datetime-local"
            name="applyFrom"
            value={applyFrom}
            disabled={applyNow}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            style= {{
              width: "220px",
            }}
            onChange={(e) => {
              const { value } = e.target;
              setApplyFrom(value);
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mt: "16px"
          }}
        >
          <input
            id="upload-file-button"
            type="file"
            name="file"
            onChange={handleFileSelected}
            accept=".xlsx,.xls"
            className={classes.hiddenUploadButton}
            style={uploadButtonStyle}
          />
          <label 
            htmlFor="upload-file-button"
            style={uploadButtonStyle}
          >
            <Button
              variant="contained"
              component="span"
              buttonType={ButtonType.Dark}
              disabled={disableUpload}
              >
              Import...
            </Button>
          </label>
          <label style={{marginLeft: "20px"}}>
            {selectedFile?.name}
          </label>
        </Box>
      </Box>
      <Typography
        className={classes.downloadLabel}
        style={{
          marginLeft: "0px"
        }}
      >
        You can upload any .xls, .xlsx file
      </Typography>
      {errors.length > 0 ? 
        <Box 
          className={classes.errors}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "890px",
            mt: "16px"
          }}>
          <ul
            style={{margin: 0}}>
            {errors.map(error => (<li>{error}</li>)) }
          </ul>
        </Box>
        : null
      }
      <DataGrid
        autoHeight={true}
        checkboxSelection
        disableSelectionOnClick
        pageSize={10}
        columns={columns}
        rows={records}
        components={{
          LoadingOverlay: DataGridLoadingOverlay,
        }}
        loading={isUploading}
        selectionModel={selectedRecordIds}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectedRecordIds(newSelectionModel);
        }}   
        style={{
          marginTop: "32px",
          width: "890px"
        }}
      />
      <Button
        variant="contained"
        type="submit"
        disabled={isSaving || selectedRecordIds.length === 0 || currentFactorTypeId === '-' || (!applyNow && !applyFrom)}
        buttonType={ButtonType.Primary}
        onClick={handleApply}
        style={{
          padding: "10px",
          marginRight: "10px",
        }}
      >
        Apply
      </Button>
    </Box>
  );
};

export default FactorImport;