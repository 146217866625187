import { tableauReportConstants } from "../_constants/tableauReport.constants";
import * as reportApi from "../_services/report.service";

export const publishTableauDataSuccess = (isPublished) => {
  return {
    type: tableauReportConstants.PUBLISH_TABLEAU_DATA,
    isPublished: isPublished,
  };
};

const getTableauPublishingModeStarted = () => {
  return {
    type: tableauReportConstants.GET_TABLEAU_PUBLISHING_MODE_STARTED,
  };
};

export const getTableauPublishingModeSuccess = (tableauPublishingMode) => {
  return {
    type: tableauReportConstants.GET_TABLEAU_PUBLISHING_MODE_SUCCESS,
    tableauPublishingMode: tableauPublishingMode,
  };
};


export const setTableauAutoPublishing = (venueId, isAuto) => {
  return async (dispatch) => {
    await reportApi
      .setTableauAutoPublishing(venueId, isAuto)
      .catch((error) => {
        throw error;
      });
  };
};

export const getTableauPublishingMode = (venueId) => {
  return async (dispatch) => {
    dispatch(getTableauPublishingModeStarted());
    await reportApi
      .getTableauPublishingMode(venueId)
      .then((response) => {
        dispatch(getTableauPublishingModeSuccess(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};