import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import { Card, CardContent } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import { Button } from "../_common/htmlTags/Button";
import { toast } from "react-toastify";
import Grid from "@material-ui/core/Grid";
import { getVenueLocations } from "../../_actions/venue.location.actions";
import { addOccupancyManualAdjustment, loadLatestOccupancies } from "../../_actions/indicators.actions";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router";
import { loadVendors } from "../../_actions/schedule.task.actions";
import Typography from "@material-ui/core/Typography";
import { loadLocationMapping } from "../../_actions/location.mapping.actions";
import { makeStyles } from "@material-ui/core/styles";
import RefreshIcon from '@material-ui/icons/Refresh';
import Link from '@material-ui/core/Link';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { AuthService } from "../../_services/auth.service";
import { ApiRootConstants } from "../../_constants/apiRoot.constants";
import { selectVendors } from "../../_reducers/selectors";
import LoadPleaseWait from "../notification/LoadingPleaseWait/LoadingMessage";
import devconsole from "../_common/devconsole";

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTableContainer-root": {
      overflow: "hidden"
    }
  },
  inputRoot: {
    '&$disabled': {
      color: 'black'
    },
  },
  disabled: {},
  noBorder: {
    border: "none",
  },
}));

// This function makes array of objects with children flat recursively.
function flattenList(array) {
  var flattened = [];
  (function flat(array) {
    array.forEach(function (el) {
      if (Array.isArray(el.children) && el.children) flat(el.children);
      flattened.push(el);
    });
  })(array);
  return flattened;
}

function getFormattedDateFromTimestamp(timestamp) {
  var date = new Date();
  date.setTime(timestamp);

  var dateTime = date.getUTCFullYear() + "-"
    + String(date.getUTCMonth() + 1).padStart(2, "0") + "-"
    + String(date.getUTCDate()).padStart(2, "0") + " "
    + String(date.getUTCHours()).padStart(2, "0") + ":"
    + String(date.getUTCMinutes()).padStart(2, "0") + ":"
    + String(date.getUTCSeconds()).padStart(2, "0");

  return dateTime;
}

function FlashText(id) {
  if (document.getElementById(id) !== undefined && document.getElementById(id) !== null) {
    document.getElementById(id).style.color = 'darkgreen';
    document.getElementById(id).style.fontWeight = '900';
    setInterval(function () {
      if (document.getElementById(id) !== undefined && document.getElementById(id) !== null) {
        document.getElementById(id).style.color = '';
        document.getElementById(id).style.fontWeight = '';
      }
    }, 2000);
  }
}
const authService = new AuthService();

async function getUser() {
  return authService.getUser();
}
export const OccupancyManualAdjustment = () => {
  const [user, setUser] = useState(null);
  const [refreshCnt, setRefreshCnt] = useState(0);
  const [isSilentRefresh, setIsSilentRefresh] = useState(false);
  const [locationsWithOccupancyList, setLocationsWithOccupancyList] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const [signalRConn, setSignalRConn] = useState(null);
  const [locationMappingLoaded, setLocationMappingLoaded] = useState(false);

  const selectedVenue = useSelector(
    (state) => state.venueReducer.selectedVenue
  );
  const venueLocations = useSelector(
    (state) => state.venueLocationReducer.venueLocations
  );
  const locationMappings = useSelector(
    (state) => state.locationMapping.locationMappings
  );
  const latestOccupancies = useSelector(
    (state) => state.indicators.occupancy.latestOccupancies
  );
  const vendors = useSelector(selectVendors);
  const latestOccupanciesLoading = useSelector(
    (state) => state.indicators.occupancy.loading.latestOccupanciesLoading
  );

  const [vendorId, setVendorId] = useState(null);
  function handleVendorChange(venue) {
    let oldVendorId = vendorId;
    setVendorId(venue.target.value);
    devconsole.log(`connectionid: ${signalRConn.connectionId}`);
   
    if (oldVendorId != null) {
   
      signalRConn.invoke("LeaveLatestOccupancyGroup", selectedVenue.id, oldVendorId).catch(function (err) {
        return console.error(err.toString());
      });
    }
    signalRConn.invoke("JoinLatestOccupancyGroup", selectedVenue.id, venue.target.value).catch(function (err) {
      return console.error(err.toString());
    });
  }
  
  function refreshLatestOccupancies() {
    if (selectedVenue && vendorId) {
      return dispatch(loadLatestOccupancies(selectedVenue.id, vendorId)).catch((error) => {
        toast.error("Loading latest occupancies failed. " + error, { autoClose: false });                
      });
    }
    return Promise.resolve();
  }

  useEffect(() => {
    refreshLatestOccupancies().then(() => setIsSilentRefresh(false));
  }, [refreshCnt, setRefreshCnt, setIsSilentRefresh]);

  function refreshLatestOccupanciesSilent() {
    setIsSilentRefresh(true);
    setRefreshCnt(cnt => cnt + 1);
  }

  useEffect(() => {
    if (selectedVenue?.id) {
      dispatch(loadLocationMapping(selectedVenue.id)).catch((error) => {
        toast.error("Loading location mappings failed. " + error, { autoClose: false });
      });
    }

    if (selectedVenue?.id && vendorId) {
      dispatch(loadLatestOccupancies(selectedVenue.id, vendorId)).catch((error) => {
        toast.error("Loading latest occupancies failed. " + error, { autoClose: false });
      });
    }
  }, [dispatch, selectedVenue?.id, vendorId])

  useEffect(() => {
    return history.listen((page) => {
      if (selectedVenue?.id && locationMappings.length === 0 && !locationMappingLoaded) {
        setLocationMappingLoaded(true);
        dispatch(loadLocationMapping(selectedVenue.id)).catch((error) => {
          toast.error("Loading location mappings failed. " + error, { autoClose: false });
        });
      }

      if (selectedVenue?.id && vendorId) {
        dispatch(loadLatestOccupancies(selectedVenue.id, vendorId)).catch((error) => {
          toast.error("Loading latest occupancies failed. " + error, { autoClose: false });
        });
      }
    })
  }, [dispatch, history, selectedVenue?.id, vendorId, locationMappingLoaded, setLocationMappingLoaded])

  useEffect(() => {
    if (selectedVenue?.id) {
      dispatch(getVenueLocations(selectedVenue.id));
      getUser().then(a => setUser(a));
    }
  }, [dispatch, selectedVenue?.id]);

  useEffect(() => {
    if (user != null) {
      //console.log(`access token: ${user.access_token}`);
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${ApiRootConstants.OccupancyIndicatorApiRoot}Hubs/LatestOccupancy`, {
          accessTokenFactory: async () => {
            let currUser = await getUser();
            //console.log(`Access Token Factory user token: ${ currUser.access_token }`);
            return currUser.access_token;
          }
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();
      newConnection.onreconnecting(error => {
        devconsole.log("reconnecting...");
      });
      newConnection.onclose(error => {
        devconsole.log("connection closed");
      });
      setSignalRConn(newConnection);
    }
  }, [user]);

  useEffect(() => {
    if (signalRConn !== null) {
      if (signalRConn.connectionId === null) {
        signalRConn.start()
          .then(function () {
            devconsole.log("Signalr Connected successfully!");
            
            signalRConn.on('UpdateLatestOccupancy', refreshLatestOccupanciesSilent);
          })
          .catch(function (error, status, code) {
            console.log('Connection to signalr failed: ', {error, status, code});
          });
      }
    }
    return function cleanup() {
      devconsole.log("Unmount component!");
      if (signalRConn != null) {
        signalRConn.off("UpdateLatestOccupancy");
        devconsole.log(`stop connection!`);
        //console.log(`stop connection with id: ${signalRConn.connectionId}!`);
        signalRConn.stop();
      }
    }
  }, [signalRConn]);


  useEffect(() => {
    if (vendors.length === 0) {
      dispatch(loadVendors()).catch((error) => {
        toast.error("Loading data sources failed. " + error, { autoClose: false });
      });
    }

    if (selectedVenue && locationMappings.length === 0 && !locationMappingLoaded) {
      setLocationMappingLoaded(true);
      dispatch(loadLocationMapping(selectedVenue.id)).catch((error) => {
        toast.error("Loading location mappings failed. " + error, { autoClose: false });
      });
    }

    if (selectedVenue && vendorId) {
      dispatch(loadLatestOccupancies(selectedVenue.id, vendorId)).catch((error) => {
        toast.error("Loading latest occupancies failed. " + error, { autoClose: false });
      });
    }
  }, [dispatch, vendorId, locationMappings, selectedVenue, vendors.length, locationMappingLoaded, setLocationMappingLoaded]);

  useEffect(() => {

    if (latestOccupancies === undefined || latestOccupancies === null)
      return;

    //All locations - parents and their children.
    const locationsFlatList = flattenList(venueLocations).sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }));
    const locationMappingsIds = locationMappings.filter(e => e.vendorId === vendorId).map(e => e.locationId);
    const list = locationsFlatList.filter(location => {
      return locationMappingsIds.includes(location.id);
    })
      .map(location => {
        var latestOccupancy = latestOccupancies?.find(latestOccupancy => latestOccupancy.locationId === location.id);
        var occupancy = latestOccupancy?.occupancyNumber ?? 0;
        var rawOccupancyNumber = latestOccupancy?.rawOccupancyNumber ?? 0;
  
        var locationMapping = locationMappings.find(locationMapping => locationMapping.vendorId === vendorId && locationMapping.locationId === location.id);
        var locationVendorCode = locationMapping.vendorCode;
  
        return { ...location, occupancy, rawOccupancyNumber, locationVendorCode };
      });
      setLocationsWithOccupancyList(list);
  }, [venueLocations, locationMappings, vendorId, latestOccupancies, setLocationsWithOccupancyList]);

  function handleSubmit(venue) {
    venue.preventDefault();

    if (!venue.target.adjustmentValue.value
      || (+venue.target.adjustmentValue.value === 0
        && document.getElementById(venue.target.locationId.value + "_rawOccupancyNumber").innerHTML !== document.getElementById(venue.target.locationId.value + "_newOccupancy").value
      )
    ) {
      toast.error("Please select adjustment value");
      history.push("/adjustments");
      return;
    }

    if (
      !(venue.target.adjustmentFinishMinutes.value && venue.target.adjustmentFinishHours.value)
      || (+venue.target.adjustmentFinishMinutes.value === 0 && +venue.target.adjustmentFinishHours.value === 0)
    ) {
      toast.error("Please select adjustment finish time");
      history.push("/adjustments");
      return;
    }

    if (!vendorId) {
      toast.error("Please select data source");
      history.push("/adjustments");
      return;
    }

    var userHours = parseInt(venue.target.adjustmentFinishHours.value);
    var userMinutes = parseInt(venue.target.adjustmentFinishMinutes.value);

    var allUserMinutes = userHours * 60 + userMinutes;
    var allUserMilliseconds = allUserMinutes * 60000;

    var now = new Date();
    var occupancyManualAdjustment = {
      "locationId": venue.target.locationId.value,
      "venueId": selectedVenue.id,
      "vendorId": vendorId,
      "adjustmentDate": getFormattedDateFromTimestamp(now.getTime()),
      "adjustmentFinalDate": getFormattedDateFromTimestamp(new Date(now.getTime() + allUserMilliseconds)),
      "adjustmentValue": venue.target.adjustmentValue.value,
      "locationVendorCode": venue.target.locationVendorCode.value
    };

    dispatch(addOccupancyManualAdjustment(occupancyManualAdjustment))
      .then((response) => {
        toast.success("Adding occupancy manual adjustment successful.");
        history.push("/adjustments");
      })
      .catch((error) => {
        toast.error("Adding occupancy manual adjustment failed. " + error, { autoClose: false });
        history.push("/adjustments");
      });

    // dispatch(loadLatestOccupancies(selectedVenue.id, vendorId)).catch((error) => {
    //   toast.error("Loading location mappings failed. " + error, { autoClose: false });
    // });

    document.getElementById(venue.target.locationId.value + "_increase_decrease").value = 0;
    document.getElementById(venue.target.locationId.value + "_newOccupancy").value = 0;
    document.getElementById(venue.target.locationId.value + "_displayedHours").value = 0;
    document.getElementById(venue.target.locationId.value + "_displayedMinutes").value = 0;

    document.getElementById(venue.target.locationId.value + "_adjustmentValue").value = 0;
    document.getElementById(venue.target.locationId.value + "_adjustmentFinishHours").value = 0;
    document.getElementById(venue.target.locationId.value + "_adjustmentFinishMinutes").value = 0;
  }

  return (
    <div className={classes.root}>
      {selectedVenue != null ? (
        <div style={{ height: '100%', width: '100%', overflow: 'hidden', margin: 0, scrolling: "auto" }}>
          <Typography variant="h4">Occupancy manual adjustment</Typography>
          <Typography variant="h6">Make adjustments for occupancies for selected venue, data source and location </Typography><br />

          <Card style={{ height: "80vh", overflow: 'auto'}}>
            <CardContent>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="vendor-label">Data Source</InputLabel>
            <Select
              labelId="vendor-label"
              value={vendorId || ""}
              id="vendorId"
              name="vendorId"
              onChange={handleVendorChange}
              label="Data Source"
              required
            >
              {vendors.filter(e => e.canAdjustOccupancy).map((e) => (
                <MenuItem key={e.id} value={e.id}>
                  {e.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl><br /><br />
          <i style={{ backgroundColor: 'white', opacity: 0.7 }}>Note: Manual adjustments are available only for data source(s) that supply occupancy data to dashboard via PMY Platform</i><br /><br />
          {vendorId ? (
            <>
             <LoadPleaseWait show={latestOccupanciesLoading && !isSilentRefresh} >
                <>
                  <div>Last Updated at: <b id="lastUpdated">{(new Date().toLocaleString())}</b></div>
                  <TableContainer >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell><b>Location Name</b></StyledTableCell>
                          <StyledTableCell><b>Raw Occupancy</b></StyledTableCell>
                          <StyledTableCell>
                            <div style={{ verticalAlign: "center" }}>
                              <div style={{ display: "inline" }}><b>Adjusted Occupancy</b></div>
                              <div style={{ display: "inline" }}>
                                <Link component="button" onClick={refreshLatestOccupancies}>
                                  <RefreshIcon />
                                </Link>
                              </div>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell><b>Increase/decrease value</b></StyledTableCell>
                          <StyledTableCell><b>New Occupancy</b></StyledTableCell>
                          <StyledTableCell><b>Adjustment finishes in</b></StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {locationsWithOccupancyList.map((row) => (
                          <TableRow key={row.name}>

                            <StyledTableCell component="th" scope="row">
                              {row.name}
                            </StyledTableCell>

                            <StyledTableCell id={row.id + "_rawOccupancyNumber"} component="th" scope="row">
                              {row.rawOccupancyNumber}
                            </StyledTableCell>

                            <StyledTableCell id={row.id + "_OccupancyNumber"} component="th" scope="row">
                              {row.occupancy}
                            </StyledTableCell>

                            <StyledTableCell component="th" scope="row">
                              <TextField id={row.id + "_increase_decrease"} type="number" variant="outlined" onChange={(venue) => {
                                if (venue.target.value)
                                  venue.target.value = parseInt(venue.target.value) >= -parseInt(row.occupancy) ? venue.target.value : -parseInt(row.occupancy);

                                var occupancyNumber = 0;
                                if (document.getElementById(row.id + "_OccupancyNumber") === null)
                                  occupancyNumber = row.occupancy;
                                else
                                  occupancyNumber = parseInt(document.getElementById(row.id + "_OccupancyNumber").innerHTML);

                                var rawOccupancyNumber = 0;
                                if (document.getElementById(row.id + "_rawOccupancyNumber") === null)
                                  rawOccupancyNumber = row.rawOccupancyNumber;
                                else
                                  rawOccupancyNumber = parseInt(document.getElementById(row.id + "_rawOccupancyNumber").innerHTML);

                                var newOccupancy = venue.target.value ? occupancyNumber + parseInt(venue.target.value) : 0;
                                document.getElementById(row.id + "_adjustmentValue").value = newOccupancy - rawOccupancyNumber;
                                document.getElementById(row.id + "_newOccupancy").value = newOccupancy >= 0 ? newOccupancy : 0;
                              }}
                                InputProps={{
                                  inputProps: {
                                    min: - parseInt(row.occupancy)
                                  }
                                }}
                              />
                            </StyledTableCell>

                            <StyledTableCell component="th" scope="row">
                              <TextField id={row.id + "_newOccupancy"}
                                disabled
                                variant="outlined"
                                value={
                                  document.getElementById(row.id + "_increase_decrease") && document.getElementById(row.id + "_increase_decrease").value ?
                                    parseInt(row.occupancy) + parseInt(document.getElementById(row.id + "_increase_decrease").value)
                                    : parseInt(row.occupancy)
                                }
                                InputProps={{
                                  classes: {
                                    root: classes.inputRoot,
                                    disabled: classes.disabled,
                                    notchedOutline: classes.noBorder
                                  }
                                }}
                              >
                              </TextField>
                            </StyledTableCell>

                            <StyledTableCell component="th" scope="row">
                              <Grid container spacing={2}>
                                <Grid item>
                                  <TextField id={row.id + "_displayedHours"} type="number" variant="outlined" label="Hours" defaultValue={0}
                                    onChange={(venue) => {
                                      if (venue.target.value) {
                                        venue.target.value = parseInt(venue.target.value) >= 0 ? parseInt(venue.target.value) : 0;
                                        venue.target.value = parseInt(venue.target.value) <= 24 ? parseInt(venue.target.value) : 24;
                                      }

                                      document.getElementById(row.id + "_adjustmentFinishHours").value = venue.target.value ? venue.target.value : 0;
                                    }}
                                    InputProps={{
                                      inputProps: {
                                        min: 0,
                                        max: 24
                                      }
                                    }}
                                  />
                                </Grid>
                                <Grid item>
                                  <TextField id={row.id + "_displayedMinutes"} type="number" variant="outlined" label="Minutes" defaultValue={0}
                                    onChange={(venue) => {
                                      if (venue.target.value) {
                                        venue.target.value = parseInt(venue.target.value) >= 0 ? parseInt(venue.target.value) : 0;
                                        venue.target.value = parseInt(venue.target.value) <= 59 ? parseInt(venue.target.value) : 59;
                                      }

                                      document.getElementById(row.id + "_adjustmentFinishMinutes").value = venue.target.value ? venue.target.value : 0;
                                    }}
                                    InputProps={{
                                      inputProps: {
                                        min: 0,
                                        max: 59
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              <form onSubmit={handleSubmit}>
                                <Grid container spacing={6}>
                                  <Grid item>
                                    <TextField type="number" id={row.id + "_adjustmentValue"} name="adjustmentValue" variant="outlined" defaultValue={0} hidden />
                                    <TextField type="number" id={row.id + "_adjustmentFinishHours"} name="adjustmentFinishHours" variant="outlined" defaultValue={0} hidden />
                                    <TextField type="number" id={row.id + "_adjustmentFinishMinutes"} name="adjustmentFinishMinutes" variant="outlined" defaultValue={0} hidden />
                                    <TextField name="locationId" hidden value={row.id} />
                                    <TextField name="locationVendorCode" hidden value={row.locationVendorCode} />
                                  </Grid>

                                  <Grid item>
                                    <Button
                                      variant="contained"
                                      type="submit"
                                    >
                                      Submit
                                    </Button>
                                  </Grid>
                                </Grid>
                              </form>
                            </StyledTableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
            </LoadPleaseWait>
            </>
          ) : (
            <>
              <Typography variant="body1" color="textSecondary">
                Select data source first
              </Typography>
            </>
            )}
            </CardContent></Card>
        </div>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
    </div>
    
  );
}

export default OccupancyManualAdjustment;