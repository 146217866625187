import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
    Grid,
    Typography,
    RadioGroup,
    Radio, Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { publishReportData, setReportAutoPublishing, getReportPublishingMode } from "../../../../_actions/report.action";
import { setTableauAutoPublishing, getTableauPublishingMode } from "../../../../_actions/tableauReport.action";
import { Button, ButtonType } from "../../../_common/htmlTags/Button";

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(2),
        fontWeight: "500"
    },
    publishButton: {
        marginBottom: theme.spacing(1),
    },
    publishJournalButton: {
        marginTop: theme.spacing(2),
    },
    atMidnightEveryDay: {
        color: theme.palette.color.secondary.main,
    }
}));

const PUBLISHING_MODES = {
    MANUAL: 1,
    AUTO: 2
}

const ReportsDataPublishing = ({ selectedVenue, error }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const reportPublishingMode = useSelector((state) => state.reporting.reportPublishingMode);
    const tableauPublishingMode = useSelector((state) => state.tableauReporting.tableauPublishingMode);
    const notifications = useSelector((state) => state.notifications);

    const [publishing, setPublishing] = useState(false);
    const [publishId, setPublishId] = useState(null);

    useEffect(() => {
        dispatch(getReportPublishingMode(selectedVenue.id));
        dispatch(getTableauPublishingMode(selectedVenue.id));
    }, [dispatch, selectedVenue.id]);

    useEffect(() => {
        if (notifications && publishId && notifications[publishId]?.type !== 'processing') {
            setPublishing(false);
        }
    }, [notifications, publishId, setPublishing]);

    const handleSelectMode = (e) => {
        let isAuto = parseInt(e.target.value) === parseInt(PUBLISHING_MODES.AUTO);
        dispatch(setReportAutoPublishing(selectedVenue.id, isAuto)).then(() => {
            dispatch(getReportPublishingMode(selectedVenue.id));
        })
            .catch((err) => {
                toast.error("Setting Publish data for reports failed. " + err, {
                    autoClose: false,
                });
            });;
    }

    const handleTableauAutoPublish = (e) => {
        let isAuto = e.target.checked;
        dispatch(setTableauAutoPublishing(selectedVenue.id, isAuto)).then(() => {
            dispatch(getTableauPublishingMode(selectedVenue.id));
        })
          .catch((err) => {
              toast.error("Setting Publish data for reports failed. " + err, {
                  autoClose: false,
              });
          });;
    }

    const handlePublish = (venue) => {
        venue.preventDefault();
        setPublishing(true);
        setPublishId(null);

        publishReportData(selectedVenue.id)
            .then((publishResult) => {
                if (error) {
                    toast.error(`Publish data for reports failed. ${error}`, { autoClose: false });
                    setPublishing(false);
                }
                setPublishId(publishResult);
            })
            .catch(err => {
                toast.error(`Publish data for reports failed. ${err}`, { autoClose: false });
                setPublishing(false);
            });
    }

    return (
        <Grid container>
            <form className={classes.container} onSubmit={handlePublish}>
                <Typography variant="h6" className={classes.title}>
                    Publish data for reports
                </Typography>
                <RadioGroup color="primary" aria-label="publishingMode" name="publishingMode" value={reportPublishingMode ?? PUBLISHING_MODES.MANUAL} onChange={handleSelectMode}>
                    <Grid item container spacing={3}>
                        <Grid item xs={6}>
                            <FormControlLabel color="primary" value={PUBLISHING_MODES.MANUAL} control={<Radio color="primary" />} label="Manually" />
                        </Grid>

                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                className={classes.publishButton}
                                disabled={publishing || parseInt(reportPublishingMode) !== PUBLISHING_MODES.MANUAL}
                                type="submit"
                            >
                                {publishing ? "Publishing..." : "Publish"}
                            </Button>
                        </Grid>
                    </Grid>

                    <FormControlLabel 
                        color="primary" 
                        value={PUBLISHING_MODES.AUTO} 
                        control={<Radio color="primary" />} 
                        label={
                            <div>
                                <Typography
                                    display="inline"
                                >
                                    Automatically&nbsp;
                                </Typography>
                                <Typography
                                    display="inline"
                                    className={classes.atMidnightEveryDay}
                                >
                                    (every 1 hour)
                                </Typography>
                            </div>
                        } 
                    />
                </RadioGroup>

                <Grid item xs={12}>
                    <Button
                        component={Link}
                        buttonType={ButtonType.Dark}
                        className={classes.publishJournalButton}
                        variant="contained"
                        to="/reports/PublishJournal"
                    >
                        Publish Journal
                    </Button>
                </Grid>
                <hr />
                <Typography variant="h6" className={classes.title}>
                    Publish data for Tableau reports
                </Typography>
                <FormControlLabel
                  control={<Checkbox checked={tableauPublishingMode ?? false} onChange={handleTableauAutoPublish} name="tableauAutoPublish" />}
                  label="Auto Publish Tableau (every 1 hour)"
                />
            </form>
        </Grid>
    );
}

export default ReportsDataPublishing;