import {Box, makeStyles} from "@material-ui/core";
import {Typography, TextField} from "@material-ui/core";
import {Col, Container, Row} from "react-bootstrap";
import React, {useState} from "react";
import {Button, ButtonType} from "../../../_common/htmlTags";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    backgroundColor: "#FFFFFF",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: theme.spacing(1),
    borderRadius: "5px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "500",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }
}));

export const EditEvent = ({ event, onCancel, onSave }) => {
  const classes = useStyles();

  const [currentEvent, setCurrentEvent] = useState(event);

  return (
    <Box
      sx={{
        width: "800px",
        display: "flex",
        flexDirection: "column",
      }}
      className={classes.mainBox}
    >
      <Container fluid>
        <Row>
          <Col md={12}>
            <Typography className={classes.title}>Event properties</Typography>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className={classes.formInput}>
            <TextField id="eventName" label="Name" name="eventName"           
                  placeholder={"ie. My Event"} value={currentEvent.eventName}
                  defaultValue={""} variant="outlined" required fullWidth
                  onChange={(e) => setCurrentEvent({ ...currentEvent, eventName: e.target.value })}/>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className={classes.formInput}>
            <TextField id="eventDescription" label="Description" name="eventDescription" multiline rows={5}
                        placeholder={"Describe the event"} value={currentEvent.eventDescription} 
                        defaultValue={""} variant="outlined" required fullWidth 
                        onChange={(e) => setCurrentEvent({ ...currentEvent, eventDescription: e.target.value })}/>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className={classes.formInput}>
              <TextField id="startDate" label="Start date" name="startDate" type="datetime-local"
                          placeholder={"Start date"} value={currentEvent.startDate}
                          defaultValue={currentEvent.startDate} variant="outlined" required fullWidth
                          onChange={(e) => setCurrentEvent({ ...currentEvent, startDate: e.target.value })}/>
            </div>
          </Col>
          <Col md={6}>
            <div className={classes.formInput}>
              <TextField id="endDate" label="End date" name="endDate" type="datetime-local"
                          placeholder={"End date"}
                          defaultValue={currentEvent.endDate} variant="outlined" required fullWidth
                          onChange={(e) => setCurrentEvent({ ...currentEvent, endDate: e.target.value })}/>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className={classes.formInput}>
              <TextField id="businessHoursFrom" label="Business hours start" name="businessHoursFrom" type="time"
                          value={currentEvent.businessHoursFrom}
                          defaultValue={"00:00"} variant="outlined" required fullWidth
                          onChange={(e) => setCurrentEvent({ ...currentEvent, businessHoursFrom: e.target.value })}/>
            </div>
          </Col>
          <Col md={6}>
            <div className={classes.formInput}>
              <TextField id="businessHoursTo" label="Business hours end" name="businessHoursTo" type="time"
                          value={currentEvent.businessHoursTo}
                          defaultValue={"00:00"} variant="outlined" required fullWidth
                          onChange={(e) => setCurrentEvent({ ...currentEvent, businessHoursTo: e.target.value })}/>
            </div>
          </Col>
        </Row>
      </Container>
        
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          
        }}
      >
        <Button
          variant="contained"
          type="submit"
          buttonType={ButtonType.Primary}
          style={{
            padding: "10px 24px",
            margin: "16px 8px 16px 16px"
          }}
          onClick={() => {onSave(currentEvent);}}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          type="button"
          buttonType={ButtonType.Secondary}
          onClick={() => {
            onCancel(event);
          }}
          style={{
            padding: "10px 24px",
            margin: "16px"
          }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  )
};

export default EditEvent;