import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  InputAdornment,
  IconButton,
  Radio,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { toast } from "react-toastify";
import LoadPleaseWait from "../../../notification/LoadingPleaseWait/LoadingMessage"
import {useApiGet} from "../../../../_helpers/useApiGet";
import {getIndicatorVendorMappings} from "../../../../_services/scheduledTasks.service";
import {getLocationMappingsByLocationId, updateVendorLocationMappings} from "../../../../_services/location.mapping.service";

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: "uppercase",
    fontWeight: "500",
    fontSize: "15px",
    padding: "15px 20px 10px 20px",
  },
  vendorHeader: {
    height: "90px"
  },
  oddVendorBackground: {
    backgroundColor: theme.palette.venueSettings.dataSources.oddVendorBackground
  },
  indicatorsLabel: {
    fontWeight: 500,
    fontSize: 15,
    textTransform: "uppercase",
    marginBottom: "12px"
  },
  indicatorHeader: {
    height: "50px",
  },
  occupancyIndicatorHeader: {
    height: "100px",
    paddingTop: "16px",
  },
  trafficInOutIndicatorHeader: {
    height: "155px",
    paddingTop: "16px",
  },
  queueModelIndicatorHeader: {
    height: "210px",
    paddingTop: "16px"
  },
  oddIndicatorBackground: {
    background: theme.palette.venueSettings.dataSources.locationsBackground
  },
  vendorLabel: {
    fontWeight: 500,
    fontSize: 15
  },
  vendorLocationIndicatorMapping: {
    height: "50px",
    width: "100%",
  },
  radio: {
    color: theme.palette.venueSettings.dataSources.radioButton,
    '&$checked': {
      color: theme.palette.venueSettings.dataSources.radioButton,
    }
  },
  radioChecked: {    
  },
  vendorCodeOk: {
    color: theme.palette.color.success.main,
    width: "30px",
    height: "100%",
    marginRight: "0px"
  },
  vendorCodeCancel: {
    color: theme.palette.color.danger.main,
    width: "30px",
    height: "100%"
  },
}));

// This method has l10n issue. It only works with full stop as the decimal point.
function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

const getIndicatorVendorMappingsCall = async (venueId) => {
  if (venueId) {
    const result = await getIndicatorVendorMappings();
    return result;
  } else {
    return [];
  }
};

const getLocationMappingsByLocationIdCall = async ({venueId, locationId}) => {
  if (venueId && locationId) {
    const result = await getLocationMappingsByLocationId(venueId, locationId);
    const vendorLocationMappings = result.data;
    return vendorLocationMappings;
  } else {
    return [];
  }
};

export const Matrix = ({venue, location}) => {
  const classes = useStyles();

  // The "data" here is the array of indicator-vendor mappings as they are loaded from the database.
  const [{data: indicatorVendorMappings, isLoading : isIndicatorLoading}] = useApiGet(getIndicatorVendorMappingsCall, venue.id, []);

  const [indicators, setIndicators] = useState([]);
  // The radio buttons for Occupancy indicator should be disabled
  // if the selected locaton is sensor. Also, when the radio 
  // buttons are checked automatically, the occupancy indiator
  // should be handled differently for sensors.
  // Identifying the Occupancy indicator by its name is not the best
  // solution. It's better to do it on the backend similarly to how
  // isSensor locations are implemented, but it seems like an 
  // over-kill in the current situation.
  const occupancyIndicatorId = indicators.find(indicator => indicator.name === "Occupancy")?.id;
  const [vendors, setVendors] = useState([]);

  // The matrix UI does not have a Save button, and the changes are 
  // persisted into the database immediately after the user
  // ticked a radio button or clicked the green tick button
  // on one of the location ID fields.
  // While the changes are being persisted into the database,
  // it is possible for the user to do more changes on the UI.
  // We can, theoretically, save these following changes too, but
  // this may lead to problems if the first change 
  // fails and does not get saved to the database.
  const [isSaving, setIsSaving] = useState(false);

  useEffect(()=> {
    if (indicatorVendorMappings) {
      const newIndicators = [];
      indicatorVendorMappings.forEach((m) => {
        if (!newIndicators.find((ni) => ni.id === m.indicatorId)) {
          newIndicators.push({
            id: m.indicatorId, 
            name: m.indicatorName,
            orderNum: m.indicatorOrderNum
          });
        }
      });
      setIndicators(newIndicators.sort((a, b) => a.indicatorOrderNum - b.indicatorOrderNum));

      const newVendors = [];
      indicatorVendorMappings.forEach((m) => {
        if (!newVendors.find((nv) => nv.id === m.vendorId)) {
          newVendors.push({
            id: m.vendorId, 
            name: m.vendorName
          });
        }
      });
      setVendors(newVendors.sort((a,b) => a.name.localeCompare(b.name)));
    }
  },[indicatorVendorMappings]);

  // The "data" here is the array of vendor-location mappings loaded from the database, before any editing.
  const [{
    data: initialVendorLocationMappings, 
    isLoading: isMappingLoading
  }, 
    setParamsForGetLocationMappingByLocationId
  ] = useApiGet(getLocationMappingsByLocationIdCall, {venueId: venue.id, locationId: location.locationId}, []);

  const [vendorLocationMappings, setVendorLocationMappings] = useState([]);

  useEffect(() => {
    // If vendor location mapping object does not exist in the database for a particular vendor,
    // create a mapping object for this vendor. The database should only contain the mappings
    // that have the location ID (vendor code) or at least one of the indicator mappings.
    // When saving the vendor location mappings ot the database, only those will be saved that
    // are not "empty". No need to inforce this non-"empty" rule on the UI.
    const placeholderMappings = [];
    vendors.forEach(v => {
      if (!initialVendorLocationMappings.find(m => m.vendorId === v.id)) {
        placeholderMappings.push({
          venueId: venue.id,
          vendorId: v.id,
          locationId: location.locationId,
          vendorLocationIndicatorMappings: []
        });
      }
    });
    setVendorLocationMappings([...initialVendorLocationMappings, ...placeholderMappings]);
    setCachedVendorCodes([]);
  }, [initialVendorLocationMappings, vendors, location, venue]);

  useEffect(() => {
    setParamsForGetLocationMappingByLocationId({venueId: venue.id, locationId: location.locationId});
  }, [venue, location.locationId, setParamsForGetLocationMappingByLocationId]);

  // This is the array of vendorCodes that have been edited but not saved or reverted yet.
  // Each element in this array is an object with 2 properties: vendorId and vendorCode.
  const [cachedVendorCodes, setCachedVendorCodes] = useState([]);

  const handleVendorCodeChanged = (vendorId, vendorCode) => {
    setCachedVendorCodes((prev) => {
      const result = [
        ...prev.filter((vc) => vc.vendorId !== vendorId),
        {
          vendorId: vendorId,
          vendorCode: vendorCode
        }
      ];
      return result;
    });
  };

  const handleVendorCodeSave = async (vendorId) => {
    if (isSaving) {
      return;
    }

    // The vendor code (location ID) that we are trying to save.
    const cachedVendorCode = cachedVendorCodes.find(vc => vc.vendorId === vendorId);
    if (!cachedVendorCode) {
      return; // KF: We should not reach this line...
    }
    // Trim the vendor code.
    const newVendorCode = cachedVendorCode.vendorCode ? cachedVendorCode.vendorCode.trim() : cachedVendorCode.vendorCode;
    
    // We have location vendor mappings for each vendor, for the current location.
    const vendorLocationMapping = vendorLocationMappings.find(vlm => vlm.venueId === venue.id && vlm.vendorId === vendorId && vlm.locationId === location.locationId);
    const oldVendorCode = vendorLocationMapping.vendorCode;

    const vendorCodeUpdate = {
      vendorId: vendorId,
      oldVendorCode: oldVendorCode,
      newVendorCode: newVendorCode
    };

    const indicatorMappingUpdates = [];

    if (oldVendorCode && !newVendorCode) {
      // If location ID is being cleared, remove the indicator mappings for this vendor code.
      // If the mapping was removed for a particular indicator, map this indicator
      // to the first suitable vendor with location ID specified.
      const indicatorIds = vendorLocationMapping.vendorLocationIndicatorMappings.map(vlim => {
        return vlim.operationalIndicatorId;
      });
      indicatorIds.forEach(indicatorId => {
        // Un-map the indicator from vendor.
        indicatorMappingUpdates.push({
          type: "unmap",
          vendorId: vendorId,
          indicatorId: indicatorId
        });

        // Can we find another vendor to map this indicator to?
        // If the indicator is Occupancy and the current location is a sensor,
        // don't do the mapping.
        const vendorIdToMap = vendorLocationMappings.find(vlm => {
          return vlm.vendorId !== vendorId
            && indicatorVendorMappings.find(ivm => 
              ivm.vendorId === vlm.vendorId 
              && ivm.indicatorId === indicatorId )
            && vlm.vendorCode
        })?.vendorId;
        if (vendorIdToMap && !(indicatorId === occupancyIndicatorId && location.isSensor)) {
          indicatorMappingUpdates.push({
            type: "map",
            vendorId: vendorIdToMap,
            indicatorId: indicatorId
          })
        }
      });
    } else if (!oldVendorCode && newVendorCode) {
      // If location ID was empty, but is now not empty, then map the current vendor
      // to all indicators that can be mapped to this vendor but don't have any 
      // mapping yet.

      // This is the list of all indicators that can be mapped to the vendor,
      // for which the location ID has been changed.
      const indicatorIds = indicatorVendorMappings.filter(ivm => {
        return ivm.vendorId === vendorId && !(ivm.indicatorId === occupancyIndicatorId && location.isSensor);
      }).map(ivm => ivm.indicatorId);

      // Out of all indicators that can be mapped to the vendor, find those ones
      // that are not mapped to any of the other vendors.
      indicatorIds.forEach(indicatorId => {
        const checkedVendorLocationMapping = vendorLocationMappings.find(vlm => {
          return vlm.vendorLocationIndicatorMappings.find( vlim => 
            vlim.operationalIndicatorId === indicatorId );
        });
        if ( !checkedVendorLocationMapping) {
          indicatorMappingUpdates.push({
            type: "map",
            vendorId: vendorId,
            indicatorId: indicatorId
          });
        }
      });
    }

    saveUpdatesToDatabase(vendorCodeUpdate, indicatorMappingUpdates); 
  };

  const handleVendorCodeCancel = (vendorId) => {
    setCachedVendorCodes((prev) => {
      return prev.filter( vc => vc.vendorId !== vendorId);
    });
  };

  const handleVendorLocationIndicatorMappingChecked = async (vendorId, indicatorId, checked) => {
    if (isSaving) {
      return;
    }
    
    // The "checked" parameter is always true, because the user can check the radio
    // button, but cannot un-check it.

    const indicatorMappingUpdates = [{
      type: "map",
      vendorId: vendorId,
      indicatorId: indicatorId
    }];

    // If there's a vendor that needs to be un-ticked for the indicator,
    // add it to the list of updates.
    const oldVendorLocationMapping = vendorLocationMappings.find(vlm => 
      vlm.locationId === location.locationId && vlm.vendorLocationIndicatorMappings.find(vlim => vlim.operationalIndicatorId === indicatorId));
    if (oldVendorLocationMapping) {
      indicatorMappingUpdates.push({
        type: "unmap",
        vendorId: oldVendorLocationMapping.vendorId,
        indicatorId: indicatorId
      });
    }

    saveUpdatesToDatabase(null, indicatorMappingUpdates);
  };

  const saveUpdatesToDatabase = (vendorCodeUpdate, indicatorMappingUpdates) => {
    // The formats of the "update" objects are:
    // vendorCodeUpdate: {
    //   vendorId:
    //   oldVendorCode:
    //   newVendorCode:
    // }
    // indicatorMappingUpdates: [{
    //   type: "map", or "unmap"
    //   vendorId:
    //   indicatorId:
    //}]

    // 1. Prepare the DTOs that'll be sent to the database, and the "revert" DTOs 
    // that'll revert the changes in the local state if the backend returns an error.
    // 2. DTOs are of the same structure as the objects in the vendorLocationMappings
    // array. Apply the prepared DTOs to vendorLocationMappings local state, clear
    // the corresponding object in cachedVendorCodes if location ID was changed for 
    // the vendor.
    // 3. Call UpdateVendorLocationMappings method on the backend.
    // 4. If the backend update failed, revert the local state (vendorLocaitonMappings
    // and cachedVendorCodes) to what they were before step 2. using the prepared
    // set of "revert" DTOs

    setIsSaving(true);
    
    // Step 1.
    const dtos = [];
    const revertDtos = [];

    if ( vendorCodeUpdate ) {
      // The vendorLocationMapping will always be found.
      const vendorLocationMapping = vendorLocationMappings.find(vlm =>
        vlm.vendorId === vendorCodeUpdate.vendorId );
      const dto = {
        ...vendorLocationMapping,
        vendorLocationIndicatorMappings: [...vendorLocationMapping.vendorLocationIndicatorMappings],
        vendorCode: vendorCodeUpdate.newVendorCode
      };
      dtos.push(dto);
      const revertDto = {
        ...vendorLocationMapping,
        vendorLocationIndicatorMappings: [...vendorLocationMapping.vendorLocationIndicatorMappings],
        vendorCode: vendorCodeUpdate.oldVendorCode
      }
      revertDtos.push(revertDto);
    }

    indicatorMappingUpdates.forEach((update, index) => {
      const vendorLocationMapping = vendorLocationMappings.find(vlm =>
        vlm.vendorId === update.vendorId);
      let dto = dtos.find( item => item.vendorId === update.vendorId);
      let revertDto = revertDtos.find( item => item.vendorId === update.vendorId);
      if (!dto) {
        dto = {
          ...vendorLocationMapping,
          vendorLocationIndicatorMappings: [...vendorLocationMapping.vendorLocationIndicatorMappings]
        };
        dtos.push(dto);
        revertDto = {
          ...vendorLocationMapping,
          vendorLocationIndicatorMappings: [...vendorLocationMapping.vendorLocationIndicatorMappings]
        };
        revertDtos.push(revertDto);
      }
      dto.vendorLocationIndicatorMappings = [
        ...dto.vendorLocationIndicatorMappings.filter(im => im.operationalIndicatorId !== update.indicatorId)
      ];
      revertDto.vendorLocationIndicatorMappings = [
        ...revertDto.vendorLocationIndicatorMappings.filter(im => im.operationalIndicatorId !== update.indicatorId)
      ];     
      if (update.type === "map") {
        dto.vendorLocationIndicatorMappings.push({
          operationalIndicatorId: update.indicatorId
        });
      }
      if (update.type === "unmap") {
        revertDto.vendorLocationIndicatorMappings.push({
          operationalIndicatorId: update.indicatorId
        });
      }
    });

    // Step 2.
    if (vendorCodeUpdate) {
      setCachedVendorCodes(prev => {
        return prev.filter(item => item.vendorId !== vendorCodeUpdate.vendorId)
      });
    }
    setVendorLocationMappings((prev) => {
      return prev.map((vlm) => {
        const dto = dtos.find( item => item.vendorId === vlm.vendorId);
        if ( dto) {
          return dto;
        } else {
          return vlm;
        }
      });
    });

    // Step 3.
    updateVendorLocationMappings(dtos)
      .then(() => {
        toast.success("Data sources have been saved.");
        setIsSaving(false);
      })
      .catch((error) => {
        toast.error("Failed to save data sources. " + error.message, {autoClose: false});
        // Step 4.
        if (vendorCodeUpdate) {
          setCachedVendorCodes(prev => {
            return [
              ...prev.filter(item => item.vendorId !== vendorCodeUpdate.vendorId),
              {
                vendorId: vendorCodeUpdate.vendorId,
                vendorCode: vendorCodeUpdate.newVendorCode
              }
            ];
          });
        }
        setVendorLocationMappings((prev) => {
          return prev.map((vlm) => {
            const revertDto = revertDtos.find( item => item.vendorId === vlm.vendorId);
            if ( revertDto) {
              return revertDto;
            } else {
              return vlm;
            }
          });
        });   
        setIsSaving(false);
      });
  };

  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "start start",
        gridTemplateAreas: "inner-div",
        width: "auto"
      }}
    >
      {/* This Box contains the "DATA SOURCES" title and the Box with columns */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "auto",
          gridArea: "inner-div",
          gridRow: 1,
          gridColumn: 1
        }}>
        <Typography
          className={classes.title}
          style={{
            textAlign: "center",
            visibility: isIndicatorLoading? "hidden" : "visible"
          }}>
          Data source
        </Typography>

      {/* // This Box contains the columns. */}
        <Box 
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "auto",
            // gridArea: "inner-div",
            //visibility: isMappingLoading ? "hidden" : "visible", // KF: I need this comment for possible future beautifying of loading state.
          }}>
            {/* // This Box contains the INDICATORS label in the top left corner
            // and the names of the indicators. */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "auto"
              }}
            >
              <Box>
                <Box
                  className={classes.vendorHeader}
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    pl: 2,
                    pr: 2,
                    pb: 1
                  }}
                >
                  <Typography
                    style={{visibility:isIndicatorLoading? "hidden": "visible"}}
                    className={classes.indicatorsLabel}
                  >
                    Indicators
                  </Typography>
                </Box>
                {indicators.map((indicator, i) => {
                  return (
                    <Box
                      key={indicator.id}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        pl: 2,
                        pr: 2
                      }}
                      className={`${classes.indicatorHeader} ${ i % 2 === 0 ? classes.oddIndicatorBackground: ""}`}
                    >
                      <Typography noWrap>
                        {indicator.name}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            {/* // The following set of Box components shows a column for each vendor. */}
            {vendors.map((vendor, i) => {
              const vendorLocationMapping = vendorLocationMappings?.find(vlm => vlm.vendorId === vendor.id);
              // Try to find the code in the local cache - array of changed codes.
              // If the code is not found in the array of chagned codes, then use the code loaded
              // from the database. If the code was not loaded from the database, then show the
              // empty string.
              let cachedVendorCode = cachedVendorCodes.find((vc) => vc.vendorId === vendor.id);
              let vendorCode = "";
              if ( cachedVendorCode) {
                vendorCode = cachedVendorCode.vendorCode;
              } else if (vendorLocationMapping && vendorLocationMapping.vendorCode) {
                vendorCode = vendorLocationMapping.vendorCode;
              }
              
              return (
                <Box
                  key={vendor.id}
                  spacing={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "min-content",
                    minWidth: "170px"
                  }}>
                  {/* // The following Box is the column header for the vendor. */}
                  <Box
                    className={`${classes.vendorHeader} ${i % 2 === 0 ? classes.oddVendorBackground: ""}`}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      pl: 1, pr: 1,
                      pt: 1, pb: 1,
                    }}
                    autoComplete="off"
                  >
                    <Typography
                      noWrap
                      className={classes.vendorLabel}
                    >
                      {vendor.name}
                    </Typography>
                    <Tooltip
                      title="Location ID as it is configured in the data source"
                      enterDelay={2000}
                      enterNextDelay={2000}
                      leaveDelay={200}
                    >
                      <TextField
                        id="code"
                        label = "Location ID"
                        placeholder="Location ID"
                        variant="outlined"
                        fullWidth
                        name="code"
                        value={vendorCode}
                        autoComplete="off"
                        type="text"
                        onChange={(e) => {
                          const { value } = e.target;
                          handleVendorCodeChanged(vendor.id, value);
                        }}
                        margin="dense"
                        size="small"
                        InputProps={cachedVendorCodes.find(vc => vc.vendorId === vendor.id) ? {
                            endAdornment: (
                              <InputAdornment
                                position="end">
                                <IconButton 
                                  edge="end" 
                                  className={classes.vendorCodeOk}
                                  onClickCapture={(e) => handleVendorCodeSave(vendor.id)} 
                                  onMouseDown={e => e.stopPropagation()}
                                >
                                  <DoneIcon />
                                </IconButton>
                                <IconButton 
                                  edge="end" 
                                  className={classes.vendorCodeCancel}
                                  onClickCapture={(e) => handleVendorCodeCancel(vendor.id)} 
                                  onMouseDown={e => e.stopPropagation()}
                                >
                                  <ClearIcon />
                                </IconButton>
                              </InputAdornment>
                            )}
                            :null
                          } 
                        />
                      </Tooltip>
                  </Box>
                  {/* // The folliwng Box components are cells in the column for the vendor. */}
                  {indicators.map((indicator,j) => {
                    let showRadio = indicatorVendorMappings.find(ivm => ivm.vendorId === vendor.id && ivm.indicatorId === indicator.id);
                    // Radio button should not be visible for Occupancy indicator if the location is a sensor.
                    if (location.isSensor && (indicator.name === "Occupancy")) {
                      showRadio = false;
                    }

                    let backgroundClass = "";
                    if (j % 2 === 0) {
                      backgroundClass = classes.oddIndicatorBackground;
                    } else if (i % 2 === 0 ) {
                      backgroundClass = classes.oddVendorBackground;
                    }

                    let radioChecked = false;
                    if (vendorLocationMapping) {
                      if ( vendorLocationMapping.vendorLocationIndicatorMappings.find(vlim => vlim.operationalIndicatorId === indicator.id) ) {
                        radioChecked = true;
                      }
                    }

                    return (
                      <Box
                        key={indicator.id}
                        className={`${classes.vendorLocationIndicatorMapping} ${backgroundClass}`}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          pl: 1, pr: 1,
                          pt: 1, pb: 1,
                        }}>
                        {showRadio ?
                          <Radio
                            disableRipple
                            color="default"
                            // Radio button is enabled only if location ID for the corresponding vendor
                            // and selected location is not empty. The location ID must be saved into
                            // the database, just starting to edit the location ID in the text box is not enough.
                            disabled={!(vendorLocationMapping && vendorLocationMapping.vendorCode)}
                            checked={radioChecked}
                            onChange={(e) => {
                              handleVendorLocationIndicatorMappingChecked(vendor.id, indicator.id, e.target.checked);
                            }}
                          />
                          : null
                        }
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
        </Box>
      </Box>
      <Box
        sx={{
          gridArea: "inner-div",
          margin: "auto",
          gridRow: 1,
          gridColumn: 1
        }}
      >
        <LoadPleaseWait show={isMappingLoading || !location.locationId} />
      </Box>
    </Box>
  );
};