import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {useQuery} from 'react-query';
import {useParams} from "react-router-dom";
import {DashboardGridLayout} from '../admin/dashboards/gridLayout';
import {makeStyles} from "@material-ui/core/styles";
import QRCode from "qrcode.react";
import {parseBoolean, GetQueryParam} from "../../_helpers";
import * as dashboardApi from "../../_services/dashboard.service";
import * as venueApi from "../../_services/venue.service";
import {selectDashboardLastUpdatedById, selectDashboardById} from "../../_reducers/dashboard.selectors";
import {updateDashboardSuccess} from "../../_actions/dashboard.actions";
import {checkDashboardCacheAndRefresh, refreshDashboardWidgetsData} from "../../_actions";
import AudioPlayer from "../_common/AudioPlayer";
import { v4 as uuidv4 } from 'uuid';

const REFRESH_DATA_INTERVAL = 1000 * 3;
const REFRESH_DATA_TOKEN_INTERVAL = 1000 * 60 * 60;

const DEFAULT_MAIN_LOGO = '../../logo_white.png';

const log = function () { console.debug('DashboardViewer', ...arguments); }
//{headerBackgroundColor: '#c6d3d8', footerBackgroundColor: '#1bade5', mainAreaBackgroundColor: '#6b8859', headerFontColor: '#c31bbc', widgetBackgroundColor: '#ef8a5e'}

const useStyles = makeStyles((theme) => ({
  dashboardViewer: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Jost",
    height: "100vh",
    margin: "0px"
  },
  headerPanel: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: props => props?.headerBackgroundColor ?? "black",
    color: props => props?.headerFontColor ?? "white",
  },
  headerPanel_mainLogo: {
    padding: "0px 15px 0px 30px",
    margin: "auto",
    width: "170px",
    [theme.breakpoints.down('sm')]: {
      padding: "10px 5px 10px 10px",
      width: "120px",
    }
  },
  headerPanel_title: {
    flex: "auto",
    display:"flex",
    flexWrap: "wrap",
    textAlign: "center",
    verticalAlign: "middle",
    paddingTop: "10px",
    paddingBottom: "10px"
  },
  title: {
    fontSize: "30px",
    flex: "100%",
    fontWeight: "600",
    margin: "auto",
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: "30px"
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "16px"
    }
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: "400",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    margin: "auto",
    flex: "100%",
    [theme.breakpoints.down('md')]: {
      fontSize: "18px"
    },
    [theme.breakpoints.down('sm')]: {
      display: "none"
    }
  },
  headerPanel_alternateLogo: {
    padding: "0px 30px 0px 15px",
    margin: "auto",
    width: "170px",
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: "10px 5px 10px 10px",
      width: "120px",
    }
  },
  dashboardPanel: {
    flex: "auto",
    padding: "0px",
    [theme.breakpoints.up('xs')]: {
      flexDirection: "column",
      minHeight: "200px",
      overflow: "auto",
    },
  },
  mainImage: {
    objectFit: "scale-down",
    height: "70px",
    [theme.breakpoints.down('sm')]: {
      height: "20px"
    }
  },
  alternateImage: {
    objectFit: "scale-down",
      height: "70px",
      [theme.breakpoints.down('sm')]: {
          height: "20px"
      }
  },
  footerImage: {
    objectFit: "scale-down",
    height: "40px"
  },
  footerPanel: {
    display: "flex",
    flexDirection: "row",
    background: props => props?.footerBackgroundColor ?? "black",
    color: props => props?.footerFontColor ?? "white",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: "row",
    }
  },
  footerPanel_FooterLogo: {
    padding: "30px",
    textAlign: "left", 
    margin: "auto",
    [theme.breakpoints.down('sm')]: {
      display: "none"
    }
  },
  footerPanel_legend: {
    flexWrap: "wrap",
    verticalAlign: "middle",
    flex: "1",
    display:"flex",
    margin: "auto",
    [theme.breakpoints.up('sm')]: {
      padding: "10px",
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    }
  },
  legend: {
    fontSize: "16px",
    flex: "100%",
    textTransform: "uppercase",
    fontWeight: "Bold",
    textAlign: "center",
    margin: "auto",
    [theme.breakpoints.up('md')]: {
      fontSize: "36px",
      flex: "auto",
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: "20px",
      flex: "auto",
    }
  },
  freeToUse: { color: "#3FBE5D" },
  nearCapacity: { color: "#D69706" },
  fullCapacity: { color: "#FC5050" },
  footerPanel_qrcode: {
    margin: "auto",
    textAlign: "right",
    padding: "8px 14px 7px 14px",
    [theme.breakpoints.down('sm')]: {
      display: "none"
    }
  },
  qrcode: {
    width: "100px",
    height: "100px"
  }
}));

export const DashboardViewer = () => {
  const dispatch = useDispatch();
  const [customSettings, setCustomSettings] = useState({});
  let classes = useStyles(customSettings);
  
  const showHeader = parseBoolean(GetQueryParam("showheader") ?? true);
  const showTitle = parseBoolean(GetQueryParam("showtitle") ?? true);
  const showSubTitle = parseBoolean(GetQueryParam("showsubtitle") ?? true);
  const showMainLogo = parseBoolean(GetQueryParam("showmainlogo") ?? true);
  const showAlternateLogo = parseBoolean(GetQueryParam("showalternatelogo") ?? false);

  const showFooter = parseBoolean(GetQueryParam("showfooter") ?? true);
  const showFooterLogo = parseBoolean(GetQueryParam("showfooterlogo") ?? false);
  const showLegend = parseBoolean(GetQueryParam("showlegend") ?? false);
  const showQRCode = parseBoolean(GetQueryParam("showqrcode") ?? false);
  const clientType = GetQueryParam("clienttype") ?? null;

  const title = GetQueryParam("title");
  const subTitle = GetQueryParam("subTitle");
  const qrPreText = GetQueryParam("qrpretext") ?? "";
  const { venueName, dashboardName} = useParams();

  const [mainVenueLogo, setMainVenueLogo] = useState(null);
  const [alternativeVenueLogo, setAlternativeVenueLogo] = useState(null);
  const [footerVenueLogo, setFooterVenueLogo] = useState(null);

  const [dashboardId, setDashboardId] = useState(null);
  const [venueId, setVenueId] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [sessionId, setSessionId] = useState(uuidv4());

  const dashboardLastUpdated = useSelector(state => selectDashboardLastUpdatedById(state, dashboardId));
  const dashboard = useSelector(state => selectDashboardById(state, dashboardId));

  const { data: token } = useQuery('widgetDataAccessToken', dashboardApi.getWidgetDataAccessToken,
    {
        refetchInterval: REFRESH_DATA_TOKEN_INTERVAL,
        staleTime: REFRESH_DATA_TOKEN_INTERVAL
    });

    const requestWidgetsData = () =>
    //refreshDashboardWidgetsData(dashboardId, token);
    checkDashboardCacheAndRefresh(dashboardId, sessionId, token);

    useEffect(() => {
        if (token && dashboardId) {
            setTimeout(() => dispatch(requestWidgetsData()), 100);
            const interval = setInterval(() => dispatch(requestWidgetsData()), REFRESH_DATA_INTERVAL)
            return () => clearInterval(interval);
        }
    }, [token, dashboardId, requestWidgetsData, dispatch]);
      
  useEffect(() => {
    log("useEffect", {lastUpdated, dashboardLastUpdated, dashboard});
    if (token && (!dashboard || (dashboardLastUpdated !== lastUpdated))) {
      dashboardApi.getPublishedDashboard(venueName, dashboardName, token)
        .then((dashboards) => {
          log({dashboards});
          if (dashboards.length) {
            const dbrd = dashboards[0];
            if (title !== null)
              dbrd.name = title; // override dashboard name if title is provided in query parameter
            if (subTitle !== null)
              dbrd.description = subTitle; // override dashboard description if subTitle is provided in query parameter 

            setLastUpdated(dbrd.lastUpdated);
            dispatch(updateDashboardSuccess(dbrd));
            setDashboardId(dbrd.id);
            setVenueId(dbrd.venueId);
          }
        }).catch((error) => {
          log(error);
      });
    }  
  }, [token, dashboardName, venueName, title, subTitle, dashboardLastUpdated, lastUpdated, dashboard, dispatch]);
    
  const updateBranding = () => {
    venueApi.getVenueBrandingSettings(venueId, token)
      .then((response) => {
        console.log("getVenueBrandingSettings", JSON.parse(response.data?.settings));
        setCustomSettings(JSON.parse(response.data?.settings));
        setTimeout(() => updateBranding(), 60000); // refresh branding in next 60 secs
      }).catch((err) => { log(err); });
    venueApi.getVenueBrandingMainLogo(venueId, token)
      .then((response) => {
        setMainVenueLogo(response.data?.size ? response.data : DEFAULT_MAIN_LOGO);
      }).catch((err) => { log(err); });
    venueApi.getVenueBrandingAlternativeLogo(venueId, token)
      .then((response) => {
        setAlternativeVenueLogo(response.data);
      }).catch((err) => { log(err); });
    venueApi.getVenueBrandingFooterLogo(venueId, token)
      .then((response) => {
        setFooterVenueLogo(response.data?.size ? response.data : DEFAULT_MAIN_LOGO);
      }).catch((err) => { log(err); });
  }

  // should not put updateBranding in list of dependency because it will keep looping
  // and log & request increase really quick
  useEffect(() => {
    if (token && venueId !== null) 
      updateBranding();
  }, [venueId, token]); 
  
  if ((dashboard?.isProtected ?? true) || !dashboard?.isActive) return '';

    return (
      <div className={classes.dashboardViewer}>
        <AudioPlayer id={"audio"} showPlayIcon={false} showPauseIcon={false} showStopIcon={false} muted={true} showMuteIcon={false} style={{ position: "absolute", top: "0px", left: "0px"}} ></AudioPlayer>
        { showHeader ? (
            <div className={classes.headerPanel}>
              { showMainLogo && mainVenueLogo ? (
                <div className={classes.headerPanel_mainLogo}>
                  <img className={classes.mainImage} src={mainVenueLogo.size ? URL.createObjectURL(mainVenueLogo) : mainVenueLogo} alt={"Main Logo"} />
                </div>
              ): ("")
              }
              { showTitle ? (
                <div className={classes.headerPanel_title}>
                  <div className={classes.title}>{dashboard.name}</div><br />
                  { showSubTitle ? (
                    <div className={classes.subTitle}>{dashboard.description}</div>): ("")
                  }
                </div>
              ): ("")
              }
              { showAlternateLogo ? (
                <div className={classes.headerPanel_alternateLogo}>
                  {alternativeVenueLogo?.size ? 
                    (
                      <img className={classes.alternateImage} src={URL.createObjectURL(alternativeVenueLogo)} alt={"Alternate Logo"} />
                    ) : (
                      <></>
                    )
                  }
                </div>): ("")
              }
            </div>
          ) : ("")
        }
        <div className={classes.dashboardPanel}>
          <DashboardGridLayout
            dashboardId={dashboard.id}
            isInEditMode={false} 
            dashboardWidgets={dashboard.dashboardWidget} 
            isPublicViewMode={true} 
            brandingSettings={customSettings} 
            clientType={clientType}
            />
        </div>
        { showFooter ? (
          <div className={classes.footerPanel}>
            { showFooterLogo && footerVenueLogo ? (
              <div className={classes.footerPanel_FooterLogo}>
                <img className={classes.footerImage} src={footerVenueLogo.size ? URL.createObjectURL(footerVenueLogo) : footerVenueLogo} alt={"Footer Logo"} />
              </div>): ("")
            }
            <div className={classes.footerPanel_legend}>
              { showLegend ? (
                <>
                  <div className={[classes.legend, classes.freeToUse].join(" ")}>free to use</div>
                  <div className={[classes.legend, classes.nearCapacity].join(" ")}>Near capacity </div>
                  <div className={[classes.legend, classes.fullCapacity].join(" ")}>full capaciTY</div>
                </>
                ): ("")}
            </div>
            { showQRCode ? (
              <div className={classes.footerPanel_qrcode}>
                {qrPreText}&nbsp;
                <QRCode value={window.location.href} className={classes.qrcode} renderAs={"svg"} />
              </div>): ("")
            }
          </div>
          ) : ("")
        }
      </div>
    ); 
}
