import React, { useRef, useState, useLayoutEffect } from "react" 
import useResizeObservedRef from "../../../_helpers/useResizeObservedRef";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import { icon } from "../../../_constants";
import appViewModeConstants from "../../../_constants/app.view.mode.constants";
import { EmptyContainerWithTwoBoxes } from "../EmptyContainer";

const useStyles = makeStyles((theme) => ({
  ...theme.widget.common,
  ...theme.widget.traffic,
  ...theme.widget.trafficLight,
  container: { ...theme.widget.traffic.container },
  
}));

// define initial values
const BASE_FONT_SIZE = 10;
const BASE_WIDGET_SIZE = 100;
  
export const TrafficInOut = ({ width, height, data, settings, viewMode, brandingSettings  }) => {

  const targetRef = useRef();
  const [fontSize, setFontSize] = useState(BASE_FONT_SIZE);
  var props = { fontSize, width, height, data, settings, viewMode };
  const classes = useStyles({ width, fontSize, ...brandingSettings });
  const backgroundColor = classes.defaultBackground;

  
  const isViewMode = viewMode === appViewModeConstants.VIEW_MODE;
  const showBusinessHours = settings?.WidgetSettingUseBusinessHours ?? false;
  const startHours = data?.location?.venue?.businessHoursStart ?? "0:00";
  const finishHours = data?.location?.venue?.businessHoursEnd ?? "0:00";

    
  const noData = !settings?.RefLocation;
 
  const applyScaleFactor = (width, height) => {
    const newWidgetSize = width > height ? height : width;
    const scaleFactor = newWidgetSize / BASE_WIDGET_SIZE;

    setFontSize(BASE_FONT_SIZE * scaleFactor);
    
  };
  
  const ref = useResizeObservedRef(({ width, height }) => {
    applyScaleFactor(width, height);
  });

  // use this hook to set initial font sizes by applying scale factor based on current widget size. this is used when widget renders for the first time on viewing dashboard
  useLayoutEffect(() => {
    if (targetRef.current) {
      applyScaleFactor(targetRef.current.offsetWidth, targetRef.current.offsetHeight);
    }
  }, []);
  
  return (
    <>
      <Box
        className={`${classes.container} ${backgroundColor}`}
        height={height}
        ref={ref} >
        
        {!noData ? (
          <Box display="flex" height={height} ref={targetRef}>
            <Box style={{ height: '100%' }}
              display="flex"
              flexDirection="column"
              className={`${classes.containerLeftWithBorder} `}
            >
              <div>
                <Typography
                  className={classes.numberContent}>
                  {((isViewMode && data?.traffic?.trafficInValue) || 0).toLocaleString("en-US")} / {((isViewMode && data?.traffic?.trafficOutValue) || 0).toLocaleString("en-US")}
                </Typography>
                <Typography
                  className={classes.location}>
                  {isViewMode ? data?.location?.name : settings.RefLocation?.name}
                </Typography></div>
            </Box>
            
            <Box
              display="flex"
              flexDirection="column"
              className={classes.containerRight}
            >
              <Typography className={classes.content}>
                <img
                  src={icon.path + "inOutWidget.svg"}
                  alt="Traffic In/Out"
                />
              </Typography>
              <Typography className={classes.content}>
                {isViewMode ? data?.traffic?.timePeriodName ?? "" : settings.WidgetSettingTimePeriod?.name}
              </Typography>
              {showBusinessHours && (
                <Typography className={classes.hoursContent}>
                  {startHours + " > " + finishHours}
                </Typography>
              )}
            </Box>
          </Box>
        ) : (
          <EmptyContainerWithTwoBoxes {...props} />
        )}
      </Box>
    </>
  );

}

export default TrafficInOut;
