import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import appViewModeConstants from "../../../_constants/app.view.mode.constants";
import useResizeObservedRef from "../../../_helpers/useResizeObservedRef";
import { EmptyContainer } from "../EmptyContainer";

const useStyles = makeStyles((theme) => ({
  ...theme.widget.common,
  ...theme.widget.number,
  ...theme.widget.flashingWidget,
}));

// define initial values
const BASE_FONT_SIZE = 10;
const BASE_WIDGET_SIZE = 122;

const getLocStr = (locsArray) => {
    const str = locsArray?.map(it => it.name)?.filter(it => it)?.join(', ');
    return truncateString(str);
}

const truncateString = (stringValue) => {
    const length = 20;
    if ((stringValue??"").length <= length)
        return stringValue;
    // truncate string if it's more than the specified length
    return `${stringValue.substr(0, length).trim()} ...`;
}

export const WidgetNumberContent = ({ backgroundColor, width, height, data, settings, viewMode, brandingSettings, onAudioPlay }) => {
    const numberData = data?.numberData;
    const colorData = data?.color;
    const targetRef = useRef();
    const isViewMode = viewMode === appViewModeConstants.VIEW_MODE;
    const [fontSize, setFontSize] = useState(BASE_FONT_SIZE);
    const classes = useStyles({ backgroundColor, width, fontSize, ...brandingSettings });
    const noData = !settings?.RefListLocation;
    var props = { fontSize, backgroundColor, width, height };

    const [playSound, setPlaySound] = useState(false);

    useEffect(() => {
        setPlaySound(isViewMode && data?.sound);
    }, [data?.sound, isViewMode, setPlaySound]);

    useEffect(() => {
        if (playSound) {
            onAudioPlay();
        }
    }, [playSound, onAudioPlay]);

    // calculate scale factor depending on the width and height. Always use smallest value to use for scale factor calculation
    // original widget size is approx BASE_WIDGET_SIZE width and height, and base font size is BASE_FONT_SIZE. Using this values we calculate scale factor
    const applyScaleFactor = (width, height) => {
        const newWidgetSize = width > height ? height : width;
        const scaleFactor = newWidgetSize / BASE_WIDGET_SIZE;

        setFontSize(BASE_FONT_SIZE * scaleFactor);
    };

    // use this ref to monitor resize venue and adjust the font sizes accordingly using scale factor
    const ref = useResizeObservedRef(({ width, height }) => {
        applyScaleFactor(width, height);
    });

    // use this hook to set initial font sizes by applying scale factor based on current widget size. this is used when widget renders for the first time on viewing dashboard
    useLayoutEffect(() => {
        if (targetRef.current) {
            applyScaleFactor(targetRef.current.offsetWidth, targetRef.current.offsetHeight);
        }
    }, []);

    

    // The following are pending changes once we build APIs for this widget and link in WidgetWrapper:
    //   - get data and update rendering with received data. At the moment it is dummy value
    return (
        <>
            <Box ref={ref}
                className={`${classes.container} ${isViewMode && colorData?.flash ? classes.flashEffect : ''}`}
                style={isViewMode && colorData?.value ? {backgroundColor: colorData.value} : null}            
            >
                {
                    noData ? (
                        <EmptyContainer {...props} />
                    ) : (
               
                        <div ref={targetRef}>
                            <div className={classes.value}>{(isViewMode ? numberData?.value?.toLocaleString('en-US') : 0)}</div>
                            <div className={classes.caption}>{isViewMode ? numberData?.caption : (settings.RefNumberWidgetValueType?.name ?? 'CAPTION')}</div>
                            <div className={classes.desc1}>{isViewMode ? truncateString(numberData?.description1) : (getLocStr(settings.RefListLocation) ?? "Description 1")}</div>
                            <div className={classes.desc2}>{isViewMode ? numberData?.description2 : (settings.RefNumberWidgetTimePeriod?.name ?? "Description 2")}</div>
                        </div>
              
                    )
                }
            </Box>
        </>
    );
};

export default WidgetNumberContent;
