import React from "react" 
import { Box, Typography } from "@material-ui/core";
import appViewModeConstants from "../../../_constants/app.view.mode.constants";
import * as occUtils from './OccupancyUtils';
import EditableNumber from "../EditableNumber";

const RawNumber = (props) => {
  const { data, viewMode, classes, textColor } = props;
  const isViewMode = viewMode === appViewModeConstants.VIEW_MODE;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "baseline"
        }}
      >
        {(isViewMode && data?.occupancy?.occupancyNumber) ? 
          <EditableNumber 
            className={classes.numberContent}
            textColor={textColor}
            value = {data.occupancy.occupancyNumber}
            venueId = {data?.location?.venueId}
            locationId = {data?.location?.id}
            factorTypeId = 'e1ca2628-b3f3-4ae9-86a6-f60f1dbbd1c7' // "Occupancy" factor type
          />
          : <Typography className={classes.numberContent}> 0 </Typography>
        }
        <Typography className={classes.numberContent}>&nbsp;/&nbsp;{(isViewMode && data?.location?.maximumCapacity?.toLocaleString("en-US")) || 0}</Typography>
      </Box>
        {/* <Typography className={classes.numberContent}>
            {(isViewMode && data?.occupancy?.occupancyNumber) || 0} / {(isViewMode && data?.location?.maximumCapacity) || 0}
        </Typography> */}
      <Typography className={classes.location}>
          {occUtils.getLocationName(props)}
      </Typography>
    </>
  );
}

export default RawNumber;
