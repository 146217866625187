// Sample events calendar build, explained and detailed over at
// https://justacoding.blog/react-calendar-component-example-with-events/

import React, { useState, useEffect, Fragment } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {Col, Container, Row} from "react-bootstrap";
import {addVenueEvent, updateVenueEvent, deleteVenueEvent} from "../../../_services/venue.event.service";
import {toast} from "react-toastify";
import {Button, ButtonType} from "../../_common/htmlTags";
import AddIcon from "@material-ui/icons/Add";
import TextLink from "../../_common/htmlTags/TextLink";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {SCOPES} from "../../../_constants/user.permissions.constants";
import PermissionsGate from "../../_common/permissions/PermissionsGate";
import { Grid, Box, Tooltip, IconButton, Typography } from '@material-ui/core';
import VenueEventsImportModal from "./venueEvents/VenueEventsImportModal";
import VenueEventsExportModal from './venueEvents/VenueEventsExportModal';
import {getAllVenueEvents} from "../../../_services/venue.event.service";
import { alpha } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { CallReceived } from '@material-ui/icons';
import DeleteIcon from "@material-ui/icons/Delete";
import devconsole from "../../_common/devconsole";

const useStyles = makeStyles((theme) => ({

  formInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  calendarWrapper: {
    backgroundColor: "white",
    padding: "20px"
  },
  calendar: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: "800px",
    width: "100%",
    position: "relative",
    "& a": {
      color: "grey"
    }
  },
  cells: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    borderStyle: "solid none none solid",
    borderColor: theme.palette.eventCalendar.border,
    borderWidth: "0.5px",
  },
  cell: {
    width: "14.2857%",
    height: "130px",
    borderStyle: "none solid solid none",
    borderColor: theme.palette.eventCalendar.border,
    borderWidth: "0.5px",
    "& .date": {
      margin: "8px 16px 8px 16px",
      fontSize: "20px",
      fontWeight: 600,
      color: theme.palette.color.primary.dark
    }
  },
  current: {
    background: "white",
    "& .date": {
    }
  },
  otherMonth: {
    background: alpha(theme.palette.color.primary.background, 0.8),
    "& .date": {
      color: alpha(theme.palette.color.secondary.main, 0.5)
    }
  },
  datesGrid: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    borderStyle: "none none none solid",
    borderColor: theme.palette.eventCalendar.border,
    borderWidth: "0.5px",
  },
  addEventOnDay: {
    borderRadius: "5px",
    float: "right",
    width: "10px"
  },
  miniEvent: {
    background: theme.palette.eventCalendar.background,
    color: theme.palette.eventCalendar.text,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: alpha(theme.palette.eventCalendar.text, 0.4),
    borderRadius: "2px",
    margin: "16px 16px",
    padding: "4px 8px",
    cursor: "pointer",    
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  navigation: {
    width: "100%",
    marginTop: "8px",
    marginBottom: "16px"
  },
  iconButton: {
    color: theme.palette.color.primary.main,
    cursor: "pointer",
    width: "40px",
    marginBottom:"-20px"
  },
  dayLabelRow: {
    borderStyle: "solid none none solid",
    borderColor: theme.palette.eventCalendar.border,
    borderWidth: "0.5px",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  dayLabel: {
    fontWeight: 500,
    fontSize: "18px",
    textAlign: "center",
    height: "auto",
    padding: "10px 0",
    background: "white",
  },
  addEvent: {
    textDecoration: "none",
    display: "block",
    textAlign: "center",
    width: "100%",
    background: "#3fb73f",
    borderBottom: "2px solid green",
    color: "white",
    padding: "10px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    top: "0",
    left: "0",
    width: "calc(100% + 40px)",
    height: "calc(100% + 40px)",
    margin: "-20px"
  }, 
  calendarModal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    background: "white",
    position: "absolute",
    width: "50%",
    left: "25%",
    top: "5%",
    borderRadius: "5px",
    padding: theme.spacing(1),
    "& h3": {
      margin: "0",
      background: "#e6e6e6",
      padding: "12px",
      borderRadius: "5px",
    },
  },
  modalTitle: {
    fontSize: "24px",
    fontWeight: "500",
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  fieldName: {
    fontSize: "16px",
    color: theme.palette.color.secondary.main,
    fontWeight: 500
  },
  fieldValue: {
    fontSize: "16px",
    color: theme.palette.color.primary.dark,
  },
  deleteIcon: {
    color:  theme.palette.color.primary.red,
    marginLeft: theme.spacing(1)
  },
  close: {
    marginTop: "22px",
    fontSize: "0.7em",
    display: "block",
  },
  feedback: {
    position: "absolute",
    width: "90%",
    padding: "16px 0",
    left: "5%",
    textAlign: "center",
    bottom: "5%",
    background: "#3fb73f",
    borderBottom: "3px solid green",
    borderRadius: "5px",
    color: "white",
  },
  loader: {
    position: "absolute",
    top: "50%",
    marginTop: "-70px",
    left: "50%",
    marginLeft: "-38px",
  },
  /* animated loading spinner from https://loading.io/css/ */
  ldsRoller: {
    display: "inline-block",
    position: "relative",
    width: "80px",
    height: "80px",
    "& div": {
      animation: "$ldsRoller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite",
      transformOrigin: "40px 40px",
      "&:after": {
        content: " ",
        display: "block",
        position: "absolute",
        width: "7px",
        height: "7px",
        borderRadius: "50%",
        background: "#fff",
        margin: "-4px 0 0 -4px",
      }
    },
    "& div:nth-child(1)": {
      animationDelay: "-0.036s",
      "&:after": {
        top: "63px",
        left: "63px",
      }
    },
    "& div:nth-child(2)": {
      animationDelay: "-0.072s",
      "&:after": {
        top: "68px",
        left: "56px",
      }
    },
    "& div:nth-child(3)": {
      animationDelay: "-0.108s",
      "&:after": {
        top: "71px",
        left: "48px",
      }
    },
    "& div:nth-child(4)": {
      animationDelay: "-0.144s",
      "&:after": {
        top: "72px",
        left: "40px",
      }
    },
    "& div:nth-child(5)": {
      animationDelay: "-0.18s",
      "&:after": {
        top: "71px",
        left: "32px",
      }
    },
    "& div:nth-child(6)": {
      animationDelay: "-0.216s",
      "&:after": {
        top: "68px",
        left: "24px",
      }
    },
    "& div:nth-child(7)": {
      animationDelay: "-0.252s",
      "&:after": {
        top: "63px",
        left: "17px",
      }
    },
    "& div:nth-child(8)": {
      animationDelay: "-0.288s",
      "&:after": {
        top: "56px",
        left: "12px",
      }
    },
  },
  "@keyframes ldsRoller": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    }
  }  
}));

// If you make changes on the order of the week, then you need to update somewhere
// in the code since it's not
const DAYS_SHORT = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]

const toStartOfDay = (date) => {
	const newDate = new Date(date)
  newDate.setHours(0)
  newDate.setMinutes(0)
  newDate.setSeconds(0)
  newDate.setMilliseconds(0)
  return newDate
}

const pad = (input) => {
	return input < 10 ? "0" + input : input
}

// I'm using default <input type="datepick-local">,
// so a specific date format is required
const dateToInputFormat = (date) => {
	if (!date) {
  	return null
  }
  
	const month = pad(date.getMonth() + 1)
  const day = pad(date.getDate())
  const hours = pad(date.getHours())
  const minutes = pad(date.getMinutes())
  
  return `${date.getFullYear()}-${month}-${day}T${hours}:${minutes}`
}

// Could be used to filter out invalid events data also
// (ie. missing properties) or events that can't be parsed 
// to contain valid to/from dates
const parseEvents = (events) => {
  return events.map(event => {
  	const from = new Date(event.startDate)
    const to = new Date(event.endDate)

    return {
      ...event,
      from,
      to
    }
  })
}

const findEventsForDate = (events, date) => {
  const dateTime = date.getTime()
  if (events) {
    return events.filter(event => {
      const eventFromTime = toStartOfDay(event.from ?? event.startDate).getTime()
      const eventToTime = toStartOfDay(event.to ?? event.endDate).getTime()

      return (dateTime >= eventFromTime && dateTime <= eventToTime)
    })
  }
  else return [];
}

// Top bar, contains the month/year combo as well as back/forward links
const Navigation = ({ date, setDate, setShowingEventForm, setShowImportModal, setShowExportModal }) => {
  const classes = useStyles();
  const currentMonthYear = date.getFullYear().toString() + "-" + pad(date.getMonth() + 1).toString();
  return (
    <div className={classes.navigation}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <TextField 
          id={"month"} 
          label={"Month"} 
          name={"month"}
          type={"month"}  
          value={currentMonthYear} 
          variant="outlined"
          onChange={(e) => { setDate(new Date(e.target.value + "-01")); }} 
          required 
          style={{width: "250px"}}
        />
        <Box 
          sx={{
            display: "flex",
            width: "auto",
          }}
        >
          {/* <PermissionsGate scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_ADD_EVENTS]}>
            <Button variant="contained" component={TextLink} onClick={() => {
              setShowingEventForm({ visible: true })
            }}><AddIcon /></Button>&nbsp;
          </PermissionsGate> */}
          <Button 
            variant="outlined" 
            buttonType={ButtonType.Secondary}
            style={{
              marginLeft: "40px"
            }}
            onClick={() => {
              const newDate = new Date(date)
              newDate.setMonth(newDate.getMonth() - 1)
              setDate(newDate)
          }}><ArrowBackIcon /></Button>
          <Button 
            variant="outlined" 
            buttonType={ButtonType.Secondary}
            style={{
              marginLeft: "8px"
            }}
            onClick={() => {
              const newDate = new Date(date)
              newDate.setMonth(newDate.getMonth() + 1)
              setDate(newDate)
          }}><ArrowForwardIcon /></Button>
          <Button 
            variant="outlined" 
            buttonType={ButtonType.Dark}
            style={{
              marginLeft: "40px"
            }}
            onClick={() => {
              const today = new Date(Date.now());
              setDate(today);
            }}
          >
            Today
          </Button>
        </Box>
        <div style={{flexGrow: 1}} />
        <Tooltip title="Export">
          <IconButton
            aria-label="view"
            className={classes.iconButton}
            onClick={(e) => setShowExportModal(true)}
          >
            <CloudDownloadIcon  />
          </IconButton>
        </Tooltip>
        <Tooltip title="Import">
          <IconButton
            aria-label="view"
            className={classes.iconButton}
            onClick={(e) => {setShowImportModal(true)}}
          >
            <CloudUploadIcon  />
          </IconButton>
        </Tooltip>
      </Box>
    </div>
  )
}

// Week day headers: Mon, Tue, Wed etc
const DayLabels = () => {
  const classes = useStyles();
  return (
    <div className={classes.dayLabelRow}>
      {
        DAYS_SHORT.map((dayLabel, index) => {
          return <div className={classes.dayLabel + " " + classes.cell} key={index}>{dayLabel}</div>
         })
      }
    </div>
  );
};

// An individual event displayed within the calendar grid itself
// can be clicked to open the main event view
const MiniEvent = ({ event, setViewingEvent }) => {
  const classes = useStyles();
  return (
    <div className={classes.miniEvent}
         onClick={() => { setViewingEvent(event); }}>
      {event.eventName}
    </div>
  )
}

// The main event view, opens in a modal and contains all information
// about the event in question
const Event = ({ event, setViewingEvent, setShowingEventForm, deleteEvent }) => {
  const classes = useStyles();
  const startDateStr = (new Date(event.startDate)).toLocaleString();
  const endDateStr = (new Date(event.endDate)).toLocaleString();
  return (
    <Modal onClose={() => setViewingEvent(null)} title={`${event.eventName}`} >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          margin: "8px 16px 16px 16px"
        }}
      >
        <Box
        >
          <Typography
            display="inline"
            className={classes.fieldName}
          >
            Dates:&nbsp;
          </Typography>
          <Typography
            display="inline"
            className={classes.fieldValue}
          >
            {startDateStr} &ndash; {endDateStr}
          </Typography>
        </Box>
        <Box
          sx={{
            mt: "16px"
          }}
        >
          <Typography
            display="inline"
            className={classes.fieldName}
          >
            Business hours:&nbsp;
          </Typography>
          <Typography
            display="inline"
            className={classes.fieldValue}
          >
            {event.businessHoursFrom} &ndash; {event.businessHoursTo}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            mt: "16px"
          }}>
          <Typography
          className={classes.fieldName}
          >
            Description:&nbsp;
          </Typography>
          <Typography
            className={classes.fieldValue}
            style={{
              flexGrow: 1
            }}
          >
            {event.eventDescription}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: "32px"
          }}
        >
          <PermissionsGate scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_EVENTS]}>
            <Button
              variant="contained"
              type="submit"
              buttonType={ButtonType.Primary}
              style={{
                padding: "10px 24px",
                margin: "0 8px 0px 0px"
              }}
              onClick={()=>{
                setViewingEvent(null);
                setShowingEventForm({ visible: true, withEvent: event });
              }}
            >
              Change
            </Button>
          </PermissionsGate>
          <Button
            variant="outlined"
            type="button"
            buttonType={ButtonType.Secondary}
            onClick={() => {
              setViewingEvent(null);
            }}
            style={{
              padding: "10px 24px",
              margin: "0 0 0 16px"
            }}
          >
            Cancel
          </Button>
          <PermissionsGate scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_DELETE_EVENTS]}>
            <IconButton 
              edge="end" 
              aria-label="Remove"
              className={classes.deleteIcon}
              onClick={() => {
                deleteEvent(event);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </PermissionsGate>
        </Box>
      </Box>
    </Modal>
  )
}

// Form to add new events or edit existing events
// In a real implementation, we'd have some frontend
// validation and also the equivalent in our 
// backend service...
const EventForm = ({ setShowingEventForm, addEvent, editEvent, withEvent, setViewingEvent, preselectedDate }) => {
  const newEvent = withEvent || {};
  const classes = useStyles();
  if (!withEvent && !!preselectedDate) {
    newEvent.startDate = dateToInputFormat(preselectedDate)
  }
  const [event, setEvent] = useState(newEvent);
  const handleSave= (e) => {
    e.preventDefault();
    if (withEvent) 
      editEvent(event);
    else 
      addEvent(event);
  };
  return (
    <form autoComplete="off" onSubmit={handleSave}>
      <Modal onClose={() => setShowingEventForm({ visible: false })} title="Event properties">
        <Container fluid>
          <Row>
            <Col md={12}>
              <div className={classes.formInput}>
              <TextField id="eventName" label="Name" name="eventName"           
                   placeholder={"ie. My Event"} value={event.eventName}
                   defaultValue={""} variant="outlined" required fullWidth
                   onChange={(e) => setEvent({ ...event, eventName: e.target.value })}/>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className={classes.formInput}>
              <TextField id="eventDescription" label="Description" name="eventDescription" multiline rows={5}
                         placeholder={"Describe the event"} value={event.eventDescription} 
                         defaultValue={""} variant="outlined" required fullWidth 
                         onChange={(e) => setEvent({ ...event, eventDescription: e.target.value })}/>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className={classes.formInput}>
                <TextField id="startDate" label="Start date" name="startDate" type="datetime-local"
                           placeholder={"Start date"} value={event.startDate}
                           defaultValue={event.startDate} variant="outlined" required fullWidth
                           onChange={(e) => setEvent({ ...event, startDate: e.target.value })}/>
              </div>
            </Col>
            <Col md={6}>
              <div className={classes.formInput}>
                <TextField id="endDate" label="End date" name="endDate" type="datetime-local"
                           placeholder={"End date"}
                           defaultValue={event.endDate} variant="outlined" required fullWidth
                           onChange={(e) => setEvent({ ...event, endDate: e.target.value })}/>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className={classes.formInput}>
                <TextField id="businessHoursFrom" label="Business hours start" name="businessHoursFrom" type="time"
                           value={event.businessHoursFrom}
                           defaultValue={"00:00"} variant="outlined" required fullWidth
                           onChange={(e) => setEvent({ ...event, businessHoursFrom: e.target.value })}/>
              </div>
            </Col>
            <Col md={6}>
              <div className={classes.formInput}>
                <TextField id="businessHoursTo" label="Business hours end" name="businessHoursTo" type="time"
                           value={event.businessHoursTo}
                           defaultValue={"00:00"} variant="outlined" required fullWidth
                           onChange={(e) => setEvent({ ...event, businessHoursTo: e.target.value })}/>
              </div>
            </Col>
          </Row>
        </Container>
        
        {withEvent ? (
          <Fragment>
            <Button
              variant="contained"
              type="submit"
              buttonType={ButtonType.Primary}
              style={{
                padding: "10px 24px",
                margin: "16px 8px 16px 16px"
              }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              type="button"
              buttonType={ButtonType.Secondary}
              onClick={() => {
                setShowingEventForm({ visible: false });
                setViewingEvent(event);
              }}
              style={{
                padding: "10px 24px",
                margin: "16px"
              }}
            >
              Cancel
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            <Button
              variant="contained"
              type="submit"
              buttonType={ButtonType.Primary}
              onClick={handleSave}
              style={{
                padding: "10px 24px",
                margin: "16px 8px 16px 16px"
              }}
            >
              Save
            </Button>
            <Button
              className="btn primary"
              variant="outlined"
              type="button"
              buttonType={ButtonType.Secondary}
              onClick={() => {
                setShowingEventForm({ visible: false });
              }}
              style={{
                padding: "10px 24px",
                margin: "16px"
              }}
            >
              Cancel
            </Button>
          </Fragment>
        )}
      </Modal>
    </form>
  )
}

// Generic component - modal to present children within
const Modal = ({ children, onClose, title, className }) => {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.overlay} onClick={onClose} />
      <div className={classes.calendarModal + " " + className}>
        <Typography className={classes.modalTitle}>
            {title}
        </Typography>
        <div className={classes.inner}>
          {children}
        </div>
      </div>
    </Fragment>
  )
}

// Generic component - a nicely animated loading spinner
const Loader = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.overlay} />
      <div className={classes.loader}>
        <div className={classes.ldsRoller}>
          <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div>
      </div>
    </Fragment>
  )
}

// Generic component - simple feedback after an action has taken place
const Feedback = ({ message, type }) => {
  const classes = useStyles();
  return (
    <div className={classes.feedback + " " + type}>{message}</div>
  )
}

// The EventGrid of days, renders a month's worth of days and
// also populates the events on the relevant dates
const EventGrid = ({ date, events, setViewingEvent, setShowingEventForm, actualDate }) => {
  const ROWS_COUNT = 6;
  const currentDate = toStartOfDay(new Date());
  const classes = useStyles();

  // Finds the closest Monday relative to the first day of
  // the target month/year combination
  // Then increment upon this day until we have a full set
  // of date objects to work with
  const startingDate = new Date(date.getFullYear(), date.getMonth(), 1);
  let currentMonthYear = parseInt(date.getFullYear().toString() + ( pad(date.getMonth() + 1)).toString());
  startingDate.setDate(startingDate.getDate() - ((startingDate.getDay() - 1) === -1 ? 6: (startingDate.getDay() - 1)));
  const dates = [];
  for (let i = 0; i < (ROWS_COUNT * 7); i++) {
    const date = new Date(startingDate);
    let dateMonthYear = parseInt(date.getFullYear().toString() + ( pad(date.getMonth() + 1)).toString());
    // when the index #35 or #28 is already the next month, then skip the whole week 
    if ((i === 35 || i === 28) && dateMonthYear > currentMonthYear) break;
    dates.push({date, events: findEventsForDate(events, date)});
    startingDate.setDate(startingDate.getDate() + 1);
  }
  return (
    <div className={classes.datesGrid}>
      {dates.map((date, index) => {
        return (
          <div
              key={index}
              className={classes.cell + " " + (date.date.getTime() === currentDate.getTime() ? classes.current : "") + " " + (date.date.getMonth() != actualDate.getMonth() ? classes.otherMonth : "")}>
            <div className="date">
              {date.date.getDate()}
              <PermissionsGate scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_ADD_EVENTS]}>
                <Button variant="contained" size="small" style={{width:"44px" ,height: "32px", padding: 0, minWidth: "44px"}} className={classes.addEventOnDay}
                        onClick={() => { setShowingEventForm({ visible: true, preselectedDate: date.date }) }} >
                  <AddIcon />
                </Button>
              </PermissionsGate>
            </div>
            {date.events.map((event, index) =>
                <MiniEvent key={index} event={event} setViewingEvent={setViewingEvent} />
            )}
          </div>
        )
      })}
    </div>
  )
}

// The "main" component, our actual calendar
const VenueEventCalendar = ({ venueId, month, year, preloadedEvents = [] }) => {
  const classes = useStyles();
  const selectedDate = new Date(year, month - 1)

  const [date, setDate] = useState(selectedDate)
  const [viewingEvent, setViewingEvent] = useState(false)
  const [showingEventForm, setShowingEventForm] = useState({ visible: false })
  const [isLoading, setIsLoading] = useState(false)
  const [feedback, setFeedback] = useState()

  const parsedEvents = parseEvents(preloadedEvents)
  const [events, setEvents] = useState(null)

  const [showImportModal, setShowImportModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  
  useEffect(() => {
  	devconsole.log("Date has changed... load fresh data")
  }, [date]);

  useEffect (() => {
    if ((events === null || events?.length === 0) && (parsedEvents !== null && parsedEvents.length !== 0)) {
      setEvents(parsedEvents);
    }
  }, [parsedEvents]);

  const addEvent = (venueEvent) => {
    setIsLoading(true);
    venueEvent.venueId = venueId;
    addVenueEvent(venueEvent).then(d => {
      const parsedEvents = parseEvents([d.data]);
      const updatedEvents = [...(events ?? [])];
      updatedEvents.push(parsedEvents[0]);
      setShowingEventForm({ visible: false });
      setEvents(updatedEvents);
      // reload events just to make sure changes from db are loaded
      getAllVenueEvents(venueId)
        .then(d => {
          setIsLoading(false);
          setEvents(parseEvents(d.data));
        })
        .catch(error => {
          console.error(`getAllVenueEvents: ${error.message}`, error);
        });
      toast.success("Event created successfully");
    }).catch(error => {
      setIsLoading(false);

      setViewingEvent(null);
      console.error(`addVenueEvent: ${error.message}`, error);
      toast.error(error.message, {autoClose: false});
    });
  }

  const editEvent = (venueEvent) => {
    setIsLoading(true);
    setShowingEventForm({ visible: false });
    updateVenueEvent(venueEvent).then(d => {
      const parsedEvent = parseEvents([venueEvent]);
      const updatedEvents = [...events].map(updatedEvent => {
        return updatedEvent.id === venueEvent.id ? parsedEvent[0] : updatedEvent;
      });
  
      setEvents(updatedEvents);
      // reload events just to make sure changes from db are loaded
      getAllVenueEvents(venueId)
        .then(d => {
          setIsLoading(false);
          setEvents(parseEvents(d.data));
        })
        .catch(error => {
          console.error(`getAllVenueEvents: ${error.message}`, error);
        });
      toast.success("Event edited successfully");
    }).catch(error => {
      setIsLoading(false);
      setViewingEvent(null);
      setShowingEventForm({ visible: true, withEvent: venueEvent });
      console.error(`updateVenueEvent: ${error.message}`, error);
      toast.error(error.message, {autoClose: false});
    });
  }

  const deleteEvent = (venueEvent) => {
    setIsLoading(true)
    setViewingEvent(null)
    deleteVenueEvent(venueEvent.id).then(d => {
      const updatedEvents = [...events].filter(finalEvent => finalEvent.id !== venueEvent.id);

      setEvents(updatedEvents);
      // reload events just to make sure changes from db are loaded
      getAllVenueEvents(venueId)
        .then(d => {
          setIsLoading(false);
          setEvents(parseEvents(d.data));
        })
        .catch(error => {
          console.error(`getAllVenueEvents: ${error.message}`, error);
        });
      toast.success("Event deleted successfully");
    }).catch(error => {
      setIsLoading(false);
      console.error(`updateVenueEvent: ${error.message}`, error);
      toast.error(error.message, {autoClose: false});
    });
  }

  const showFeedback = ({ message, type, timeout = 2500 }) => {
    setFeedback({ message, type })
    setTimeout(() => {
      setFeedback(null)
    }, timeout)
  }

  const handleImport = () => {
    setIsLoading(true);
    getAllVenueEvents(venueId).then(d => {              
      setEvents(d.data);
      setIsLoading(false);
    }).catch(e => {
      console.error("getAllVenueEvents", e);
      toast.error("Cannot retrieve venue events!", {autoClose: false});
    })
  }

  return (
    <div className={classes.calendarWrapper}>
      <div className={classes.calendar}>
        {isLoading && <Loader />}
  
        {feedback && 
          <Feedback 
            message={feedback.message} 
            type={feedback.type} 
           />
         }

        <Navigation 
          date={date} 
          setDate={setDate} 
          setShowingEventForm={setShowingEventForm} 
          setShowExportModal={setShowExportModal}
          setShowImportModal={setShowImportModal}          
        />

        <VenueEventsExportModal 
          showModal={showExportModal}
          setShowModal={setShowExportModal}
          venueId={venueId}
        />
        <VenueEventsImportModal 
          showModal={showImportModal}
          setShowModal={setShowImportModal}
          venueId={venueId}
          refreshEvents={handleImport}
        />        
  
        <DayLabels />
  
        <EventGrid
          date={date}
          events={events}
          setShowingEventForm={setShowingEventForm} 
          setViewingEvent={setViewingEvent} 
          actualDate={date}
        />
  
        {viewingEvent && 
          <Event 
            event={viewingEvent} 
            setShowingEventForm={setShowingEventForm}
            setViewingEvent={setViewingEvent} 
            deleteEvent={deleteEvent} 
          />
        }
  
        {showingEventForm && showingEventForm.visible &&
          <EventForm 
            withEvent={showingEventForm.withEvent}
            preselectedDate={showingEventForm.preselectedDate}
            setShowingEventForm={setShowingEventForm} 
            addEvent={addEvent}
            editEvent={editEvent}
            setViewingEvent={setViewingEvent}
          />
        }
      </div>
    </div>
  )
}
export default VenueEventCalendar;