import { React, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button, ButtonType } from "../../../_common/htmlTags/Button";
import * as venueApi from "../../../../_services/venue.service";

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: theme.spacing(2),
        fontWeight: "500"
    },
    duplicateButton: {
        marginTop: theme.spacing(2)
    }
}));

const DuplicateVenue = ({ selectedVenue }) => {
    const classes = useStyles();

    const [duplicating, setDuplicating] = useState(false);
    const [mapsSelected, setMapsSelected] = useState(false);
    const [dataSourcesSelected, setDataSourcesSelected] = useState(false);
    const [dashboardsSelected, setDashboardsSelected] = useState(false);
    const [taskSchedulesSelected, setTaskSchedulesSelected] = useState(false);
    const [alertsSelected, setAlertsSelected] = useState(false);
    const [aiStreamsSelected, setAiStreamsSelected] = useState(false);

    const checkBoxes = [
        { label: 'Maps', checked: mapsSelected, onChange: _ => setMapsSelected(it => !it) },
        { label: 'Data sources', checked: dataSourcesSelected, onChange: _ => setDataSourcesSelected(it => !it) },
        { label: 'Dashboards', checked: dashboardsSelected, onChange: _ => setDashboardsSelected(it => !it) },
        { label: 'Scheduled tasks', checked: taskSchedulesSelected, onChange: _ => setTaskSchedulesSelected(it => !it) },
        { label: 'Alerts', checked: alertsSelected, onChange: _ => setAlertsSelected(it => !it) },
        { label: 'AI streams', checked: aiStreamsSelected, onChange: _ => setAiStreamsSelected(it => !it) },
    ];

    const handleDuplicate = (evt) => {
        evt.preventDefault();
        setDuplicating(true);

        venueApi.duplicateVenue({
            venueId: selectedVenue.id,
            mapsSelected,
            dataSourcesSelected,
            dashboardsSelected,
            taskSchedulesSelected,
            alertsSelected,
            aiStreamsSelected
        })
            .then(() => toast.success("Venue was duplicated successfully"))
            .catch(err => toast.error(`Venue duplicating failed. ${err}`, { autoClose: false }))
            .finally(() => setDuplicating(false));
    }

    return (
        <Grid container>
            <form className={classes.container} onSubmit={handleDuplicate}>
                <Typography variant="h6" className={classes.title}>
                    Duplicate venue including objects below if selected
                </Typography>
                <Grid item container>
                    {
                        checkBoxes.map(chk => 
                            <Grid item xs={12}>
                                <FormControlLabel
                                    label={chk.label}
                                    control={
                                        <Checkbox
                                            checked={chk.checked}
                                            onChange={chk.onChange}
                                        />
                                    }
                                />
                            </Grid>
                        )
                    }
                </Grid>

                <Grid item xs={12}>
                    <Button
                        buttonType={ButtonType.Dark}
                        className={classes.duplicateButton}
                        variant="contained"
                        disabled={duplicating}
                        type="submit"
                    >
                        {duplicating ? 'Duplicating...' : 'Duplicate venue'}
                    </Button>
                </Grid>
            </form>
        </Grid>
    );
}

export default DuplicateVenue;
