import axios from "axios";
import React, { useCallback, useEffect, useState } from 'react';
import { Box } from "@material-ui/core";
import ReactTimeout from 'react-timeout';
import LoadPleaseWait from '../../notification/LoadingPleaseWait/LoadingMessage';
import { AuthService } from "../../../_services/auth.service";

const log = function () { console.debug('TimeRefreshingImage', ...arguments); }

const authService = new AuthService();

const requestHttpWithToken = async (config, access_token) => {
    config.headers = {
        Accept: "application/json",
    }

    if (access_token) {
        config.headers.Authorization = `Bearer ${access_token}`;
    }

    return await axios.request(config);
}

const requestHttp = async (config) => requestHttpWithToken(config, (await authService.getUser())?.access_token);
const getHttp = async (url) => await requestHttp({ method: 'get', url });

const TimeRefreshingImage = (props) => {
    const { width, height, url, timeout } = props;

    const [imageBase64, setImageBase64] = useState(null);
    const [error, setError] = useState(null);
    const [refreshNum, setRefreshNum] = useState(0);

    const refreshImage = useCallback(() => {
        props.setTimeout(() => setRefreshNum(n => n + 1), timeout);
    }, [setRefreshNum, timeout, props]);

    const onError = useCallback(err => {
        log(err);
        setError(err);
        refreshImage();
    }, [setError, refreshImage]);

    useEffect(() => {

        getHttp(url)
            .then(response => {
                if (response.data.image) {
                    if (response.data.image === imageBase64) {
                        refreshImage();
                    } else {
                        setImageBase64(response.data.image);
                        setError(null);
                    }
                } else {
                    onError({error: 'No image'});
                }
            }).catch(err => onError(err));

    }, [url, setError, onError, setImageBase64, refreshNum, refreshImage, imageBase64]);

    return (
        <LoadPleaseWait show={!imageBase64} text={error && 'Error occurred, reconnecting...'} >
            <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img style={{width, height}} alt='' src={`data:image/png;base64,${imageBase64}`} onLoad={refreshImage} onError={onError} />
            </Box>
        </LoadPleaseWait>
    );
}

export default ReactTimeout(TimeRefreshingImage);
