import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import SpeedIcon from '@material-ui/icons/Speed';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import ScheduleIcon from '@material-ui/icons/Schedule';
import TuneIcon from '@material-ui/icons/Tune';
import MapIcon from '@material-ui/icons/Map';
import featureToggle from './featureToggle';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { Icon } from '@material-ui/core';
import { icon } from "../_constants";
import EventIcon from '@material-ui/icons/Event';

export const getMainDrawerMenuTop = (reportViews) => {

    const reports = [];
    reportViews.forEach(reportView => {
        if (reportView.type === 'Tableau') {
            reports.push({
                name: reportView.name,
                link: `/reports/tableau/${reportView.id}`
            });
        }
        if (reportView.type === 'ARM') {
            reports.push({
                name: reportView.name,
                link: `/reports/arm/${reportView.id}`
            });
        }
    });

    return [{
        name: 'Home',
        icon: <HomeIcon />,
        link: '/Home',
    },
    {
        name: 'Dashboards',
        icon: <SpeedIcon />,
        link: '/dashboards',
    },
    {
        name: 'Venue locations',
        icon: <LocationOnIcon />,
        link: '/venueLocations',
    },
    ...featureToggle.MAP_ENABLED ? [{
        name: "Maps",
        icon: <MapIcon />,
        link: '/maps',
    }] : [],
    {
        name: 'Alerts',
        icon: <ColorLensIcon />,
        link: '/alert-rule-sets',
    },
    {
        name: 'Reports',
        icon: <EqualizerIcon />,
        link: '#',
        subMenu: [
            ...reports
    //         {
    //             name: "Full report",
    //             link: "/reports/full"
    //         },
    //         {
    //             name: "Daily report",
    //             link: "/reports/daily"
    //         },
    //         {
    //             name: "Raw occupancy report",
    //             link: "/reports/raw"
    //         },
    //         {
    //             name: "Traffic In/Out Report",
    //             link: "/reports/traffic-in-out"
    //         },
        ]
    },
    
    // {
    //     name: 'Location mapping',
    //     icon: <LinkIcon />,
    //     link: '/location-mapping',
    // },
    {
        name: 'Scheduled tasks',
        icon: <ScheduleIcon />,
        link: '/scheduled-tasks',
    },
    // The following menu item should be uncommented for testing color rules component.
    // {
    //     name: "Test page for colour rules",
    //     link: '/widgets/settings/colour-rules-test'
    // }
    ];
};

export const mainDrawerMenuBottom = [
    {
        name: 'Users',
        icon: <PeopleIcon />,
        link: '/users',
    }, 
    {
        name: "User groups",
        icon: 
            <Icon style={{
                marginLeft: -4,
                marginTop: -5
                }}>
                <img 
                    src={icon.path + 'UserGroups.svg'} 
                    style ={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch"
                    }}
                />
            </Icon>,
        link: '/user-groups'
    },
    //// As discussed with Dmitriy, we need to remove the Car Park Import for adamant for now and use it later when we got this back
    //// Currently, this adamant car park import doesn't have the new version for traffic structure.. need to update it 
    //// when we plan to use this again
    // {
    //     name: 'Import',
    //     icon: <PostAddIcon />,
    //     link: '/import',
    // }, 
    {
        name: 'Factors',
        icon: 
            <Icon style={{marginLeft: -3}}>
                <img 
                    src={icon.path + 'Factors.svg'} 
                    style ={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "stretch"
                    }}
                />
            </Icon>,
        link: '/factors',
    },
    {
        name: 'Occupancy manual adjustments',
        icon: <TuneIcon />,
        link: '/adjustments',
    },
    {
        name: 'Events',
        icon: <EventIcon />,
        link: '/events',
    },
    {
        name: 'Settings',
        icon: <SettingsIcon />,
        link: '/settings',
    },
    {
        name: 'Monitor',
        icon: <DesktopWindowsIcon />,
        link: '/monitor',
    },
]