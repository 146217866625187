import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText
} from '@material-ui/core';
import SelectionOptionText from "./common/SelectionOptionText";
import { widgetSettingsConstants } from "../../../../_constants/widget.settings.constants";
import { NIL as NIL_UUID } from "uuid";

const SelectSetting = ({
  widgetSettingWithValue,
  handleSettingValueChange,
  selectionOptions,
  required
}) => {
  const [value, setValue] = React.useState(selectionOptions?.find(it => it.id === widgetSettingWithValue.value)?.id);

  const handleChange = (venue) => {
    setValue(venue.target.value);
    handleSettingValueChange(widgetSettingWithValue, venue.target.value);
  };

  // Method returns value if it is not defined depending on class name
  const getEmptyValue = () => {
    switch (widgetSettingWithValue.className) {
      case widgetSettingsConstants.refAlertRuleSet.className:
        return NIL_UUID;
        break;
      default:
        return "";
    }
  }

  return (
    <FormControl
      variant="outlined"
      style={{ width: "100%" }}
      fullWidth
      required={required}
    >
      <InputLabel id={widgetSettingWithValue.id}>{widgetSettingWithValue.name}</InputLabel>
      <Select
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
          },
          getContentAnchorEl: null
        }}
        labelId={widgetSettingWithValue.id}
        value={value ?? getEmptyValue()}
        label={widgetSettingWithValue.name}
        onChange={handleChange}
        renderValue={(v) =>
          <SelectionOptionText
            settingClassName={widgetSettingWithValue.className}
            item={selectionOptions.find(e => e.id === v)}
          />
        }
      >
        {selectionOptions
          ?.map(item => {
            return (
              <MenuItem key={item.id} value={item.id}>
                <ListItemText primary={
                  <SelectionOptionText
                    settingClassName={widgetSettingWithValue.className}
                    item={item}
                  />
                } />
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}

export default SelectSetting;
