import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography, InputLabel, Card,CardContent } from "@material-ui/core";
import { addVenue, getVenues, getTimeZones } from "../../../_actions/venue.actions";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Button, ButtonType } from "../../_common/htmlTags/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  card: {
    fontFamily: "Manrope",
    borderColor: "#15e577",
    color: "#000",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#15e577",
      borderColor: "#564345",
    },
  },
  selectControl: {
    minWidth: 120,
  },
  formControl: {
    width: "100%",
  },
  button: {
    marginRight: "15px"
  }
}));

export const AddVenue = (props) => {
  const venues = useSelector((state) => state.venueReducer.venues);
  const timeZones = useSelector((state) => state.venueReducer.timeZones);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    name: "",
    description: "",
    timeZoneId: "",
  });

  useEffect(() => {
    dispatch(getVenues(0)).catch((error) => {
      toast.error("Loading venues failed. " + error, { autoClose: false });
    });
    dispatch(getTimeZones()).catch((error) => {
      toast.error("Loading time zones failed. " + error, { autoClose: false });
    });
  }, [dispatch]);

  function handleSubmit(venue) {
    venue.preventDefault();

    if (state.startDate > state.endDate) {
      toast.error("Start Date has to be less than End Date");
      return;
    }

    if (venues.find((e) => e.name === state.name)) {
      toast.error("There is already an venue with same name", {
        autoClose: 3000,
      });
      return;
    }

    dispatch(addVenue(state))
      .then(() => {
        if (props.error !== null && typeof props.error !== "undefined")
          toast.error("Adding venue failed" + props.error, {
            autoClose: false,
          });
        else {
          toast.success("Venue added successfully.");
          history.push("/home");
        }
      })
      .catch((err) => {
        toast.error("Adding venue failed" + err, {
          autoClose: false,
        });
      });
  }

  const handleChange = (venue) => {
    const { name, value } = venue.target;
    setState((prevTask) => ({
      ...prevTask,
      [name]: value,
    }));
  };

  return (
    <div>
      <Card style={{ overflow: 'auto' }}><CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h4">Venue details</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <form className={classes.container} onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="venueName"
                    label="Venue name"
                    variant="outlined"
                    fullWidth
                    required
                    name="name"
                    value={state.name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="venueDescription"
                    label="Venue description"
                    variant="outlined"
                    fullWidth
                    required
                    name="description"
                    value={state.description}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.selectControl}
                    fullWidth
                    required
                  >
                    <InputLabel id="role-label">Time zone</InputLabel>
                    <Select
                      labelId="timeZone-label"
                      id="timeZone-select"
                      name="timeZoneId"
                      value={state.timeZoneId}
                      onChange={handleChange}
                      label="TimeZone"
                    >
                      {timeZones.map((e) => (
                        <MenuItem key={e.id} value={e.id}>
                          {e.displayName + ' - ' + e.id}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} style={{ display: 'none' }}>
                  <TextField
                    id="venueDashboardUrl"
                    label="Venue dashboard URL"
                    variant="outlined"
                    fullWidth
                    name="venueDashboardUrl"
                    value={state.venueDashboardUrl}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="venueStartDate"
                    label="Venue start date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    required
                    name="startDate"
                    value={state.startDate}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="venueEndDate"
                    label="Venue end date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    required
                    name="endDate"
                    value={state.endDate}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    className={classes.button}
                    type="submit"
                  >
                    Save
                  </Button>
                  <Button
                      variant="outlined"
                      buttonType={ButtonType.Secondary}
                      component={Link}
                      to={"/Home"}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </CardContent></Card>
    </div>
  );
};

export default AddVenue;
