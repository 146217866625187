import React, {useState, useEffect} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { toast } from "react-toastify";
import LoadPleaseWait from "../../../notification/LoadingPleaseWait/LoadingMessage";
import {useApiGet} from "../../../../_helpers/useApiGet";
import {getTwilioPhoneNumber, updateTwilioPhoneNumber, testSms} from "../../../../_services/venue.twilio.service";
import { Button } from "../../../_common/htmlTags/Button";

const useStyles = makeStyles((theme) => ({
  twilioPhoneNumberOk: {
    color: theme.palette.color.success.main,
    width: "30px",
    height: "100%",
    marginRight: "0px"
  },
  twilioPhoneNumberCancel: {
    color: theme.palette.color.danger.main,
    width: "30px",
    height: "100%"
  },
  saveIcon: {
    color: theme.palette.color.success.main,
    height: "100%",
    marginRight: "0px"
  },
  cancelIcon: {
    color: theme.palette.color.danger.main,
    height: "100%"
  },
  testButton: {
    marginLeft: theme.spacing(3)
  }
}));

const getTwilioPhoneNumberCall = async (venueId) => {
  if (venueId) {
    const result = await getTwilioPhoneNumber(venueId);
    return result;
  } else {
    return null;
  }
};

export const TwilioApiSettings = ({venue}) => {
  const classes = useStyles();

  // The "data" here is the Twilio phone number as it was loaded from the database.
  const [{data: initialTwilioPhoneNumber, isLoading : isLoading1}] = useApiGet(getTwilioPhoneNumberCall, venue.id, null);

  // The twilioPhoneNumber is the same as the initialTwilioPhoneNumber until the user edits and saves
  // the phone number. The twilioPhoneNumber variable will be set to the newly saved value.
  const [twilioPhoneNumber, setTwilioPhoneNumber] = useState("");

  // If cachedTwilioPhoneNumber is null, then the phone number has not been edited since
  // the page loaded from the database. If the cachedTwilioPhoneNumber is not null (it can be 
  // an empty string), then the phone number name has been edited.
  const [cachedTwilioPhoneNumber, setCachedTwilioPhoneNumber] = useState(null);

  const [isSavingTwilioPhoneNumber, setIsSavingTwilioPhoneNumber] = useState(false);

  useEffect(() => {
    setTwilioPhoneNumber(initialTwilioPhoneNumber?.data);
    setCachedTwilioPhoneNumber(null);
  }, [initialTwilioPhoneNumber]);

  const handleTwilioPhoneNumberSave = async () => {
    if (isSavingTwilioPhoneNumber) {
      return;
    }
    if (cachedTwilioPhoneNumber === null) {
      return;
    }

    setIsSavingTwilioPhoneNumber(true);
    updateTwilioPhoneNumber(venue.id, cachedTwilioPhoneNumber)
    .then(() => {
      toast.success("Twilio phone number has been saved.");
      setTwilioPhoneNumber(cachedTwilioPhoneNumber);
      setCachedTwilioPhoneNumber(null);
      setIsSavingTwilioPhoneNumber(false);
    })
    .catch((error) => {
      toast.error("Failed to save Twilio phone number." + error.message, {autoClose: false});
      setIsSavingTwilioPhoneNumber(false);
    });
  }

  const [testPhoneNumber, setTestPhoneNumber] = useState("");

  const handleSendClick = async () => {
    testSms(venue.id, testPhoneNumber)
    .then((response) => {
      toast.success(`Test SMS sent. ${response.data}`);
    })
    .catch((error) => {
      toast.error("Failed to send test SMS. " + error.message, {autoClose: false});
    });
  };

  return (
    <div>
      {venue !== null ? (
        <LoadPleaseWait show={isLoading1} >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start"
            }}>
            <Typography variant="h5">Twilio API settings</Typography>
            <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "30px"
                }}>
                <TextField
                  id="twilioPhoneNumber"
                  label = "Twilio phone number"
                  placeholder="Twilio phone number"
                  variant="outlined"
                  name="twilioPhoneNumber"
                  value={cachedTwilioPhoneNumber === null ? twilioPhoneNumber : cachedTwilioPhoneNumber}
                  onChange={(e) => {
                    const { value } = e.target;
                    setCachedTwilioPhoneNumber(value);
                  }}
                  style={{width: "500px"}}
                  InputProps={cachedTwilioPhoneNumber !== null ? {
                    endAdornment: (
                      <InputAdornment
                        position="end">
                        <IconButton 
                          edge="end" 
                          className={classes.twilioPhoneNumberOk}
                          onClickCapture={(e) => handleTwilioPhoneNumberSave()} 
                          onMouseDown={e => e.stopPropagation()}
                        >
                          <DoneIcon />
                        </IconButton>
                        <IconButton 
                          edge="end" 
                          className={classes.twilioPhoneNumberCancel}
                          onClickCapture={(e) => setCachedTwilioPhoneNumber(null)} 
                          onMouseDown={e => e.stopPropagation()}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )}
                      :null
                    } 
                  />
                <Box
                  sx={{
                    width: "1200px",
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "50px",
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography 
                    style={{
                      marginBottom: "16px"
                    }}
                    variant="h6">
                    Send test SMS
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center"
                    }}
                  >
                    <TextField
                      id="testPhoneNumber"
                      label = "Phone number to send SMS to"
                      placeholder="Phone number to send SMS to"
                      variant="outlined"
                      name="testPhoneNumber"
                      value={testPhoneNumber}
                      onChange={(e) => {
                        const { value } = e.target;
                        setTestPhoneNumber(value);
                      }}
                      style={{width: "500px"}}
                    />
                    <Button
                      variant="contained"
                      className={classes.testButton}
                      disabled={!testPhoneNumber}
                      onClick={handleSendClick}
                    >
                      Send  
                    </Button>
                  </Box>
                </Box>
            </Box>
          </Box>
        </LoadPleaseWait>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
    </div>
  );
};

export default TwilioApiSettings;