import PermissionsGate from "../_common/permissions/PermissionsGate";
import {Box, Card, CardContent, Tab, Tabs, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {getLoginUser} from "../../_services";
import {SCOPES} from "../../_constants/user.permissions.constants";
import {TabContext, TabPanel} from "@material-ui/lab";
import PMYVisionMessageChecker from "./PMYVisionMessageChecker";
import PMYVisionTrafficInOutMessageChecker from "./PMYVisionTrafficInOutMessageChecker";
import PMYVisionQueueModelMessageChecker from "./PMYVisionQueueModelMessageChecker";
import PMYVisionDemographicMessageChecker from "./PMYVisionDemographicMessageChecker";
import devconsole from "../_common/devconsole";

const WrappedTab = (props) => {
  const { scopes, ...otherProps } = props;
  return (
    <PermissionsGate scopes={scopes}>
      <Tab {...otherProps} />
    </PermissionsGate>
  )
};
WrappedTab.muiName = "Tab";

const useStyles = makeStyles((theme) => ({
  // KF: The label of the active tab should be highlighted with the green line under the label.
  // I cannot make it work with dynamic tabs. Below is my attempt to fix this, but 
  // result does not look as beautiful, e.g. there ae silly margins on the left and
  // right of the highlighting and there's no animation. That's all I coould do
  // for now. We should give it another go before too long.
  root: {
    borderBottom: '1px solid #BCCBCE',
    '& .MuiTabs-indicator': {
      backgroundColor: "transparent", // theme.palette.color.primary.main,
    },
  },
  cardRoot: {
    display: "flex",
    flexDirection: "column",
    //flexGrow: 1,
    minHeight: 0,
    marginBottom: theme.spacing(3)
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: 0,
  },
  tabLabel: {
    color: "#031820",
    fontWeight: "400",
    padding: "0px 20px 12px 20px",
    marginBottom: "-6px",
  },
  activeTabLabel: {
    fontWeight: "400",
    color: theme.palette.color.primary.main,
    padding: "0px 20px 10px 20px",
    marginBottom: "-6px",
    borderColor: theme.palette.color.primary.main,
    borderBottom: '2px solid',
  },
  dataSourcesTabPanel: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: 0,
  }
}));

const Monitor = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);
  const [userRole, setUserRole] = useState(null);
  const [selectedTab, setSelectedTab] = useState("pmyvisionmessagechecker");

  useEffect(() => {
    dispatch(getLoginUser).then((user) => {
      setUserRole(user.profile.role);
    }).catch(() => {

    });
  }, [dispatch]);

  useEffect(() => {
    setSelectedTab(prev => prev || "pmyvisionmessagechecker");
  }, [userRole]);

  const handleTabSelectionChange = (e, selected) => {
    setSelectedTab(selected);
  };
  devconsole.log(selectedTab);
  return (
    <>
      {selectedVenue !== null && selectedVenue !== undefined ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            minHeight: 0
          }}>
          <Typography variant="h4" className={classes.title}>
            Monitor
          </Typography>
          <br></br>

          <Card
            className={classes.cardRoot}
            style={selectedTab === "data-sources"? {
              flexGrow: 1
            }: null}
          >
            <CardContent
              className={classes.cardContent}>
              <TabContext value={selectedTab}>
                <Box>
                  <Tabs value={selectedTab} classes={{ root: classes.root }} onChange={handleTabSelectionChange} scrollButtons="auto" variant="scrollable" >
                    <WrappedTab
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_VIEW_PMYVISIONMESSAGECHECKER]}
                      label={<div className={selectedTab === "pmyvisionmessagechecker" ? classes.activeTabLabel : classes.tabLabel}>OCCUPANCY</div>}
                      value="pmyvisionmessagechecker" />
                    <WrappedTab
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_VIEW_PMYVISIONTRAFFICINOUTMESSAGECHECKER]}
                      label={<div className={selectedTab === "pmyvisiontrafficinoutmessagechecker" ? classes.activeTabLabel : classes.tabLabel}>TRAFFIC IN OUT</div>}
                      value="pmyvisiontrafficinoutmessagechecker" />
                    <WrappedTab
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_VIEW_PMYVISIONQUEUEMODELMESSAGECHECKER]}
                      label={<div className={selectedTab === "pmyvisionqueuemodelmessagechecker" ? classes.activeTabLabel : classes.tabLabel}>QUEUE METRICS</div>}
                      value="pmyvisionqueuemodelmessagechecker" />
                    <WrappedTab
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_VIEW_PMYVISIONDEMOGRAPHICMESSAGECHECKER]}
                      label={<div className={selectedTab === "pmyvisiondemographicmessagechecker" ? classes.activeTabLabel : classes.tabLabel}>DEMOGRAPHIC</div>}
                      value="pmyvisiondemographicmessagechecker" />
                  </Tabs>
                </Box>
                <TabPanel
                  value="pmyvisionmessagechecker">
                  <PermissionsGate
                    scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_VIEW_PMYVISIONMESSAGECHECKER]} >
                    <PMYVisionMessageChecker />
                  </PermissionsGate>
                </TabPanel>
                <TabPanel
                  value="pmyvisiontrafficinoutmessagechecker">
                  <PermissionsGate
                    scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_VIEW_PMYVISIONTRAFFICINOUTMESSAGECHECKER]} >
                    <PMYVisionTrafficInOutMessageChecker />
                  </PermissionsGate>
                </TabPanel>
                <TabPanel
                  value="pmyvisionqueuemodelmessagechecker">
                  <PermissionsGate
                    scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_VIEW_PMYVISIONQUEUEMODELMESSAGECHECKER]} >
                    <PMYVisionQueueModelMessageChecker />
                  </PermissionsGate>
                </TabPanel>
                <TabPanel
                value="pmyvisiondemographicmessagechecker">
                <PermissionsGate
                  scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_VIEW_PMYVISIONDEMOGRAPHICMESSAGECHECKER]} >
                  <PMYVisionDemographicMessageChecker />
                </PermissionsGate>
              </TabPanel>
              </TabContext>
            </CardContent>
          </Card>
        </Box>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
    </>
  )
}

export default Monitor;