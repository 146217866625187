import {React, useState, useEffect} from "react";
import {useSelector} from "react-redux";
import { getLatestPMYVisionDemographicMessage } from "../../_services/indicators.PMYVisionMessageChecker";
import {DataGrid} from "@material-ui/data-grid";
import {NIL as NIL_UUID} from "uuid";
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import {Checkbox, FormControlLabel, Grid, TextField, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import useLocalStorage from "../../_helpers/useLocalStorage";
import useSortModel from "../../_helpers/useSortModel";

const useStyles = makeStyles((theme) => {
  return {
    mapped: { color: "green"},
    notMapped: { color: "red"},
    timeExceedThresholdWarning: {
      color: "red",
    },
    settingCellStyle: {
      padding: "5px 10px 5px 5px"
    }
  };
});

const PMYVisionDemographicMessageChecker = () => {
  const classes = useStyles();
  const selectedVenue = useSelector(
    (state) => state.venueReducer.selectedVenue
  );

  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [lastNumberMessage, setLastNumberMessage] = useState(1);
  const [showAboveThresholdOnly, setShowAboveThresholdOnly] = useState(true);
  const [differentInMinute, setDifferentInMinute] = useState(10);
  const [timeoutInSecond, setTimeoutInSecond] = useState(20);
  const [differentInSeconds, setDifferentInSeconds] = useState(differentInMinute * 100 );
  var date = new Date();
  const [lastUpdated, setLastUpdated] = useState(date.toLocaleString());
  const [pageSize, setPageSize] = useLocalStorage("pmy-pmyVisionDemographicMessageChecker-pageSize", 20);
  const [sortModel, setSortModel] = useSortModel("pmy-pmyVisionDemographicMessageChecker-sortModel", [{field: 'messageCreateDateFromSensor', sort: 'desc'}]);

  const refreshData = () => {
    setIsLoading(true);
    setMessages([]);
  }
  useEffect(() => {
    if (messages.length === 0 && selectedVenue !== null) {
      getLatestPMYVisionDemographicMessage(selectedVenue?.id ?? NIL_UUID, lastNumberMessage)
        .then((response) => {
          setMessages(response.data);
          setIsLoading(false);
          setLastUpdated((new Date()).toLocaleString());
          setTimeout(() => {
            refreshData();
          }, timeoutInSecond * 1000);
        }).catch(error => {
        setIsLoading(false);
        setLastUpdated((new Date()).toLocaleString());
        console.error(error);
      });
    }
  }, [ isLoading, messages, selectedVenue]);

  const renderExceedThreshold = (params) => {
    if(params.row.timeDifferenceInSeconds > differentInSeconds)
      return classes.timeExceedThresholdWarning;
    else return "";
  };

  const messagesAboveThreshold = messages.filter(a => a.timeDifferenceInSeconds > differentInSeconds);
  var messagesFiltered = messages;
  if (showAboveThresholdOnly) messagesFiltered = messagesAboveThreshold;

  const columns = [
    {
      field: "id",
      hide: true,
      valueFormatter: (params) => params.row?.id,
    },
    {
      field: "isMapped",
      width: 130,
      headerName: "MAPPED?",
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableSelectionOnClick: true,
      disableColumnSelector: true,
      disableDensitySelector: true,
      cellClassName: renderExceedThreshold,
      renderCell: (params) => {
        return (
          <>
            {params.row.isMapped ? <DoneIcon className={classes.mapped} fontSize={"small"} /> : <ErrorOutlineIcon  className={classes.notMapped} fontSize={"small"} /> }
          </>
        );
      }
    },
    {
      field: "vendorCode",
      width: 350,
      headerName: "VENDOR CODE",
      cellClassName: renderExceedThreshold,
    },
    {
      field: "vendorCodeName",
      width: 300,
      headerName: "LOCATION NAME",
      cellClassName: renderExceedThreshold,
    },
    {
      field: "sensorName",
      width: 300,
      headerName: "CAMERA NAME",
      cellClassName: renderExceedThreshold,
    },
    {
      field: "demographyAge",
      width: 180,
      headerName: "AGE",
      cellClassName: renderExceedThreshold,
    },
    {
      field: "demographyGender",
      width: 180,
      headerName: "GENDER",
      cellClassName: renderExceedThreshold,
    },
    {
      field: "demographyEmotion",
      width: 180,
      headerName: "EMOTION",
      cellClassName: renderExceedThreshold,
    },
    {
      field: "demographyRace",
      width: 180,
      headerName: "RACE",
      cellClassName: renderExceedThreshold,
    },
    {
      field: "demographyCount",
      width: 180,
      headerName: "COUNT",
      cellClassName: renderExceedThreshold,
    },
    {
      field: "messageCreateDateFromSensor",
      width: 250,
      headerName: "CAMERA DATE (UTC)",
      cellClassName: renderExceedThreshold,
      renderCell: (params) => {
        return (
          (new Date(params.row.messageCreateDateFromSensor)).toLocaleString()
        );
      }
    },
    {
      field: "messageCreatedDate",
      width: 310,
      headerName: "MESSAGE CREATED DATE (UTC)",
      cellClassName: renderExceedThreshold,
      renderCell: (params) => {
        return (
          (new Date(params.row.messageCreatedDate)).toLocaleString()
        );
      }
    },
    {
      field: "timeDifferenceInSeconds",
      width: 210,
      headerName: "TIME DIFFERENCE",
      renderCell: (params) => {
        return (
          params.row.timeDifference
        );
      },
      cellClassName: renderExceedThreshold,
    }
  ];

  return (
    <div>
      <Typography variant={"h5"}>PMY vision demographic message checker</Typography><br/>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={6} xl={4} spacing={3} style={{paddingRight: "30px"}}>
          <Grid container spacing={1}>
            <Grid xs={6} spacing={2} className={classes.settingCellStyle}>
              <b>Time difference threshold</b><br />
              <FormControlLabel
                control={<Checkbox name="showAboveThresholdOnly" checked={showAboveThresholdOnly} onChange={(e) => {
                  setShowAboveThresholdOnly(e.target.checked)
                }} />}
                label="Show data above threshold only."
              />
            </Grid>            
            <Grid xs={6} spacing={2} className={classes.settingCellStyle}>
              <TextField
                id="differentInMinute"
                variant="outlined"
                size="small"
                label="in minute(s)"
                type="number"                
                name="differentInMinute"
                value={differentInMinute}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth={true}
                onChange={(e) => {
                  setDifferentInMinute(e.target.value);
                  setDifferentInSeconds(e.target.value * 60);
                }}
              />
            </Grid>
            
            <Grid xs={6} spacing={2} className={classes.settingCellStyle}>
              <b>Refresh time</b>
            </Grid>
            <Grid xs={6} spacing={2} className={classes.settingCellStyle}>
              <TextField
                id="timeoutInSecond"
                variant="outlined"
                size="small"
                label="in second(s)"
                type="number"
                name="timeoutInSecond"
                value={timeoutInSecond}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth={true}
                onChange={(e) => {
                  setTimeoutInSecond(e.target.value);
                }}
              />
            </Grid>

            <Grid xs={6} spacing={2} className={classes.settingCellStyle}>
              <b>Get last # of message</b>
            </Grid>
            <Grid xs={6} spacing={2} className={classes.settingCellStyle}>
              <TextField
                id="lastNumberMessage"
                variant="outlined"
                size="small"
                type="number"
                name="lastNumberMessage"
                value={lastNumberMessage}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth={true}
                onChange={(e) => {
                  setLastNumberMessage(e.target.value);
                }}
              />
            </Grid>
            
            <Grid xs={12} spacing={2} className={classes.settingCellStyle}>
            <i>Any changes to settings will be applied after next refresh</i>
          </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={6} xl={8} spacing={2}>
          <b>The page will be auto refresh every {timeoutInSecond} seconds to get last {lastNumberMessage} message(s).</b><br />
          <b>The highlighted row means that it is above threshold: {differentInMinute} minute(s).</b><br /><br />
          <b><i>
            Total rows retrieved: {messages?.length}<br />
            Total rows showed in the grid: {messagesFiltered?.length}<br/>
            Total rows above the threshold : {messagesAboveThreshold?.length}<br/>
            Last Updated: {lastUpdated}
          </i></b>
        </Grid>
      </Grid>
      <br />
      <DataGrid
        disableSelectionOnClick={true}
        hideFooterRowCount={true}
        hideFooterSelectedRowCount={true}
        autoHeight
        rowHeight={40}
        pageSize={pageSize}
        onPageSizeChange={(val) => setPageSize(val)}
        rowsPerPageOptions={[10,20,50,75,100]}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        sortingOrder={['desc','asc']}
        columns={columns}
        rows={messagesFiltered === undefined ? [] : messagesFiltered}
      />
    </div>
  )
}
export default PMYVisionDemographicMessageChecker;