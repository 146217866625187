export const getHexFromColorStr = colorStr => {
    var ctx = document.createElement("canvas").getContext("2d");
    ctx.fillStyle = colorStr;
    return ctx.fillStyle;
};

export const getContrastTextColor = color => (isDarkColor(color) ? '#ffffff' : '#031820');

export const isDarkColor = color => {
    let hexcolor = `${color}`.slice(0, 1) === '#' ? color.slice(1) : getHexFromColorStr(color).slice(1);

	// If a three-character hexcode, make six-character
	if (hexcolor.length === 3) {
		hexcolor = hexcolor.split('').map(hex => hex + hex).join('');
	}

	// Convert to RGB value
	var r = parseInt(hexcolor.substr(0,2),16);
	var g = parseInt(hexcolor.substr(2,2),16);
	var b = parseInt(hexcolor.substr(4,2),16);

	// Get YIQ ratio
	var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

	// Check contrast
	return yiq < 128;
}
