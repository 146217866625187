import appViewModeConstants from "../../../_constants/app.view.mode.constants";
import { DASHBOARD_CLIENT_TYPE_COLOR_BLIND } from "../../../_constants/dashboard.constants"

const BLIND_RED_TEXT = 'Full capacity';
const BLIND_AMBER_TEXT = 'Near capacity';
const BLIND_GREEN_TEXT = 'Free to use';

const CAPACITY_GREEN = 'green';
const CAPACITY_AMBER = 'amber';
const CAPACITY_RED = 'red';
const CAPACITY_UNDEFINED = 'undefined';

const BLIND_TEXTS = {
    [CAPACITY_GREEN]: BLIND_GREEN_TEXT,
    [CAPACITY_AMBER]: BLIND_AMBER_TEXT,
    [CAPACITY_RED]: BLIND_RED_TEXT,
    [CAPACITY_UNDEFINED]: ''
};

const getCapacityLight = props => {
    const { data, viewMode } = props;

    if (viewMode === appViewModeConstants.VIEW_MODE && data?.alertRule) {
        const color = data.alertRule.color?.value?.toLower();
        switch (color) {
            case CAPACITY_RED: return CAPACITY_RED;
            case CAPACITY_GREEN: return CAPACITY_GREEN;
            case CAPACITY_AMBER: return CAPACITY_AMBER;
            default: return CAPACITY_UNDEFINED;
        }
    }

    return CAPACITY_UNDEFINED;
}

export const getLocationName = props => {
    const { data, settings, viewMode, clientType, isPublicViewMode } = props;
    const isViewMode = viewMode === appViewModeConstants.VIEW_MODE;
    
    if (isPublicViewMode && clientType === DASHBOARD_CLIENT_TYPE_COLOR_BLIND) return BLIND_TEXTS[getCapacityLight(props)];

    return isViewMode ? data?.location?.name : settings.RefLocation?.name;
}
