import { AuthService } from "./auth.service";
import axios from "axios";
import { ApiRootConstants } from "../_constants/index";
import devconsole from "../components/_common/devconsole";

var Constants = {
  dataCollectorApiRoot: ApiRootConstants.dataCollectorApiRoot
};

const authService = new AuthService();

export async function getTasks(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };
    const params = {
      venueId: venueId
    }

    return await axios
      .get(Constants.dataCollectorApiRoot + "api/DataCollector", {
        headers: headers,
        params: params
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export async function getTaskById(venueId, id) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };
    const params = {
      venueId: venueId,
    }

    return await axios
      .get(Constants.dataCollectorApiRoot + "api/DataCollector/" + id, {
        headers: headers,
        params: params
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        devconsole.log("NOT LOADED TASK", error);
        throw error;
      });
  }
}

export async function addTask(task) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    const bodyFormData = {
        Id: task.id,
        VenueId: task.venueId,
        Name: task.name,
        Description: task.description,
        OperationalIndicatorId: task.operationalIndicatorId,
        VendorId: task.vendorId,
        FrequencyId: task.frequencyId,
        Enabled: task.enabled,
        StartProcessDateTimeUTC: task.startProcessDateTimeUTC,
        CreatedDateUTC: task.createdDateUTC,
        vendorLocationMappings: task.vendorLocationMappings,
    };

    return await axios
      .post(
        Constants.dataCollectorApiRoot + "api/DataCollector",
        bodyFormData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function updateTask(task) {
    const user = await authService.getUser();
    if (user && user.access_token) {
      const options = {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + user.access_token,
        },
      };
  
      const bodyFormData = {
          Id: task.id,
          VenueId: task.venueId,
          Name: task.name,
          Description: task.description,
          OperationalIndicatorId: task.operationalIndicatorId,
          VendorId: task.vendorId,
          FrequencyId: task.frequencyId,
          Enabled: task.enabled,
          vendorLocationMappings: task.vendorLocationMappings,
        };
      
      return await axios
        .put(
          Constants.dataCollectorApiRoot + "api/DataCollector",
          bodyFormData,
          options
        )
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            } else {
              throw new Error(error.response.data.message);
            }
          }
  
          throw error;
        });
    }
  }
  
export async function disableTask(taskId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        'Content-Type': "multipart/form-data",
      },
    };

    const formData = new FormData();
    formData.set("Id", taskId);

    return await axios
      .post(
        Constants.dataCollectorApiRoot + "api/DataCollector/DisableTask", formData, options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function enableTask(taskId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        'Content-Type': "multipart/form-data",
      },
    };

    const formData = new FormData();
    formData.set("Id", taskId);

    return await axios
      .post(
        Constants.dataCollectorApiRoot + "api/DataCollector/EnableTask", formData, options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function getIndicators() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(Constants.dataCollectorApiRoot + "api/DataCollector/Indicators", {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export async function getVendors() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(Constants.dataCollectorApiRoot + "api/DataCollector/Vendors", {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export async function getIndicatorsWithVendorList() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(Constants.dataCollectorApiRoot + "api/DataCollector/IndicatorsWithVendorList", {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export async function getIndicatorVendorMappings() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(Constants.dataCollectorApiRoot + "api/DataCollector/IndicatorVendorMappings", {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export async function getIndicatorScheduleMappings() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(Constants.dataCollectorApiRoot + "api/DataCollector/IndicatorScheduleMappings", {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export async function getSchedules() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(Constants.dataCollectorApiRoot + "api/DataCollector/Frequencies", {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}
