import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { selectVendors } from './selectors';
import { widgetSettingsConstants, widgetSettingsDataTypesConstants } from '../_constants/widget.settings.constants';
import widgetTypeConstants from '../_constants/widget.type.constants';
import { parseBoolean } from "../_helpers";
import { sortByName } from '../_helpers/functions.sorting';
import { NIL as NIL_UUID } from "uuid";

const nameOf = (obj, field) => Object.entries(obj).find(entry => entry[1] === field)[0];

export const widgetDataSelector = 
    createCachedSelector(
        (state, widgetStore, widgetId) => state[widgetStore][widgetId]?.data,
        data => data
    ) (
        (state, widgetStore, widgetId) => widgetId
    );

export const widgetSettingsValuesSelector = 
    createCachedSelector(
        (state, widgetStore, widgetId) => state[widgetStore][widgetId]?.settingsValues,
        settingsValues => settingsValues
    ) (
        (state, widgetStore, widgetId) => widgetId
    );

export const widgetSettingsSelector = 
    createCachedSelector(
        (state, widgetStore) => state[widgetStore].settings,
        settings => settings
    ) (
        (state, widgetStore) => widgetStore
    );

export const getWidgetSettingsRefsList = createSelector(
        selectVendors,
        state => state.maps.maps.sort(sortByName),
        state => state.venueLocationReducer.venueLocationList.sort(sortByName),
        state => state.indicators.trafficInOut.trafficInOutMetrics,
        state => state.indicators.footfallTraffic.footfallTrafficMetrics,
        state => state.dashboards.occupancyViewModes,
        state => state.dashboards.trafficTypes,
        state => state.dashboards.cohorts,
        state => state.dashboards.numberWidgetValueTypes,
        state => state.dashboards.numberWidgetTimePeriods,    
        state => state.dashboards.demographicWidgetValueTypes,
        state => state.dashboards.demographicWidgetTimePeriods,         
        state => state.dashboards.demographicWidgetAgeGroup,  
        state => state.dashboards.demographicWidgetGender,  
        state => state.dashboards.mapWidgetIndicators,
        state => state.dashboards.cameras.sort(sortByName),
        state => state.dashboards.dashboardAlertRuleSets.sort((a, b) => a.id == NIL_UUID || b.id == NIL_UUID ? undefined : sortByName(a, b)),
        state => state.dashboards.queueModelAlertMetrics,
    
        (vendors, maps, locations, trafficInOutMetrics, footfallTrafficMetrics, occupancyViewModes, trafficTypes, cohorts, numberWidgetValueTypes, numberWidgetTimePeriods, demographicWidgetValueTypes, demographicWidgetTimePeriods, demographicWidgetAgeGroup, demographicWidgetGender, mapWidgetIndicators, cameras, dashboardAlertRuleSets, queueModelAlertMetrics) => 
        ({vendors, maps, locations, trafficInOutMetrics, footfallTrafficMetrics, occupancyViewModes, trafficTypes, cohorts, numberWidgetValueTypes, numberWidgetTimePeriods, demographicWidgetValueTypes, demographicWidgetTimePeriods, demographicWidgetAgeGroup, demographicWidgetGender, mapWidgetIndicators, cameras, dashboardAlertRuleSets, queueModelAlertMetrics})
    );
    
export const getWidgetSettingsRefs = createCachedSelector(
    getWidgetSettingsRefsList,
    (_, widgetStore) => widgetStore,

    (refsList, widgetStore) => {
        const { vendors, maps, locations, trafficInOutMetrics, footfallTrafficMetrics, 
            occupancyViewModes, trafficTypes, cohorts, numberWidgetValueTypes, numberWidgetTimePeriods, demographicWidgetValueTypes, demographicWidgetTimePeriods, demographicWidgetAgeGroup, demographicWidgetGender, mapWidgetIndicators, cameras, dashboardAlertRuleSets, queueModelAlertMetrics } = refsList;

        const ret = {};
        ret[widgetSettingsConstants.refVendor.className] = vendors;
        ret[widgetSettingsConstants.refMap.className] = maps;
        ret[widgetSettingsConstants.refLocation.className] = locations;
        ret[widgetSettingsConstants.refListLocation.className] = locations;
        ret[widgetSettingsConstants.refTrafficType.className] = trafficTypes;
        ret[widgetSettingsConstants.refListCohort.className] = cohorts;
        ret[widgetSettingsConstants.refNumberWidgetTimePeriod.className] = numberWidgetTimePeriods;
        ret[widgetSettingsConstants.refMapIndicator.className] = mapWidgetIndicators;
        ret[widgetSettingsConstants.refQueueModelAlertMetric.className] = queueModelAlertMetrics;        
        ret[widgetSettingsConstants.refNumberWidgetValueType.className] = numberWidgetValueTypes;
        ret[widgetSettingsConstants.refDemographicWidgetValueType.className] = demographicWidgetValueTypes;        
        ret[widgetSettingsConstants.refDemographicWidgetTimePeriod.className] = demographicWidgetTimePeriods;
        ret[widgetSettingsConstants.refDemographicAgeGroup.className] = demographicWidgetAgeGroup;
        ret[widgetSettingsConstants.refDemographicGenderList.className] = demographicWidgetGender;
        ret[widgetSettingsConstants.refOccupancyViewMode.className] = occupancyViewModes;
        ret[widgetSettingsConstants.refWidgetSettingCameraUrl.className] = cameras;
        ret[widgetSettingsConstants.refAlertRuleSet.className] = dashboardAlertRuleSets;

        ret[widgetSettingsConstants.widgetSettingTimePeriod.className] = 
            nameOf(widgetTypeConstants, widgetTypeConstants.widgetTrafficInOut) === widgetStore ? trafficInOutMetrics : footfallTrafficMetrics;

        return ret;
    }
)(
    (_, widgetStore) => widgetStore
);

const getTypedValue = (dataType, value) => {
    if (dataType === widgetSettingsDataTypesConstants.boolean)
        return parseBoolean(value);
    if (dataType === widgetSettingsDataTypesConstants.integer)
        return +(value ?? 0);
    return value;
};

export const widgetRefValuesSettingsSelector =
    createCachedSelector(
        getWidgetSettingsRefs,
        widgetSettingsValuesSelector,

        (settingsRefs, settingsValues) => {
            if (settingsValues === undefined)
                return undefined;

            const ret = {};
            let cnt = 0;
            settingsValues?.forEach(it => {
                const refName = it.className ?? it.widgetSetting.className;
                const value = it.value ?? it.defaultValue ?? it.widgetSetting.defaultValue;
                const valueType = (it.dataType ?? it.widgetSetting.dataType);
                if (valueType === widgetSettingsDataTypesConstants.refId || valueType === widgetSettingsDataTypesConstants.refIdList) {
                    const ref = settingsRefs[refName];
                    const refItem = ref?.find(it => it.id === value) ?? { id: value, name: 'No data' };
                    ret[refName] = valueType === widgetSettingsDataTypesConstants.refId ? refItem : [...(ret[refName] ?? []), refItem];
                } else {
                    ret[refName] = getTypedValue(valueType, value);
                }

                cnt++;
            });

            return cnt ? ret : null;
        }
    ) (
        (state, widgetStore, widgetId) => widgetId
    );
