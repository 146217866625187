import React from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { loadDashboardsSimple } from '../_actions/dashboard.actions';
import { toast } from "react-toastify";
import { getVenueById } from '../_actions/venue.actions';
import * as dashboardApi from "../_services/dashboard.service";
import { ROLES} from "../_constants/user.permissions.constants";


export const HomeRedirection = () => {
  
  //If logged in user has default dashboard set – display dashboard    
  //if logged in user has more than one venue permission and each venue has default dashboard => user will have more than one default dashboard for all venues => display list of venue 
  //If logged in user does not have default dashboard but there is a system dashboard set – display this
  //<Redirect to={{ pathname: '/dashboard/218d74ff-5315-4e50-b613-dc91557a5e03' }} />
  //If no default dashboard and user is Administrator – display home page with list of venues
  //If there is only one venue, then this venue becomes automatically selected and show Venue list.
  // <RedirectComponent />
    
    
  const history = useHistory();
  const dispatch = useDispatch();

  dashboardApi
    .getDefaultDashboard()
    .catch((err) => {
      toast.error("Cannot load user dashboard. Error: " + err, { autoClose: true });
      return;
    })
    .then((dashboard) => {

      if (dashboard !== null && dashboard !== undefined) {
        if (dashboard.venueId.length === 1 && (dashboard.venueId[0] !== "00000000-0000-0000-0000-000000000000")) {

          dispatch(getVenueById(dashboard?.venueId[0]))
            .finally(() => { //load venue finished, then load dashboard 
              dispatch(loadDashboardsSimple(dashboard.venueId[0], false))
                .finally(() => { //finishing loading dashboard, redirect to dashboard

                  var dashboardId = (dashboard.systemDashboard !== undefined && dashboard.systemDashboard !== null) ?
                    dashboard.systemDashboard : dashboard.userDashboard;

                  if (dashboardId != null)
                    history.push(`/dashboard/${dashboardId}`);
                  else {
                    //go to dashboard list page 
                    history.push('/dashboards');
                  }
                })
            });
        }
      }

      //show list of venue 
      history.push('/Home');
      
    });
  
  return (<></>);
};

export default HomeRedirection; 
