import { createTheme } from '@material-ui/core/styles';
import widgetTheme from './admin/dashboards/WidgetTheme';
import mapTheme from './maps/MapTheme';
import htmlTagTheme from '../components/_common/htmlTags/HtmlTagTheme';
import scrollbarTheme from '../components/_common/ScrollbarTheme';
import gridLayoutDashboardTheme from './admin/dashboards/gridLayout/GridLayoutDashboardTheme';
import globalStyleOverrides from './_common/globalStyleOverrides';

const theme = createTheme({
  palette: {
    color: {
      primary: {
        main: "#00C1CE", // green blue
        main10Darker: "#00BDCA",
        main20Darker: "#008e98",
        dark: "#031820",
        darkHover: "#4b4b4b",
        red: "#EE4040",
        green: "#3FBE5D",     
        background: "#E9ECF2",
        disabled: 'rgba(188, 203, 206, 0.2)',        
      },
      secondary: {
        main: "#627E84",
      },
      info: {
        main: "#0FA1F5",
      },
      danger: {
        main: "#EE4040",
      },
      warning: {
        main: "#F2C94C",
        dark: "#C19120"
      },
      success: {
        main: "#3FBE5D",
      },
    },
    common: {
      selectedVenueBackground: "#02F24F",
      panelBorderColor: "#BCCBCE",
      gridColumnHeaderBackground: "#DCE3E8",
      gridRowHoverBackground: "#EFF3F6",
      gridRowSelectedBackground: "#E9ECF2",
      gridRowInactiveTextColor: 'rgba(98, 126, 132, 0.7)',
    },
    trafficLight: {
      red: "#b30007",
      amber: "#ffea00",
      green: "#0fc101"
    },
    passwordChecklist: {
      green: "#3FBE5D",
      red: "#EE4040",
      background: 'rgba(238, 64, 64, 0.05)',      
    },
    widget: {
      backgroundColor: "#00288C",
      emptyContainerBorderColor: "#DCE3E8",
      borderColor: "#E9ECF2",
    },
    map: {
      overlay: {
        color: "rgb(45, 156, 219)",
        firstVertex: "rgb(0, 193, 206)",
        red: "#EE4040",
        amber: "#F7D208",
        green: "#3FBE5D",
      },
      thumbnail:{
        borderColor: "#DCE3E8",
      }
    },

    menu: {
      background: "#031820", 
      color:"#A1C2C9",
    },

    dashboard:{
      trafficLight:{
        amber: "#F4B72A",         
        green:"#3FBE5D",        
        red:"#EE4040",         
        },

      widgetDefaultBackground: "#FFFFFF",
      widgetDefaultColor: "#FFFFFF",
      titleColor: "#DCE3E8",
      titleColorRgbLighter: "rgb(220, 227, 232, 0.5)",
      inactiveDashboardListCardBackgroundColor: "rgba(233, 236, 242, 1)",
      inactiveDashboardListCardTextColor: "rgba(98, 126, 132, 1)",
    },

    venueSettings: {
      dataSources: {
        locationsBackground: "#F0F3F9",
        selectedLocationBackground: "#FFFFFF",
        mouseOverLocationBackground: "yellow",
        oddVendorBackground: "#F0F3F980",
        radioButton: "#00C1CE80",
      }
    },

    eventCalendar: {
      text: "#0FA1F5",
      background: "#DAEEFB",
      border: "#BCCBCE",//alpha("#94A3B8", 0.5)
    },

    errorPage: {
      messageColor: "#EB5757",
      stackBackground: '#EB5757'
    }
  },

  typography: {
    fontFamily: "Jost, sans-serif",
    fontSize: 15,
    // KF - Please note that in MUI v4 there seems to be no way to add custom variants for Typography component.
    // We have to create custom styles via useStyles if the standard typography variants don't suit
    // in particular case.
    h3: {
      // This variant is used for the venue name in the list of the venues on the home page.
      fontSize: 35,
      fontWeight: 400
    },
    h4: {
      // This variant is used for page titles.
      fontSize: 26,
      fontWeight: 500
    },
    h5: {
      // This variant is used for:
      // - the selected venue name on the very top of the page.
      // - the sub-page titles, like "General Settings" tab title in the Venue Settings page.
      // - the titles of the modal dialogs, e.g. "Widget Settings".
      fontSize: 22,
      fontWeight: 500
    },
    h6: {
      // This variant is used for:
      // - add Scheduled Task page > the "Select operational indicator..." prompt 
      // - and any prompt-like or message phrases on many other pages.
      fontSize: 20,
      fontWeight: 400
    },
    subtitle1: {
      // This variant is used in the list of venues on the home page, for the venue descriptions.
      fontSize: 14,
      fontWeight: 300
    },
    subtitle2: {
      // This variant is used for tracking notes on the pages ("Last modified by..." etc.).
      fontSize: 12,
      fontWeight: 300
    },
    body1: {
      // This variant is the default for <Typography/> component. 
      // It is used for the string values of the input fields, items in the drop-down lists, captions of the check boxes.
      fontSize: 15,
      fontWeight: 400
    },
    body2: {
      // This variant is used for the stirngs in the cells (and the column titles) of the data grids.
      // KF: I did not find a more straightforward way to affect the font in the data grids. Unfotunately,
      // having body1 and body2 variants the same makes separating them meaningless.
      fontSize: 15,
      fontWeight: 400
    },
  },
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(98,126,132,0.2),0px 1px 1px 0px rgba(98,126,132,0.14),0px 1px 3px 0px rgba(98,126,132,0.12)",
    "0px 3px 1px -2px rgba(98,126,132,0.2),0px 2px 2px 0px rgba(98,126,132,0.14),0px 1px 5px 0px rgba(98,126,132,0.12)",
    "0px 3px 3px -2px rgba(98,126,132,0.2),0px 3px 4px 0px rgba(98,126,132,0.14),0px 1px 8px 0px rgba(98,126,132,0.12)",
    "0px 2px 4px -1px rgba(98,126,132,0.2),0px 4px 5px 0px rgba(98,126,132,0.14),0px 1px 10px 0px rgba(98,126,132,0.12)",
    "0px 3px 5px -1px rgba(98,126,132,0.2),0px 5px 8px 0px rgba(98,126,132,0.14),0px 1px 14px 0px rgba(98,126,132,0.12)",
    "0px 3px 5px -1px rgba(98,126,132,0.2),0px 6px 10px 0px rgba(98,126,132,0.14),0px 1px 18px 0px rgba(98,126,132,0.12)",
    "0px 4px 5px -2px rgba(98,126,132,0.2),0px 7px 10px 1px rgba(98,126,132,0.14),0px 2px 16px 1px rgba(98,126,132,0.12)",
    "0px 5px 5px -3px rgba(98,126,132,0.2),0px 8px 10px 1px rgba(98,126,132,0.14),0px 3px 14px 2px rgba(98,126,132,0.12)",
    "0px 5px 6px -3px rgba(98,126,132,0.2),0px 9px 12px 1px rgba(98,126,132,0.14),0px 3px 16px 2px rgba(98,126,132,0.12)",
    "0px 6px 6px -3px rgba(98,126,132,0.2),0px 10px 14px 1px rgba(98,126,132,0.14),0px 4px 18px 3px rgba(98,126,132,0.12)",
    "0px 6px 7px -4px rgba(98,126,132,0.2),0px 11px 15px 1px rgba(98,126,132,0.14),0px 4px 20px 3px rgba(98,126,132,0.12)",
    "0px 7px 8px -4px rgba(98,126,132,0.2),0px 12px 17px 2px rgba(98,126,132,0.14),0px 5px 22px 4px rgba(98,126,132,0.12)",
    "0px 7px 8px -4px rgba(98,126,132,0.2),0px 13px 19px 2px rgba(98,126,132,0.14),0px 5px 24px 4px rgba(98,126,132,0.12)",
    "0px 7px 9px -4px rgba(98,126,132,0.2),0px 14px 21px 2px rgba(98,126,132,0.14),0px 5px 26px 4px rgba(98,126,132,0.12)",
    "0px 8px 9px -5px rgba(98,126,132,0.2),0px 15px 22px 2px rgba(98,126,132,0.14),0px 6px 28px 5px rgba(98,126,132,0.12)",
    "0px 8px 10px -5px rgba(98,126,132,0.2),0px 16px 24px 2px rgba(98,126,132,0.14),0px 6px 30px 5px rgba(98,126,132,0.12)",
    "0px 8px 11px -5px rgba(98,126,132,0.2),0px 17px 26px 2px rgba(98,126,132,0.14),0px 6px 32px 5px rgba(98,126,132,0.12)",
    "0px 9px 11px -5px rgba(98,126,132,0.2),0px 18px 28px 2px rgba(98,126,132,0.14),0px 7px 34px 6px rgba(98,126,132,0.12)",
    "0px 9px 12px -6px rgba(98,126,132,0.2),0px 19px 29px 2px rgba(98,126,132,0.14),0px 7px 36px 6px rgba(98,126,132,0.12)",
    "0px 10px 13px -6px rgba(98,126,132,0.2),0px 20px 31px 3px rgba(98,126,132,0.14),0px 8px 38px 7px rgba(98,126,132,0.12)",
    "0px 10px 13px -6px rgba(98,126,132,0.2),0px 21px 33px 3px rgba(98,126,132,0.14),0px 8px 40px 7px rgba(98,126,132,0.12)",
    "0px 10px 14px -6px rgba(98,126,132,0.2),0px 22px 35px 3px rgba(98,126,132,0.14),0px 8px 42px 7px rgba(98,126,132,0.12)",
    "0px 11px 14px -7px rgba(98,126,132,0.2),0px 23px 36px 3px rgba(98,126,132,0.14),0px 9px 44px 8px rgba(98,126,132,0.12)",
    "0px 11px 15px -7px rgba(98,126,132,0.2),0px 24px 38px 3px rgba(98,126,132,0.14),0px 9px 46px 8px rgba(98,126,132,0.12)"
  ]
});

export default createTheme({
  ...theme,
  overrides: globalStyleOverrides(theme),
  // ------------ Re-used styles for widgets ----------
  widget: widgetTheme(theme),
  // ------------ Re-used styles for maps -------------
  map: mapTheme(theme),
  // ------------ Re-used styles for dashboard -------------
  dashboard: {
    gridLayoutDashboard: gridLayoutDashboardTheme(theme),
  },
  // ------------ Re-used styles for html theme -------------
  htmlTag: htmlTagTheme(theme),
  // --------- Styles for scrollbars ------
  scrollbar: scrollbarTheme(theme)
});