import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { TabContext, TabPanel } from "@material-ui/lab";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";
import PermissionsGate from "../../../_common/permissions/PermissionsGate";
import WrappedTab from '../../../_common/permissions/WrappedTab';
import { SCOPES, ROLES } from "../../../../_constants/user.permissions.constants";
import ModeList from '../../venueModes/ModeList'
import VenueGeneralSetting from './VenueGeneralSetting'
import ReportsDataPublishing from "./ReportsDataPublishing";
import VenueUserSetting from "./VenueUserSetting";
import DataSourceSettings from "./DataSourceSettings";
import BrandingSettings from './BrandingSettings';
import { selectVenueSettingTab } from "../../../../_actions/venue.actions";
import { AuthService } from '../../../../_services/auth.service';
import {VenueCamerasSetting} from "./VenueCamerasSetting";
import { getLoginUser } from "../../../../_services/userManagement.service";
import DuplicateVenue from './DuplicateVenue';
import {ARMApiSettings} from "./ARMApiSettings";
import {ReportViewSettings} from "./ReportViewSettings";
import {TwilioApiSettings} from "./TwilioApiSettings";

const useStyles = makeStyles((theme) => ({
  // KF: The label of the active tab should be highlighted with the green line under the label.
  // I cannot make it work with dynamic tabs. Below is my attempt to fix this, but 
  // result does not look as beautiful, e.g. there ae silly margins on the left and
  // right of the highlighting and there's no animation. That's all I coould do
  // for now. We should give it another go before too long.
  root: {
    borderBottom: '1px solid #BCCBCE',
    '& .MuiTabs-indicator': {
      backgroundColor: "transparent", // theme.palette.color.primary.main,
    },
  },
  cardRoot: {
    display: "flex",
    flexDirection: "column",
    //flexGrow: 1,
    minHeight: 0,
    marginBottom: theme.spacing(3)
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: 0,
  },
  tabLabel: {
    color:  theme.palette.color.secondary.main,
    fontWeight: "400",
    padding: "0px 20px 12px 20px",
    marginBottom: "-11px", 
    // KF - I don't really know what I'm doing with this border bottom, but it makes it look nice.
    borderBottomColor: "transparent",
    borderBottom: '2px solid',
  },
  activeTabLabel: {
    fontWeight: "500",
    color: theme.palette.color.primary.main,
    padding: "0px 20px 10px 20px",
    marginBottom: "-11px",
    borderColor: theme.palette.color.primary.main,
    borderBottom: '4px solid',
  },
  dataSourcesTabPanel: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: 0,
  }
}));

const VenueSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);
  const error = useSelector((state) => state.scheduleTasks.error);
  const selectedTabIndex = useSelector((state) => state.venueReducer.selectedTab);

  const [userRole, setUserRole] = useState(null);
  const [selectedTab, setSelectedTab] = useState(selectedTabIndex);

  useEffect(() => {
    dispatch(getLoginUser).then((user) => {
      setUserRole(user.profile.role);
    }).catch(() => {
    
    });
  }, [dispatch]);

  useEffect(() => {
    if (userRole === ROLES.VENUE_OPERATOR) {
      setSelectedTab(prev => { return prev || "reports" });
    } else if (userRole === ROLES.ADMINISTRATOR || userRole === ROLES.VENUE_ADMINISTRATOR) {
      setSelectedTab(prev => prev || "venue");
    }
  }, [userRole]);

  const handleTabSelectionChange = (e, selected) => {
    setSelectedTab(selected);
    dispatch(selectVenueSettingTab(selected));
  };

  return (
    <>
      {selectedVenue !== null && selectedVenue !== undefined ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            minHeight: 0
          }}>
          <Typography variant="h4" className={classes.title}>
            Venue settings
          </Typography>
          <br></br> 

          <Card 
            className={classes.cardRoot}
            style={selectedTab === "data-sources"? {
              flexGrow: 1
            }: null}
            >               
            <CardContent
              className={classes.cardContent}>
              <TabContext value={selectedTab}>
                <Box>
                  <Tabs value={selectedTab} classes={{ root: classes.root }} onChange={handleTabSelectionChange} scrollButtons="auto" variant="scrollable" >
                    <WrappedTab
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_VENUE]}
                      label={<div className={selectedTab === "venue" ? classes.activeTabLabel : classes.tabLabel}>GENERAL SETTINGS</div>}
                      value="venue" />
                    <WrappedTab
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_ADD_VENUE]}
                      label={<div className={selectedTab === "duplicate-venue" ? classes.activeTabLabel : classes.tabLabel}>DUPLICATE VENUE</div>}
                      value="duplicate-venue" />
                    <WrappedTab
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_PUBLISH_DATA_FOR_REPORTS]}
                      label={<div className={selectedTab === "reports" ? classes.activeTabLabel : classes.tabLabel}>DATA FOR REPORTS</div>}
                      value="reports" />
                    <WrappedTab 
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_USER_VENUE_LIST]} 
                      label={<div className={selectedTab === "user-permissions" ? classes.activeTabLabel : classes.tabLabel}>USER PERMISSIONS</div>} 
                      value="user-permissions" />
                    <WrappedTab 
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_MODES]} 
                      label={<div className={selectedTab === "venue-modes" ? classes.activeTabLabel : classes.tabLabel}>VENUE MODES</div>}
                      value="venue-modes" />
                    <WrappedTab 
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_DATA_SOURCES]} 
                      label={<div className={selectedTab === "data-sources" ? classes.activeTabLabel : classes.tabLabel}>DATA SOURCES</div>} 
                      value="data-sources" />
                    <WrappedTab
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_BRANDING]}
                      label={<div className={selectedTab === "branding" ? classes.activeTabLabel : classes.tabLabel}>BRANDING</div>}
                      value="branding" />
                    <WrappedTab
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_AI_STREAMS]}
                      label={<div className={selectedTab === "ai-streams" ? classes.activeTabLabel : classes.tabLabel}>AI STREAMS</div>}
                      value="ai-streams" />
                    <WrappedTab
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_SETUP_ARM_API_SETTINGS]}
                      label={<div className={selectedTab === "arm-api-settings" ? classes.activeTabLabel : classes.tabLabel}>ARM API SETTINGS</div>}
                      value="arm-api-settings" />
                    <WrappedTab
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_SETUP_REPORT_VIEW_SETTINGS]}
                      label={<div className={selectedTab === "report-view-settings" ? classes.activeTabLabel : classes.tabLabel}>REPORT VIEWS</div>}
                      value="report-view-settings" />
                    <WrappedTab
                      scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_SETUP_TWILIO_API_SETTINGS]}
                      label={<div className={selectedTab === "twilio-api-settings" ? classes.activeTabLabel : classes.tabLabel}>TWILIO API SETTINGS</div>}
                      value="twilio-api-settings" />
                  </Tabs>
                </Box>
                <TabPanel
                  value="venue">
                  <PermissionsGate
                    scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_VENUE]} >
                    <VenueGeneralSetting selectedVenueId={selectedVenue.id}/>
                  </PermissionsGate>
                </TabPanel>
                <TabPanel
                  value="duplicate-venue">
                  <PermissionsGate
                    scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_ADD_VENUE]} >
                    <DuplicateVenue selectedVenue={selectedVenue}/>
                  </PermissionsGate>
                </TabPanel>
                <TabPanel
                  value="reports">
                  <PermissionsGate scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_PUBLISH_DATA_FOR_REPORTS]}>
                    <ReportsDataPublishing
                      selectedVenue={selectedVenue}
                      error={error}
                    />
                  </PermissionsGate>
                </TabPanel>
                <TabPanel
                  value="user-permissions">
                  <PermissionsGate scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_USER_VENUE_LIST]}>
                    <VenueUserSetting
                      selectedVenue={selectedVenue}
                    />
                  </PermissionsGate>
                </TabPanel>
                <TabPanel
                  value="venue-modes">
                  <PermissionsGate scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_MODES]}>
                    <ModeList />
                  </PermissionsGate>
                </TabPanel>
                <TabPanel
                  className={classes.dataSourcesTabPanel}
                  value="data-sources">
                  <PermissionsGate scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_DATA_SOURCES]}>
                    <DataSourceSettings
                      venue={selectedVenue} />
                  </PermissionsGate>
                </TabPanel>
                <TabPanel
                  value="ai-streams">
                  <PermissionsGate scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_AI_STREAMS]}>
                    <VenueCamerasSetting
                      venue={selectedVenue} />
                  </PermissionsGate>
                </TabPanel>
                <TabPanel
                  value="branding">
                  <PermissionsGate scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_BRANDING]}>
                    <BrandingSettings
                      venue={selectedVenue} />
                  </PermissionsGate>
                </TabPanel>
                <TabPanel
                  value="arm-api-settings">
                  <PermissionsGate scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_SETUP_ARM_API_SETTINGS]}>
                    <ARMApiSettings 
                      venue={selectedVenue} />
                  </PermissionsGate>
                </TabPanel>
                <TabPanel
                  value="report-view-settings">
                  <PermissionsGate scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_SETUP_REPORT_VIEW_SETTINGS]}>
                    <ReportViewSettings 
                      venue={selectedVenue} />
                  </PermissionsGate>
                </TabPanel>
                <TabPanel
                  value="twilio-api-settings">
                  <PermissionsGate scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_SETUP_TWILIO_API_SETTINGS]}>
                    <TwilioApiSettings 
                      venue={selectedVenue} />
                  </PermissionsGate>
                </TabPanel>
              </TabContext>
            </CardContent>
          </Card> 
          {/* For all tabs, except Data Sources, there is an empty Box
          on the bottom of the page. This Box has flexGrow: 1 and pushes
          the bottom border of the white background "paper" up, so that
          the paper is not bigger than the content of the tab.
          The Data Sources tab is different. It should take all vertical
          space, right to bottom of the window. This is to allow the room
          for the list of locations, regardless of how many locations
          there are there to display. */}
          {selectedTab !== "data-sources" ?
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                minHeight: 0
              }} />
          : null }
        </Box>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
    </>
  )
}

export default VenueSettings;
