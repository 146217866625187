import { AuthService } from "./auth.service";
import axios from "axios";
import { ApiRootConstants } from "../_constants/index";
import { toast } from "react-toastify";

var Constants = {
  reportApiRoot: ApiRootConstants.reportApiRoot,
  dataCollectorApiRoot: ApiRootConstants.dataCollectorApiRoot,
  venueManagementApiRoot: ApiRootConstants.venueManagementApiRoot,
  armVendorApiRoot: ApiRootConstants.ARMVendorApiRoot
};

const authService = new AuthService();

async function axiosGet(uri) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .get(uri, options)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export function getTableauToken(venueId) {
  return axiosGet(`${Constants.venueManagementApiRoot}api/ReportView/GetTableauToken/${venueId}`);
}

export async function publishReportData(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    var data = {
      "VenueId": venueId,
      "PublishTrigger": "Triggered from PMY Platform Venue Settings page",
      "UserId": user.profile.sub,
      "UserName": user.profile.name,
      "Comments": ""
    };

    return await axios
      .post(`${Constants.reportApiRoot}api/ReportPublishing/PublishAsync`, data, options)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export function getPublishReportDataStatus(publishId) {
  return axiosGet(`${Constants.reportApiRoot}api/ReportPublishing/${publishId}`);
}

export async function setReportAutoPublishing(venueId, isAuto) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    var data = {
      "VenueId": venueId,
      "PublishTrigger": "Triggered from PMY Platform Venue Settings page for auto publishing",
      "UserId": user.profile.sub,
      "UserName": user.profile.name,
      "Comments": "Auto publish for venue " + venueId
    };

    return await axios
      .post(`${Constants.dataCollectorApiRoot}api/ReportPublishing/SetReportAutoPublishing?isAuto=${isAuto}`, data, options)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function setTableauAutoPublishing(venueId, isAuto) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    var data = {
      "VenueId": venueId,
      "PublishTrigger": "Triggered from PMY Platform Venue Settings page for auto tableau publishing",
      "UserId": user.profile.sub,
      "UserName": user.profile.name,
      "Comments": "Auto tableau publish for venue " + venueId
    };

    return await axios
      .post(`${Constants.dataCollectorApiRoot}api/ReportPublishing/SetTableauAutoPublishing?isAuto=${isAuto}`, data, options)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export function getReportPublishingMode(venueId) {
  return axiosGet(Constants.dataCollectorApiRoot + "api/ReportPublishing/GetReportPublishingMode/" + venueId);
}

export function getTableauPublishingMode(venueId) {
  return axiosGet(Constants.dataCollectorApiRoot + "api/ReportPublishing/GetTableauPublishingMode/" + venueId);
}

export async function getPublishJournal(venueId) {
  return axiosGet(Constants.reportApiRoot + "api/ReportPublishing/Journal/" + venueId);
}

export async function exportFullReport(venueId, date, apiRoot, nowDateTime, setIntervalEnum, locations, setGenerating) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    fetch(ApiRootConstants.excelConverterApiRoot + 'exportFullReport'
      + '?venueId=' + venueId
      + '&date=' + date
      + '&apiRoot=' + apiRoot
      + '&dateTimePostfix=' + nowDateTime
      + '&setIntervalEnum=' + setIntervalEnum, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/zip',
        "Authorization": "Bearer " + user.access_token,
      },
      body: locations
    })
      .then((response) => response.blob())
      .then((blob) => {
        if (typeof blob === 'undefined' || blob.type === "text/html") {
          setGenerating(false);
          toast.error("There are no data available for selected date", { autoClose: false });
          return;
        }
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'Full_Report_' + nowDateTime + '.zip',
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

        setGenerating(false);
      });
  }
}

export async function exportDailyReport(venueId, selectedDate, apiRoot, nowDateTime, locations, setGenerating) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    fetch(ApiRootConstants.excelConverterApiRoot + 'exportDailyReport'
      + '?venueId=' + venueId
      + '&date=' + selectedDate
      + '&apiRoot=' + apiRoot
      + '&dateTimePostfix=' + nowDateTime, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/zip',
        "Authorization": "Bearer " + user.access_token,
      },
      body: locations
    })
      .then((response) => response.blob())
      .then((blob) => {
        if (typeof blob === 'undefined' || blob.type === "text/html") {
          setGenerating(false);
          toast.error("There are no data available for selected date and locations", { autoClose: false });
          return;
        }
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'Daily_Report_' + nowDateTime + '.zip',
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

        setGenerating(false);
      });
  }
}
export async function exportRawOccupancyReport(venueId, selectedDate, apiRoot, nowDateTime, locations, setGenerating) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    fetch(ApiRootConstants.excelConverterApiRoot + 'exportRawOccupancyReport'
      + '?venueId=' + venueId
      + '&date=' + selectedDate
      + '&apiRoot=' + apiRoot
      + '&dateTimePostfix=' + nowDateTime, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/zip',
        "Authorization": "Bearer " + user.access_token,
      },
      body: locations
    })
      .then((response) => response.blob())
      .then((blob) => {
        if (typeof blob === 'undefined' || blob.type === "text/html") {
          setGenerating(false);
          toast.error("There are no data available for selected date and locations", { autoClose: false });
          return;
        }
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'Occupancy_Raw_Data_Report_' + nowDateTime + '.zip',
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

        setGenerating(false);
      });
  }
}

export async function exportTrafficInOutReport(
  startDate, endDate, isDuringBusinessHours, dataToDisplay, vendorId, venueId,
  apiRoot, nowDateTime,
  locations,
  setGenerating
) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    fetch(ApiRootConstants.excelConverterApiRoot + 'exportTrafficInOutReport'
      + '?startDate=' + startDate
      + '&endDate=' + endDate
      + '&dataToDisplay=' + dataToDisplay
      + '&isDuringBusinessHours=' + isDuringBusinessHours
      + '&vendorId=' + vendorId
      + '&venueId=' + venueId
      + '&apiRoot=' + apiRoot
      + '&dateTimePostfix=' + nowDateTime, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/zip',
        "Authorization": "Bearer " + user.access_token,
      },
      body: locations
    })
      .then((response) => response.blob())
      .then((blob) => {
        if (typeof blob === 'undefined' || blob.type === "text/html") {
          setGenerating(false);
          toast.error("There are no data available for selected date and locations", { autoClose: false });
          return;
        }
        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'Traffic_In_Out_Report_' + nowDateTime + '.zip',
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

        setGenerating(false);
      });
  }
}

export function getARMReportToken(venueId) {
  return axiosGet(`${Constants.armVendorApiRoot}api/ARM/GetReportToken/${venueId}`);
}

export function getARMEvents(venueId) {
  return axiosGet(`${Constants.armVendorApiRoot}api/ARM/GetARMEvents/${venueId}`);
}

export function getLatestARMEvent(venueId) {
  return axiosGet(`${Constants.armVendorApiRoot}api/ARM/GetLatestARMEvent/${venueId}`);
}