import React from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    makeStyles
} from '@material-ui/core';
import Setting from './Setting';
import LoadPleaseWait from '../../notification/LoadingPleaseWait/LoadingMessage';
import { Button, ButtonType } from "../../_common/htmlTags/Button";

const widgetSettingsListDialogStyles = makeStyles((theme) => ({
    dialogContainer: {
        overflow: "hidden"
    },
    dialogTitleContainer: {
        display: "flex",
        padding: "10px"
    },
    dialogContentContainer: {
        padding: "10px 35px 20px 30px",
        overflow: "hidden"
    },
    settingsFormButtonsContainer: {
        marginTop: "15px"
    },
    settingsFormButton: {
        marginRight: "20px",
    },
    waitingContainer: {
        minHeight: 170,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

/**
 * Component returns only necessary UI representation and doesn't implement any logic.
 * Logic of specific setting is contained in "Setting" component.
 */
const WidgetSettingsModalWindow = ({
    isSettingsOpen,
    handleCloseSettings,

    settingsData,
    widgetTypeId,

    handleSettingValueChange,
    handleSubmitSettingsValues,
    isSaving
}) => {
    const classes = widgetSettingsListDialogStyles();

    return (
        <Dialog
            open={isSettingsOpen}
            onClose={handleCloseSettings}
            fullWidth={true}
            maxWidth="sm"
            className={classes.dialogContainer}
        >
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
            >
                <Box
                    display="flex"
                    flex="1"
                    justifyContent="center"
                    height="50px"
                >
                    <DialogTitle
                        className={classes.dialogTitleContainer}
                    >
                        <div>
                            <Typography
                                variant="h5"
                            >
                                Widget Settings
                            </Typography>
                        </div>
                    </DialogTitle>
                </Box>
            </Box>

            <DialogContent
                className={classes.dialogContentContainer}
                style={{ overflowY: 'auto' }}
            >
                <form onSubmit={handleSubmitSettingsValues}>
                    <Grid
                        container
                        spacing={3}
                        direction="column"
                    >
                        {
                            settingsData?.length > 0 ?
                                (
                                    <>
                                        {
                                            settingsData.map(setting => (
                                                <Grid item key={setting.id}>
                                                    {/*
                                                    HERE IS USED "Setting" COMPONENT WHICH IS NECESSARY PART OF SETTING ENGINE
                                                    */}
                                                    <Setting
                                                        settingData={setting}
                                                        widgetTypeId={widgetTypeId}
                                                        handleSettingValueChange={handleSettingValueChange}
                                                    />
                                                </Grid>
                                            ))
                                        }
                                        <Grid item className={classes.settingsFormButtonsContainer}>
                                            <Button
                                                variant="contained"
                                                className={classes.settingsFormButton}
                                                disabled={isSaving}
                                                type="submit"
                                            >
                                                {isSaving ? "Saving..." : "Save"}
                                            </Button>

                                            <Button
                                                variant="outlined"
                                                buttonType={ButtonType.Secondary}
                                                className={classes.settingsFormButton}
                                                onClick={handleCloseSettings}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </>
                                ) : (
                                    <Box className={classes.waitingContainer} >
                                        <LoadPleaseWait show={true} />
                                    </Box>
                                )
                        }
                    </Grid>
                </form>
            </DialogContent>
        </Dialog >
    );
};

export default WidgetSettingsModalWindow;