import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import { getFactorTypes, getVenueFactors, updateFactor } from "../../_services/factors.service";
import { filterHistoryByLocation, filterHistoryByFactorType } from "../../_actions/factor.actions";
import {getVenueLocations} from "../../_services/venue.locations.service";
import {toast} from "react-toastify";
import {useApiGet} from "../../_helpers/useApiGet";
import {DataGrid} from "@material-ui/data-grid";
import {DataGridLoadingOverlay} from "../_common/DataGridLoadingOverlay";
import ExplicitGhostTextField from "../_common/ExplicitGhostTextField";
import WarningText from "../_common/WarningText";

const useStyles = makeStyles((theme) => ({
  selectFactorTypeInvitation: {
    color: theme.palette.color.secondary.main
  },
}));

const getVenueFactorsCall = async (venueId) => {
  const result = await getVenueFactors(venueId);
  return result.data;
};

const getVenueLocationsCall = async (venueId) => {
    const result = await getVenueLocations(venueId);
    if ( result.data?.length) {
      const rootLocation = result.data[0];
      return flattenLocations({location: rootLocation});
    } else {
      return [];
    }
};

const flattenLocations = ({location, level = 0}) => {
  const result = [
    {
      id: location.id,
      name: location.name,
      isSensor: location.locationType.isSensor,
      isDeleted: location.isDeleted,
      level: level
    }];
  
  location.children.sort((a,b) => a.name.localeCompare(b.name)).forEach((childLocation) => {
    result.push(...flattenLocations({location: childLocation, level: level + 1}));
  });

  return result;
};

const getBrowserLocale = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
  }
};

const formatUtcDatetime = (datetime, ianaTimeZoneId) => {
  const browserLocale = getBrowserLocale();
  return datetime.toLocaleString(browserLocale, {timeZone: ianaTimeZoneId});
};

export const FactorHistory = ({venue}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [{data: initialFactors, isLoading}] = useApiGet(getVenueFactorsCall, venue.id, []);
  const [{data: locations}] = useApiGet(getVenueLocationsCall, venue.id, []);
  const [{data: factorTypes}] = useApiGet(getFactorTypes, [], []);

  const [factors, setFactors] = useState([]);
  const [filteredFactors, setFilteredFactors] = useState([]);

  const initialFilter = useSelector((state) => state.factorReducer.factorHistoryFilter);
  const [selectedLocationId, setSelectedLocationId] = useState(initialFilter.locationId);
  const [selectedFactorTypeId, setSelectedFactorTypeId] = useState(initialFilter.factorTypeId);
  
  useEffect(() => {
    if (initialFactors.length > 0 && locations.length > 0 ) {
      setFactors( initialFactors.map( f => {
        const d = new Date();
        const milliseconds = Date.parse(f.startDateUtc+ 'Z');
        d.setTime( milliseconds);

        return {
          id: f.id,
          factorTypeId: f.factorTypeId,
          factorTypeName: f.factorType.name,
          factorValue: f.factorValue,
          startDateUtc: new Date(f.startDateUtc),
          startDateVenueTZ: formatUtcDatetime(d, venue.ianaTimeZoneId),
          venueId: f.venueId,
          vendorId: f.vendorId,
          vendorName: f.vendor.name,
          locationId: f.locationId,
          locationName: locations.find(l => l.id === f.locationId)?.name
        };
      }).sort( (a,b) => b.startDateUtc.getTime() - a.startDateUtc.getTime()));
    }
  }, [initialFactors, locations]);

  useEffect(() => {
    setFilteredFactors(
      factors.filter( f => 
        (selectedFactorTypeId === "-" || f.factorTypeId === selectedFactorTypeId) 
        && (selectedLocationId === "-" || f.locationId === selectedLocationId)
      )
    );
  }, [factors, selectedLocationId, selectedFactorTypeId]);

  const handleFactorValueChanged = (factorId, factorValue) => {
    const oldFactor = factors.find(f => f.id === factorId);

    setFactors(prev => {
      return prev.map(f => {
        if (f.id === factorId ) {
          return {
            ...f,
            factorValue: factorValue
          };
        } else {
          return f;
        }
      });
    });

    updateFactor({
      ...oldFactor,
      factorValue: factorValue
    })
    .then(() => {
      toast.success("Factor has been saved.");
    })
    .catch((error) => {
      toast.error("Failed to save factor. " + error.message, {autoClose: false});
      // Revert to old factor.
      setFactors(prev => {
        return prev.map(f => {
          if (f.id === factorId ) {
            return {
              ...f,
              factorValue: oldFactor.factorValue
            };
          } else {
            return f;
          }
        });
      });
    });
  };

  const handleFactorTypeSelected = (e) => {
    const newFactorTypeId = e.target.value;
    setSelectedFactorTypeId(newFactorTypeId);
    dispatch(filterHistoryByFactorType(newFactorTypeId))
  };

  const handleLocationSelected = (e) => {
    const newLocationId = e.target.value;
    setSelectedLocationId(newLocationId);
    dispatch(filterHistoryByLocation(newLocationId));
  };

  const disableColumnOrnaments = {
    disableColumnMenu: true,
    disableColumnFilter: true,
    disableSelectionOnClick: true,
    disableColumnSelector: true,
    disableDensitySelector: true,
    sortable: false
  };

  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "locationName",
      headerName: "LOCATION",
      width: 300,
      ...disableColumnOrnaments
    },
    {
      field: "factorTypeName",
      headerName: "METRIC",
      width: 200,
      ...disableColumnOrnaments
    },
    {
      field: "vendorName",
      headerName: "DATA SOURCE",
      width: 200,
      ...disableColumnOrnaments
    },
    {
      field: "factorValue",
      headerName: "FACTOR",
      width: 180,
      ...disableColumnOrnaments,
      type:"number",
      align:'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <ExplicitGhostTextField
            value={params.row.factorValue.toLocaleString(undefined, { maximumFractionDigits: 4 })}
            onChange={(newFactorValue) => {
              handleFactorValueChanged(params.row.id, newFactorValue);
            }}
            marginTop="0"
            marginBottom="2px"
            height="31px"
          />
        );
      }
    },
    {
      field: "startDateVenueTZ",
      headerName: "APPLY FROM",
      width: 220,
      ...disableColumnOrnaments,
      align:'left',
      headerAlign: 'left'
    }
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        minHeight: 0
      }}>
      <Typography variant="h5">
        Factor history
      </Typography>
      <Box
        sx={{
          mt: "24px"
        }}
      >
        <WarningText
          text="Modifying the past factors for Occupancy will not affect today's peak values."
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          mt: "32px"
        }}>
        <FormControl
          variant="outlined"
          style={{
            width: "400px",
            marginRight: "24px"
          }}
        >
          <InputLabel id="factorType-label">Filter by metric</InputLabel>
          <Select
            labelId="factorType-label"
            id="factorType-select"
            name="factorTypeId"
            value={selectedFactorTypeId}
            onChange={handleFactorTypeSelected}
            label="Filter by metric"
          >
            {[
              <MenuItem key={"-"} value={"-"}>
                <Typography className={classes.selectFactorTypeInvitation}>All metrics</Typography>
              </MenuItem>,
              ...factorTypes.map((ft) => (
                <MenuItem key={ft.id} value={ft.id}>
                  <Typography>{ft.name}</Typography>
                </MenuItem>
              ))
            ]}
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          style={{
            width: "400px",
            marginRight: "24px"
          }}
        >
          <InputLabel id="location-label">Filter by location</InputLabel>
          <Select
            labelId="location-label"
            id="location-select"
            name="locationId"
            value={selectedLocationId}
            onChange={handleLocationSelected}
            label="Filter by location"
          >
            {[
              <MenuItem key={"-"} value={"-"}>
                <Typography className={classes.selectFactorTypeInvitation}>All locations</Typography>
              </MenuItem>,
              ...locations.map((l) => (
                <MenuItem key={l.id} value={l.id}>
                  <Box
                    ml={l.level*2}>
                    <Typography>
                      {l.name}
                    </Typography>
                  </Box>
                </MenuItem>
              ))
            ]}
          </Select>
        </FormControl>
      </Box>
      <DataGrid
        autoHeight={true}
        disableSelectionOnClick
        pageSize={10}
        columns={columns}
        rows={filteredFactors}
        components={{
          LoadingOverlay: DataGridLoadingOverlay,
        }}
        loading={isLoading}
        style={{
          marginTop: "32px",
          width: "1100px"
        }}
      />
    </Box>
  );
};

export default FactorHistory;