import React from "react";
import {useEffect, useRef, useState} from "react";
import { 
  Box,
  Tooltip,
  Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Button, ButtonType } from "../htmlTags/Button";
import AlertRuleEditor from "./AlertRuleEditor";
import { LensOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => {
  return {
    label: {
      marginLeft: "10px",
      marginTop: "10px",
      marginBottom: "10px",
    },
    rules: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      // Kf - leave this comment as the initial design was to have this background
      // and we may return to it.
      //backgroundColor: theme.palette.venueSettings.dataSources.locationsBackground
    },
    infoIcon: {
      color: theme.palette.color.primary.main,
      height: "22px",
      width: "22px",
      marginLeft: 15
    },
    popoverLikeTooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: "0px 0px 4px 0px #00000040",
      width: "200px",
    },
    popoverLikeTooltipArrow: {
      "&::before": {
        backgroundColor: theme.palette.common.white,
        boxShadow: "0px 0px 4px 0px #00000040",
      }
    },
    headerBackground: {
      background: theme.palette.common.gridColumnHeaderBackground,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2)
    },
    headerText: {
      marginLeft: theme.spacing(2),
      color: theme.palette.color.primary.dark,
      fontWeight: 500,
      fontSize: 14
    },
    toggleButtonGroupRoot: {
      background: theme.palette.color.primary.background,
      height: "30px",
      borderRadius: "15px",
      "& .Mui-selected": {
        borderRadius: "15px"
      },
      "& .MuiToggleButtonGroup-groupedHorizontal:first-child" :{
        marginLeft: "0",
        paddingLeft: "15px",
        marginRight: "-15px",
        paddingRight: "30px",
        zIndex: 0,
        borderRadius: "15px",
      },
      "& .MuiToggleButtonGroup-groupedHorizontal:first-child.Mui-selected" :{
        marginLeft: "0",       
        paddingLeft: "15px",
        marginRight: "0",
        paddingRight: "15px",
        zIndex: 1,
      },
      "& .MuiToggleButtonGroup-groupedHorizontal:last-child" :{
        marginLeft: "-15px",
        paddingLeft: "30px",
        marginRight: "0px",
        paddingRight: "15px",
        zIndex: 0,
        borderRadius: "15px",
      },
      "& .MuiToggleButtonGroup-groupedHorizontal:last-child.Mui-selected" :{
        marginLeft: "0",
        paddingLeft: "15px",
        marginRight: "0",
        paddingRight: "15px",
        zIndex: 1
      }

    },
    toggleButtonRoot: {
      textTransform: "none",
      fontWeight: 500,
      borderRadius: "15px",
      paddingTop: "5px",
      paddingBottom: "5px",
      color: theme.palette.color.primary.main,
      background: "transparent",
      borderColor: "transparent",
      marginLeft: "-15px",
      marginRight: "-15px",
      "&.Mui-selected" : {
        //background: theme.palette.common.toggleButtonBackground,
        background: "white",
        color: theme.palette.color.primary.main,
        borderColor: theme.palette.color.primary.main,
        "&:hover": {
          background: theme.palette.common.panelBorderColor
        }
      }
    }
  };
});

export const AlertRulesEditor =({label, alertType, alertRules, onChange}) => {
  const classes = useStyles();
  
  // When a new rule has been added, we need to scroll into view.
  const [scrollToLastRule, setScrollToLastRule] = useState(false);
  const scrollRef = useRef(null);
  const lastRuleFromRef = useRef(null);

  useEffect(() => {
    if (scrollToLastRule) {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behaviour: "smooth" });
      }
      if (lastRuleFromRef.current) {
        lastRuleFromRef.current.focus();
      }
      setScrollToLastRule(false);
    }
  }, [scrollToLastRule]);

  const handleAlertTypeChange = (event, newAlertType) => {
    if (!newAlertType)
      return;

    let newAlertRules;
    if ( alertType === "range" && newAlertType === "percent") {
      newAlertRules = alertRules.map(ar =>{
        return {
          ...ar,
          percent: ar.from
        }
      });
    }
    if ( alertType === "percent" && newAlertType === "range") {
      newAlertRules = alertRules.map(ar => {
        let newTo = null;
        alertRules.forEach( it => {
          if ( it.percent !== null &&( ar.percent === null || ar.percent < it.percent )) {
            if (newTo === null) {
              newTo = it.percent - 1;
            } else if (it.percent < newTo) {
              newTo = it.percent - 1;
            }
          }
        });

        return {
          ...ar,
          from: ar.percent,
          to: newTo
        };
      });
    }

    if (newAlertRules) {
      onChange(newAlertType, newAlertRules);
    } else {
      onChange(newAlertType, alertRules);
    }
  };

  const handleAlertRuleAdded = () => {
    const newAlertRules = [
      ...alertRules ?? [],
      {
        from: null,
        to: null,
        color: null,
        sound: false,
        userGroupId: null,
        sms: false,
        email: false
      }
    ];
    onChange(alertType, newAlertRules);
    setScrollToLastRule(true);
  };

  const handleAlertRuleChange = (alertRule, index) => {
    const newAlertRules = alertRules?.map((cr, i) => {
      if ( i === index) {
        return alertRule;
      } else {
        return cr;
      }
    });
    onChange(alertType, newAlertRules);
  };

  const handleAlertRuleDelete = (index) => {
    const newAlertRules = alertRules?.filter((cr, i) => {
      return (i !== index );
    });
    onChange(alertType, newAlertRules);
  };

  const renderedAlertRuleEditors = alertRules?.map((cr,i) => {
    const isLastRule = (alertRules.length - 1 === i );
    return (
      <div
        key={i}
        ref={isLastRule ? scrollRef : null}>
        <AlertRuleEditor
          key={i}
          alertRule={cr}
          alertType={alertType}
          onAlertRuleChange={(alertRule) => handleAlertRuleChange(alertRule, i)}
          onAlertRuleDelete={() => handleAlertRuleDelete(i)}
          fromRef={isLastRule? lastRuleFromRef: null}
        />
      </div>
    );
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        minHeight: 0
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center"
        }}
        className={classes.label}
      >
        <Typography>{label}</Typography>
        <Tooltip 
            title="Create rules that will aply the specified colour and sound to the locations on the dashboard widgets. The rules can be defined via value ranges or percentage thresholds. The percentage thresholds can be used for Occupancy widgets only."                        
            arrow
            placement="top-start"
            classes={{
              tooltip: classes.popoverLikeTooltip,
              arrow: classes.popoverLikeTooltipArrow
            }}
        >
          <InfoOutlinedIcon
            className={classes.infoIcon}
          />
        </Tooltip>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          minHeight: 0,
        }}
        className={classes.rules}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%"
          }}>
          <ToggleButtonGroup
            value={alertType}
            exclusive
            onChange={handleAlertTypeChange}
            classes={{
              root: classes.toggleButtonGroupRoot
            }}
          >
            <ToggleButton 
              value="range" 
              aria-label="left aligned"
              classes={{
                root: classes.toggleButtonRoot
              }}
            >
              Range
            </ToggleButton>
            <ToggleButton 
              value="percent" 
              aria-label="centered"
              classes={{
                root: classes.toggleButtonRoot
              }}
            >
              Percent
            </ToggleButton>
          </ToggleButtonGroup>
          <Box
            sx={{
              flexGrow: 1
            }}
          />
          <Button
            buttonType={ButtonType.PrimaryText}
            type="button"
            startIcon={<AddIcon />}
            onClick = {handleAlertRuleAdded}
            style={{
              marginLeft: "25px",
            }}
            >
            Add rule
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "958px",
            height: "37px",
            alignItems: "center"
          }}
          className={classes.headerBackground}
        >
          <Typography 
            className={classes.headerText}
            style={{width: "523px"}}
          >
            {alertType === "range" ? "RANGE" : "PERCENT"}
          </Typography>
          <Typography 
            className={classes.headerText}
            style={{width: "240px"}}
          >
            USER GROUP
          </Typography>
          <Typography 
            className={classes.headerText}
            style={{width: "100px"}}
          >
            SEND
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            minHeight: 0,
            overflowY: "auto",
            maxHeight: "220px",
            overflow: "auto",
            overflowX: "hidden",
            ml: "16px"
          }}
        >
          {renderedAlertRuleEditors}
        </Box>

      </Box>
    </Box>
  );
};

export default AlertRulesEditor;