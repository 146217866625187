import React, { useState } from "react";
import { Grid, TextField, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Button, ButtonType } from "../../_common/htmlTags/Button";
import { checkEmail } from '../../../_helpers/regex';
import { toast } from "react-toastify";
import { Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  numberCircle: { ...theme.htmlTag.numberCircle },
  cancelButton: { marginTop: "25px" },
}));


const SearchUser = ({ onEmailChanged, onSearchClicked }) => {

  const classes = useStyles();
  const [email, setEmail] = useState("");

  function onSearch() {

    if (email === "") {
      toast.error("Please enter login/email to search.", { autoClose: false });
      return;
    }

    if (!checkEmail(email)) {
      toast.error("Please enter a valid login/email to search.", { autoClose: false });
      return;
    }
    onSearchClicked(true);
  }

  return (
    <>
      <Typography style={{ display: 'flex' }} >
        <div className={classes.numberCircle}>1</div>
        Start with searching User by login/email</Typography>
      <Box style={{ paddingTop: 20 }}>
        <Grid container spacing={1}>
          <Grid item md={9}>
            <TextField
              required
              name="LoginEmail"
              id="LoginEmail"
              label="Login / Email"
              size="small"
              fullWidth
              variant="outlined"
              onChange={e => { setEmail(e.target.value); onEmailChanged(e.target.value); }}
              error={email.length > 0 && !checkEmail(email) ? true : false}
              helperText={
                email.length > 0 && !checkEmail(email) ? "Invalid email address" : ""
              } />

          </Grid>
          <Grid item md={2}>
            <Button fullWidth variant="contained" color="primary" onClick={() => onSearch()}>
              SEARCH EMAIL
            </Button>
          </Grid>


        </Grid>
      </Box>

      <Button
        variant="outlined"
        className={classes.cancelButton}
        buttonType={ButtonType.Secondary}
        component={Link}
        to={"/users"}
      >
        Cancel
      </Button>
    </>
  )
};


export default SearchUser;
