import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router";
import {getDashboard, loadDashboardsSimple} from "../../../_actions/dashboard.actions";
import EditDashboardFormV2 from "./EditDashboardFormV2"
import { toast } from "react-toastify";
import { NoVenueSelectedError } from "../../../_constants/";
import { selectDashboardLastUpdatedById, selectDashboardById } from '../../../_reducers/dashboard.selectors';
import {Container} from "react-bootstrap";
import {DashboardGridLayout} from "./gridLayout";
import {makeStyles} from "@material-ui/core/styles";
import {GetQueryParam, parseBoolean} from "../../../_helpers";
import {SCOPES, PERMISSIONS} from "../../../_constants/user.permissions.constants";
import {AuthService} from "../../../_services";

const log = function () { console.debug('EditDashboard', ...arguments); }
const useStyles = makeStyles((theme) => ({
    root: {
        margin: "-24px -24px -24px -24px",
        "& .MuiTextField-root": {
            margin: theme.spacing(1),
            //width: "25ch",
            flexGrow: 1,
        },

        "& .MuiFormControl-root": {
            margin: theme.spacing(1),
            //width: "25ch",
            flexGrow: 1,
        },
    },
    overwriteContainer: {
        margin: "0px"
    }
}));

const authService = new AuthService();
// Function checks if user has any permission in the scopes
// depending on user's role in system.
const hasPermission = ( permissions = [], scopes = [] ) => {
    const scopesMap = {};
    scopes.forEach((scope) => {
        scopesMap[scope] = true;
    });
    return permissions.some(p => scopesMap[p]);
};

export const EditDashboard = () => {
    const classes = useStyles();
    const history = useHistory();
    const { dashboardId } = useParams();
    const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);

    const lastUpdated = useSelector(state => selectDashboardLastUpdatedById(state, dashboardId));
    const dashboard = useSelector(state => selectDashboardById(state, dashboardId));
    const isInEditMode = parseBoolean(GetQueryParam("editMode") ?? false);
    
    const dispatch = useDispatch();
    log({dashboardId, lastUpdated, dashboard});

    useEffect(() => {
        if (selectedVenue?.id) {
            dispatch(getDashboard(selectedVenue.id, dashboardId))
              .catch(error => toast.error("Loading dashboard failed " + error, { autoClose: false }));

            // dispatch(loadDashboardsSimple(selectedVenue?.id, false))
            //   .catch(error => toast.error("Loading dashboards failed: " + error, { autoClose: false }));
        }
    }, [dispatch, selectedVenue?.id, dashboardId, lastUpdated]);

    useEffect(() => {
        authService.getUser().then((user) => {
            let roles = [user.profile.role];
            let permissions = [];
            roles.map(r => permissions = [...permissions, ...PERMISSIONS[r]]);
            /// Checks the permission of the user if the user can access edit mode, if the user doesn't have access to it, then redirect to viewing mode
            if(!hasPermission( permissions, [SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_UPDATE_DASHBOARD] )) {
                history.push(`/dashboard/${dashboardId}`); // redirect to read only mode
            }
        });
    }, []);
    
    return selectedVenue === null ? (
        <NoVenueSelectedError></NoVenueSelectedError>
      ) : (
        <div className={classes.root}>
          <Container fluid className={classes.overwriteContainer}>
            <EditDashboardFormV2 venue={selectedVenue} dashboard={dashboard} isInEditMode={isInEditMode} ></EditDashboardFormV2>
              <div style={{ height: "calc(100vh - 122px)", maxHeight: "calc(100vh - 122px)", margin:"0px -15px 0px -15px" }}  >
                <DashboardGridLayout dashboardId={dashboard?.id} dashboardWidgets={dashboard?.dashboardWidget} isInEditMode={isInEditMode} />
              </div>
          </Container>
        </div>
      );
};
