import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { getPublishJournal } from "../../../_services/report.service";
import { toast } from "react-toastify";
import Link from '@material-ui/core/Link';
import DoneIcon from '@material-ui/icons/Done'
import CrossIcon from '@material-ui/icons/Close'
import ErrorsListModalWindow from './ErrorsListModalWindow'
import LoadPleaseWait from "../../notification/LoadingPleaseWait/LoadingMessage"


const columns = [
  {
    field: "id",
    hide: true,
  },
  {
    field: "publishDate",
    headerName: "Publish Date",
    flex: 1.5,
    valueFormatter: (params) => {
      var publishDate = new Date(params.row?.publishDate);
      return new Date(publishDate.getTime() - publishDate.getTimezoneOffset() * 60000).toLocaleString()
    }
  },
  {
    field: "publishTrigger",
    headerName: "Publish Trigger",
    flex: 1.5,
  },
  {
    field: "userName",
    headerName: "User",
    flex: 1,
  },
];

export const PublishJournal = () => {
  const selectedVenue = useSelector(
    (state) => state.venueReducer.selectedVenue
  );

  const [journal, setJournal] = useState([]);
  const [loading, setLoading] = useState(false);

  const [currentPublishingLog, setCurrentPublishingLog] = useState({});
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    async function fetchData(venueId) {
      await getPublishJournal(venueId)
        .then((response) => {
          setJournal(response.data);
          setLoading(false);
        })
        .catch((err) => {
          toast.error("Loading publishing journal failed: " + err, {
            autoClose: false,
          });
          setLoading(false);
        });
    }

    if (selectedVenue !== null) {
      setLoading(true);
      fetchData(selectedVenue.id);
    }
  }, [selectedVenue]);

  return (
    <div>
      {selectedVenue !== null ? (
        <div>
          <Typography variant="h4">Publish Journal</Typography>
          
          <LoadPleaseWait show={loading} >         
            <div style={{ display: "flex", height: "100%", paddingTop: 15 }}>
              <div style={{ flexGrow: 1 }}>
                <DataGrid
                  autoHeight
                  pageSize={12}
                  columns={[...columns,
                  {
                    field: "status",
                    headerName: "Status",
                    flex: 1,
                    renderCell: (params) => {
                      return <>
                        {params.value === 'Failed' ? <CrossIcon style={{ fill: "red", marginBottom: "3px" }} /> : <DoneIcon style={{ fill: "green", marginBottom: "4px" }} />}
                        {params.value}
                        {params.value === 'Failed' ?
                          <>
                            <>&#160;</>
                            <Link
                              href=""
                              underline="hover"
                              onClick={(venue) => {
                                venue.preventDefault();
                                setCurrentPublishingLog(params.row);
                                setOpen(true);
                              }}
                            >
                              {"View errors"}
                            </Link>
                          </>
                          :
                          <></>
                        }
                      </>
                    },
                  },
                  ]}
                  rows={journal === undefined ? [] : journal}
                />
              </div>
            </div>
            </LoadPleaseWait>
        </div>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
      <ErrorsListModalWindow currentPublishingLog={currentPublishingLog} open={open} handleClose={handleClose} />
    </div>
  );
};

export default PublishJournal;