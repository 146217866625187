import WidgetNumberContent from './WidgetNumberContent';
import { widgetTypeConstants } from "../../../_constants";
import { WidgetWrapper } from "../WidgetWrapper";

export const WidgetNumber = (props) => (
        <WidgetWrapper
            {...{...props, widgetType: widgetTypeConstants.widgetNumber.id}}
        >
            <WidgetNumberContent />
        </WidgetWrapper>
    );

export default WidgetNumber;