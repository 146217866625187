import reportConstants from "../_constants/report.constants";
import initialState from "./initialState";

export function reportingReducer(state = initialState.reporting, action) {
    switch (action.type) {
        case reportConstants.GET_REPORT_PUBLISHING_MODE_STARTED:
            return {
                ...state,
                loading: { ...state.loading, reportPublishingModeLoading: true }
            };
        case reportConstants.GET_REPORT_PUBLISHING_MODE_SUCCESS:
            return {
                ...state,
                reportPublishingMode: action.reportPublishingMode,
                loading: { ...state.loading, reportPublishingModeLoading: false }
            };
        default:
            return state;
    }
}

export default reportingReducer;
